// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--APztL .s-module__title--D0_S1 {
  margin: 10px 0 6px 0;
}
.s-module__wrap--APztL .s-module__timezone--q44Ao {
  width: calc(1/3 * 100%);
}
.s-module__wrap--APztL .ant-form-item-required::after {
  display: none !important;
}
.s-module__wrap--APztL .ant-form-item {
  margin-bottom: 0 !important;
}
.s-module__wrap--APztL .s-module__hourTitle--IdtUb {
  font-size: 14px;
  line-height: 30px;
  color: var(--gray-700);
  font-weight: 500;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentSettingPage/components/AviabilityPage/TimezoneOperationhours/s.module.less"],"names":[],"mappings":"AAAA;EAEI,oBAAA;AAAJ;AAFA;EAKI,uBAAA;AAAJ;AALA;EASM,wBAAA;AADN;AARA;EAYM,2BAAA;AADN;AAXA;EAgBI,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".wrap {\n  .title {\n    margin: 10px 0 6px 0;\n  }\n  .timezone {\n    width: calc(1/3 * 100%);\n  }\n  :global {\n    .ant-form-item-required::after {\n      display: none !important;\n    }\n    .ant-form-item {\n      margin-bottom: 0 !important;\n    }\n  }\n  .hourTitle{\n    font-size: 14px;\n    line-height: 30px;\n    color: var(--gray-700);\n    font-weight: 500;\n    margin-top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--APztL`,
	"title": `s-module__title--D0_S1`,
	"timezone": `s-module__timezone--q44Ao`,
	"hourTitle": `s-module__hourTitle--IdtUb`
};
export default ___CSS_LOADER_EXPORT___;
