import dayjs from 'dayjs';
import React from 'react';
import { Popover } from 'antd';
import { Event, EventWithTimeOverlap, EventStatus } from 'types/calendar';
import { isEventTimeOverlap } from 'utils/calendar';
import s from './s.module.less';

type Props = {
    day:dayjs.Dayjs
    rowInx:number
    events:Event[]
    onEventClick?: (event:Event)=>void
};

const Day = (props:Props) => {
    const { day, rowInx, onEventClick, events = [] } = props;

    const isCurrentDay = day.format('DD-MM-YY') === dayjs().format('DD-MM-YY');
    const eventsWithTimeOverlap:EventWithTimeOverlap[] = React.useMemo(() => {
        const ret:EventWithTimeOverlap[] = [];
        for (let i = 0; i < events.length; i++) {
            const currentEvent = events[i];
            const timeOverlapKeys:string[] = [];
            for (let j = 0; j < i; j++) {
                const event2 = events[j];
                if (isEventTimeOverlap(currentEvent, event2)) {
                    timeOverlapKeys.push(event2.id);
                }
            }
            ret.push({ ...events[i], timeOverlapKeys });
        }
        return ret;
    }, [events]);

    const displayItem = eventsWithTimeOverlap.length > 5 ? eventsWithTimeOverlap.slice(0, 5) : eventsWithTimeOverlap;
    const showMore = eventsWithTimeOverlap.length > 5;
    const moreItems = eventsWithTimeOverlap.length - 5;
    const content = showMore ?
        (

            <div className={s.moreContent}>
                <div className={s.moreT1}>{day.format('ddd').toUpperCase()}</div>
                <div className={s.moreT2}>{day.format('DD').toUpperCase()}</div>
                <div className={s.moreContent}>
                    {eventsWithTimeOverlap.map((evt, idx) => {
                        const startTime = dayjs(new Date(evt.start.dateTime)).format('HH:mm A');
                        const isConfirm = evt.status === EventStatus.CONFIRMED;
                        return (
                            <div
                                key={idx}
                                className={s.moreTitle}
                                onClick={() => {
                                    if (onEventClick) {
                                        onEventClick(evt);
                                    }
                                }}
                            >
                                <span className={isConfirm ? s.confirm : s.pending} /> <b>{startTime}</b> {evt.summary}
                            </div>
                        );
                    })}
                </div>
            </div>
        )
        : null;
    return (
        <div className={s.wrap}>
            <div className={s.item}>
                {rowInx === 0 && (
                    <div className={s.header}>
                        {day.format('ddd').toUpperCase()}
                    </div>
                )}
                <div
                    className={s.content}
                >
                    <div><span className={isCurrentDay ? `${s.dayLabel} ${s.today}` : s.dayLabel}>{day.format('DD')}</span></div>
                    <div
                        className={s.eventWrap}
                        onClick={() => {
                        }}
                    >
                        {displayItem.map((evt, idx) => {
                            const startTime = dayjs(new Date(evt.start.dateTime)).format('HH:mm A');
                            const isConfirm = evt.status === EventStatus.CONFIRMED;
                            return (
                                <div
                                    key={idx}
                                    className={s.title}
                                    onClick={() => {
                                        if (onEventClick) {
                                            onEventClick(evt);
                                        }
                                    }}
                                >
                                    <span className={isConfirm ? s.confirm : s.pending} /> {startTime} {evt.summary}
                                </div>
                            );
                        })}
                        {
                            showMore && (
                                <Popover content={content} trigger="click"><div className={s.moreLabel}>{moreItems} more</div></Popover>
                            )
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Day;
