// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--FKLFk {
  display: flex;
  flex-direction: column;
}
.s-module__rowWrap--CUKSG {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentManagePage/components/Month/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n}\n\n.rowWrap{\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--FKLFk`,
	"rowWrap": `s-module__rowWrap--CUKSG`
};
export default ___CSS_LOADER_EXPORT___;
