import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Checkbox, Radio } from 'antd';
import ChannelStore from 'store/Channel';
import { TChannel } from '../../type';

interface IProps {
    setNextButtonDisabled: (v: boolean) => void;
    setConnectChannes: (v: TChannel[]) => void;
}

const ExisttingChannel = ({
    // formId,
    setNextButtonDisabled,
    setConnectChannes,
}: IProps) => {
    const [getChannelStore] = ChannelStore.useStore();
    const channelList = getChannelStore('collectedChannels');
    const [radioChecked, setRadioChecked] = useState(false);
    const [selectedChannels, setSelectedChannels] = useState<TChannel[]>([]);

    const setDefaultSelectedChannel = useCallback(() => {
        if (channelList && channelList.length > 0) {
            const defaultSelectedChannel = channelList.find((item) => item.name === 'Psychology Today');

            if (defaultSelectedChannel) {
                const { id, name } = defaultSelectedChannel;
                setSelectedChannels([{
                    id,
                    name,
                    price: name === 'Psychology Today' ? 30 : 25,
                    fieldName: name.replace(/\s+/g, '').toLowerCase(),
                }]);
            }
        }
    }, [channelList]);

    useEffect(() => {
        setDefaultSelectedChannel();
    }, [setDefaultSelectedChannel]);

    useEffect(() => {
        if (selectedChannels.length > 0) {
            setRadioChecked(false);
        } else {
            setRadioChecked(true);
        }
        setConnectChannes(selectedChannels);
    }, [selectedChannels, setConnectChannes]);

    useEffect(() => {
        if (selectedChannels.length === 0 && !radioChecked) {
            setNextButtonDisabled(true);
        } else {
            setNextButtonDisabled(false);
        }
    }, [radioChecked, selectedChannels, setNextButtonDisabled]);

    const handleRadioChange = useCallback((e: any) => {
        setRadioChecked(e.target.checked);
        if (e.target.checked) {
            setSelectedChannels([]);
        }
    }, []);

    const handleChannelChecked = useCallback((e: TChannel) => {
        if (selectedChannels.find((item) => item.id === e.id)) {
            const restData = selectedChannels.filter((item) => item.id !== e.id);
            setSelectedChannels(restData);
        } else {
            setSelectedChannels([...selectedChannels, e]);
        }
    }, [selectedChannels]);

    return (
        <div className={s.wrap}>
            <div className={s.step}>Delegate your channel management to Kiwi</div>
            <div className={s.title}>Why let Kiwi manage your channels?</div>
            <div className={s.manageChannel}>
                <div className={s.list}>
                    <div className={s.item}>
                        <div className={s.title}>🌟 Enhanced visibility:</div>
                        <div className={s.desc}>{'Our team of experts will optimize your profile for better search engine ranking, attracting more potential clients. We\'ll ensure your profile information matches your UniProfile, creating a cohesive online presence.'}</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.title}>📈 Expanded reach:</div>
                        <div className={s.desc}>{'For Psychology Today, we\'ll add additional locations (4 states) to your profile, increasing your reach to a wider audience.'}</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.title}>🕒 Time savings:</div>
                        <div className={s.desc}>Let us handle the updates and maintenance, freeing you to focus on your practice.</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.title}>🎁 Completely Free:</div>
                        <div className={s.desc}>All of these services are free.</div>
                    </div>
                </div>
            </div>
            <div className={s.channelSelectTitle}>Do you already have a Psychology Today or Mental Health Match account?</div>
            <div className={s.channelSelectDesc}>{'We\'re excited to now offer management for these platforms. If you already have an account, select it below to let us handle the management!'}</div>
            <div className={s.channelSelect}>
                {
                    channelList?.map((channel) => {
                        const { id, name, logo } = channel;
                        const checked = !!selectedChannels.find((item) => item.id === id);
                        const channelInfo = {
                            id,
                            name,
                            price: name === 'Psychology Today' ? 30 : 25,
                            fieldName: name.replace(/\s+/g, '').toLowerCase(),
                        };
                        return (
                            <div key={id} className={`${s.channel} ${checked ? s.checked : ''}`} onClick={() => handleChannelChecked(channelInfo)}>
                                <Checkbox checked={checked} className={s.checkbox} />
                                <img src={logo} alt={name} />
                                <div className={s.channeName}>{name}</div>
                            </div>
                        );
                    })
                }
                <div className={`${s.deselecAll} ${radioChecked ? s.checked : ''}`}>
                    <Radio checked={radioChecked} onChange={handleRadioChange}><span className={s.radio}>{'I don\'t have accounts on these channels.'}</span></Radio>
                </div>
            </div>
        </div>
    );
};

export default ExisttingChannel;
