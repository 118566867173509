import React from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import highfive from 'assets/channel/highfive.svg';
import dayjs from 'dayjs';

interface IProps {
    nextBillingDate: string | null,
    onClose: () => void;
}

const ReactiveSuccessfully = ({
    nextBillingDate,
    onClose,
}: IProps) => {
    return (
        <div className={s.wrap}>
            <div className={s.title}>Channel successfully reactivated</div>
            <div className={s.content}>
                <img className={s.pic} src={highfive} />
                <p>Your premium channel has been successfully reactivated. We're pleased you're continuing to utilize this platform to enhance your reach and connect with more patients. Please note that your subsequent payment for this channel will be processed after the current billing cycle concludes. Your next billing date is <strong>{nextBillingDate ? dayjs(nextBillingDate).format('MM/DD/YYYY') : ''}</strong></p>
            </div>
            <div className={s.footer}>
                <Button type="primary" onClick={onClose}>Close</Button>
            </div>
        </div>
    );
};

export default ReactiveSuccessfully;
