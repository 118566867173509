import React, { useCallback, useEffect, useState } from 'react';
import { Button, Space, message, Modal, Spin } from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { providerServiceDelete } from 'api/appointmentSetting';
import ServiceUpdateModal from 'components/ServiceUpdateModal';
import useCalendarColors from 'hooks/useCalendarColors';
import useIntakeqForm from 'hooks/useIntakeqForm';
import { Color } from 'types/calendar';
import { IntakeqForm, Service } from 'types/common';
import useService from 'hooks/useService';
import s from './s.module.less';
import { getProviderFormDetail } from 'api/applicationForm';
import { PracitceAddressForm } from 'types/practiceFront';
import { transAddressDataToServiceOptions } from 'utils/common';

interface IProps {
    useAdminApi?: boolean;
}

const ServicesPage = ({
    useAdminApi,
}: IProps) => {
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [serviceItem, setServiceItem] = useState<Service>();
    const [colors] = useCalendarColors({ useAdminApi });
    const [serviceData, loading, fetchData] = useService();
    const [intakeName] = useIntakeqForm({
        isOps: false,
    });
    const [practiceAddressLoading, setPracticeAddressLoading] = useState(false);
    const [pracitceAddressList, setPracitceAddressList] = useState<Array<PracitceAddressForm>>([]);
    const [addressOptions, setAddressOptions] = useState<Array<{ value: number, label: string, isDefault: boolean }>>([]);

    const fetchChannelProviderData = useCallback(async () => {
        setPracticeAddressLoading(true);
        const res = await getProviderFormDetail({
            allowAuth: false,
            step: 'PRACTICE',
        });
        if (!res.error) {
            const providerData = res.data?.data;
            const providerPractice = providerData?.providerPractice || {};
            const addressList = providerPractice?.addressList || [];

            setPracitceAddressList(addressList);
        } else {
            console.error(res);
        }
        setPracticeAddressLoading(false);
    }, []);

    useEffect(() => {
        fetchChannelProviderData();
    }, [fetchChannelProviderData]);

    useEffect(() => {
        const addressResp = transAddressDataToServiceOptions(pracitceAddressList);

        setAddressOptions(addressResp);
    }, [pracitceAddressList]);

    const getEventColor = (record: Service, color:Color[]):string => {
        const target = color?.find((i) => {
            return i.dictKey === record.colorId;
        });
        return target ? target.content : '#4499df';
    };

    const getIntakeName = (record: Service, name: IntakeqForm[]): string => {
        const target = name?.find((i) => {
            return i.id === record.sendForm;
        });
        return target ? target.name : '';
    };

    const deleteFunc = async (record: Service) => {
        modal.confirm({
            width: '600px',
            closable: true,
            title: `Do you confirm remove this service - ${record.name}?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes, confirm',
            cancelText: 'Cancel',
            onOk: async () => {
                const id = record?.id;
                try {
                    await providerServiceDelete(id!).finally(() => fetchData());
                } catch (error) {
                    console.error(error);
                }
            },
        });
    };

    const addNewService = () => {
        setIsEdit(false);
        setOpen(true);
        setServiceItem(undefined);
    };

    const editService = (record: Service) => {
        setServiceItem(record);
        setIsEdit(true);
        setOpen(true);
    };

    const onSuccess = () => {
        setOpen(false);
        fetchData();
        message.success(isEdit ? 'Update success' : 'Add success');
    };

    const columns: ColumnsType<Service> = [
        {
            title: 'TYPE',
            dataIndex: 'type',
            key: 'type',
            align: 'left',
            width: '180px',
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: '200px',
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            width: '250px',
        },
        {
            title: 'DURATION',
            dataIndex: 'duration',
            key: 'duration',
            align: 'center',
            width: '160px',
            render: (text) => (
                <span>{text} mins</span>
            ),
        },
        {
            title: 'PRICE',
            dataIndex: 'price',
            key: 'price',
            width: '180px',
            render: (price, item) => (
                <div>
                    <p>{price}</p>
                    {
                        item.enableSlidingScale &&
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>[{item.lowestPrice?.toFixed(2)} ~ {item.highestPrice?.toFixed(2)}]
                        </p>
                    }
                </div>
            ),
        },
        {
            title: 'COLOR',
            dataIndex: 'color',
            key: 'color',
            align: 'center',
            width: '160px',
            render: (_, record) => (
                <span className={s.colorItem} style={{ background: getEventColor(record, colors) }} />
            ),
        },
        {
            title: '',
            align: 'center',
            render: (_, record: Service) => (
                <Space size="small">
                    <Button className="text-indigo-600" type="text" onClick={() => editService(record)}>Edit</Button>
                    <Button className="text-indigo-600" type="text" onClick={() => deleteFunc(record)}>Remove</Button>
                </Space>
            ),
        },
    ];

    return (
        <div className={s.wrap}>
            <Spin spinning={practiceAddressLoading}>
                <div className={s.header}>
                    <div className={s.title}>Manage your services</div>
                    <Button
                        onClick={addNewService}
                        icon={<PlusOutlined />}
                    >
                        Add a new service
                    </Button>
                </div>
                <CommonTable
                    bordered
                    loading={loading}
                    columns={columns}
                    data={serviceData}
                />
                {
                    open &&
                    <ServiceUpdateModal
                        addressList={addressOptions}
                        isEdit={isEdit}
                        serviceItem={serviceItem}
                        onCancel={() => setOpen(false)}
                        onSuccess={onSuccess}
                    />
                }
                {contextHolder}
            </Spin>
        </div>
    );
};

export default ServicesPage;
