import React from 'react';
import { Form, Input, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { BillingFormFieldEnum, BillingType } from '../../BillingWrap/types';
import StateSelect from 'components/form/StateSelect';
import ChannelSelect from 'components/form/ChannelSelect';

type Props = {
    onCancel?:()=>void,
    onSave?:()=>void,
    onDelete?: () => void,
    form?: FormInstance<BillingType>,
};

export default function BillingForm(props: Props) {
    const { onCancel, onSave, onDelete, form } = props;
    return (
        <Form
            className="mt-4"
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
        >

            <Form.Item
                label="Bank name"
                name={BillingFormFieldEnum.BANK_NAME}
                rules={[{ required: true, message: 'Bank name is required' }]}
            >
                <Input style={{ width: '50%' }} />
            </Form.Item>
            <Form.Item
                label="Bank routing number"
                name={BillingFormFieldEnum.BANK_ROUTING_NUMBER}
                rules={[{ required: true, message: 'Bank routing number is required' }]}
            >
                <Input style={{ width: '50%' }} />
            </Form.Item>
            <Form.Item
                label="Account holder"
                name={BillingFormFieldEnum.ACCOUNT_HOLDER}
                rules={[{ required: true, message: 'Account holder is required' }]}
            >
                <Input style={{ width: '50%' }} />
            </Form.Item>
            <Form.Item
                label="Account number"
                name={BillingFormFieldEnum.ACCOUNT_NUMBER}
                rules={[{ required: true, message: 'Account number is required' }]}
            >
                <Input style={{ width: '50%' }} />
            </Form.Item>

            <div className="flex w-2/3">
                <div className="w-1/2">
                    <Form.Item
                        className="mr-8"
                        label="Billing address"
                        name={BillingFormFieldEnum.BILLING_ADDRESS}
                        rules={[{ required: true, message: 'Billing address is required' }]}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className="w-1/4">
                    <Form.Item
                        className="mr-8"
                        label="ZIP code"
                        name={BillingFormFieldEnum.ZIP_CODE}
                        rules={[{ required: true, message: 'ZIP code is required' }]}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className="w-1/4">
                    <Form.Item
                        label="State"
                        name={BillingFormFieldEnum.STATE}
                        rules={[{ required: true, message: 'State is required' }]}
                    >
                        <StateSelect />
                    </Form.Item>
                </div>
            </div>

            <div className="w-1/2">
                <Form.Item
                    label="Linked channels"
                    name={BillingFormFieldEnum.CHANNEL}
                    rules={[{ required: true, message: 'Channel is required' }]}
                >
                    <ChannelSelect mode="tags" />
                </Form.Item>
            </div>
            <div className="flex justify-between items-center">
                <div className="flex">
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="primary" style={{ marginLeft: '10px' }} onClick={onSave}>Save</Button>
                </div>
                {
                    form?.getFieldValue([BillingFormFieldEnum.BANK_NAME]) !== undefined && <Button danger type="text" onClick={onDelete}>Remove from the billing account list</Button>
                }
            </div>
        </Form>
    );
}
