import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    HomeIcon,
} from 'components/MenuIcons';
import s from './s.module.less';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import DocumentIcon from 'assets/common/documentIcon.svg';
import GlobeIcon from 'assets/common/globeIcon.svg';
import ExternalLinkIcon from 'assets/common/externalLinkIcon.svg';
import { getChannelDetailById } from 'api/channel';
import { useRequest } from 'ahooks';
import ChannelStatus from 'components/ChannelStatus';
import ChannelStartButton from 'components/ChannelStartButton';
import ProviderStore from 'store/Provider';
import { PATH } from 'constants/path';
import useListToChannel from 'hooks/useListToChannel';
import { getProviderFormDetail } from 'api/applicationForm';
import { Button, Modal, Spin, message } from 'antd';
import SingleServiceTypeChannelModal from 'components/SingleServiceTypeChannelModal';
import { NoticeType } from 'antd/es/message/interface';
import { PREVIEW_GOOGLE_NAME, PREVIEW_YELP_NAME, PREVIEW_ZOCDOC_NAME, PAYMENT_WEBSITE_NAME, SINGLE_TYPE_SINGLE_SERVICE } from 'constants/common';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { openSubscriptionModal, openChannelSubscriptionModal, openKlaritySubscriptionModal } from 'utils/globalLayerControl';
import { ChannelPayPlanTypeEnum } from 'types/channel';
import commonS from 'styles/common.module.less';
import { checkHasSubmit, getProviderKlarityPlanLevel, isFreeUser, shouldShowKlarityPlanUI } from 'utils/provider';
import useChannelHomeInfo from 'hooks/useChannelHomeInfo';
import { getPendingSubscriptionChannel, setPendingSubscriptionChannel } from 'utils/localstore';
import { EChannelActiveStatus } from 'types/common';
import ChannelNotFound from '../ChannelNotFound';
// import { getUrlParam } from 'utils/common';
// import PaymentSuccess from './components/PaymentSuccess';
import homeStore from 'store/Home';

const ChannelDetailPage = () => {
    // const from = getUrlParam('from');
    const navigate = useNavigate();
    const [info, loadingHome] = useChannelHomeInfo();
    const [messageApi, contextHolder] = message.useMessage();
    // 如果某个channel不允许重复，但是目前还没有重复service的时候。
    const [submitWithoutSingleServiceSelect, setSubmitWithoutSingleServiceSelect] = useState(false);
    const [open, setOpen] = useState(false);
    const [hideTip, setHideTip] = useState<boolean>(false);
    // const [isFromPaymentSite, setIsFromPaymentSite] = useState(false);
    const [params] = useSearchParams();
    const channelId = params.getAll('channelId')[0];
    const [getUser] = ProviderStore.useStore();
    const { data = {}, loading } = useRequest(getChannelDetailById, {
        defaultParams: [channelId],
    });
    const [getHomeSore] = homeStore.useStore();
    const homeInfo = getHomeSore('data');
    const klarityUserWithoutKiwiPlan = !!homeInfo?.klarityUserWithoutKiwiPlan;

    const myPlanLevel = getProviderKlarityPlanLevel(homeInfo);

    const user = getUser('data');
    const pramas = useRef({
        allowAuth: false,
        channelId,
        providerId: user?.id,
        step: 'REVIEW',
    });
    const { data: providerData } = useRequest(getProviderFormDetail, {
        defaultParams: [pramas.current],
    });
    const pendingChannel = getPendingSubscriptionChannel();
    const isStripePaymentSuccessful = !!pendingChannel;

    const { caseStudy, name, website, listStatus, currentStep, channelDesc, providerWebsite, news, economics, logo, payPlanType, payStatus, isShow, activeStatus, klarityPlanLevel } = data?.data?.data || {};

    const providerServiceList = useMemo(() => providerData?.data?.data?.providerServiceList, [providerData]);
    const [, loadingSubscriptionPlan] = useSubscriptionPlan();
    const isFree = isFreeUser();

    const onShowMessage = useCallback((type: NoticeType, content: string) => {
        messageApi.open({
            type,
            content,
        });
    }, [messageApi]);

    const onShowSelectionModal = useCallback((status: boolean) => {
        setOpen(status);
    }, []);

    const showKlarityPlanUI = shouldShowKlarityPlanUI(homeInfo);

    const {
        handleChannelConfirmSubmit,
        handleModalOk,
        singleServiceTypeIds,
        handleSingleServiceTypeSelected,
        loading: listLoading,
    } = useListToChannel({
        services: providerServiceList,
        channelId: Number(channelId),
        onShowMessage,
        onShowSelectionModal,
        submitWithoutSingleServiceSelect,
        successPagePath: `${PATH.CHANNEL_DETAIL_LIST_SUCCESS}?channelId=${channelId}`,
        from: PATH.CHANNEL_DETAIL,
        channelName: encodeURIComponent(name),
        IgnoreMultipleServiceSelect: data?.data?.data?.serviceConfig !== SINGLE_TYPE_SINGLE_SERVICE,
        isKlarityPlan: klarityUserWithoutKiwiPlan,
    });

    const handleServiceListFeedback = useCallback((status: boolean) => {
        setSubmitWithoutSingleServiceSelect(status);
    }, []);

    const directoWebsite = useCallback(() => {
        window.open(website?.indexOf('http') === 0 ? website : `https://${website}`);
    }, [website]);

    const handleHideTip = useCallback(() => setHideTip(true), []);

    const handleListToChannel = useCallback(() => {
        if (
            (payPlanType === ChannelPayPlanTypeEnum.PREMIUM && payStatus !== 'paid') || // kiwi plan
            (showKlarityPlanUI && payPlanType === ChannelPayPlanTypeEnum.PREMIUM && klarityPlanLevel === 0 && payStatus !== 'paid') // klarity plan
        ) {
            const channelData = data?.data?.data;

            if (channelData) {
                openChannelSubscriptionModal({ channel: channelData });
            }
        } else {
            handleChannelConfirmSubmit?.();
        }
    }, [payPlanType, payStatus, klarityPlanLevel, data, handleChannelConfirmSubmit, showKlarityPlanUI]);
    const handleCloseModal = useCallback(() => {
        setOpen(false);
        handleSingleServiceTypeSelected([]);
    }, [handleSingleServiceTypeSelected]);

    const handlePay = useCallback(() => {
        let path = window.location.pathname;
        const fromStr = `from=${PAYMENT_WEBSITE_NAME}`;
        if (window.location.search) {
            path += `${window.location.search}&${fromStr}`;
        } else {
            path += `?${fromStr}`;
        }

        if (showKlarityPlanUI) {
            openKlaritySubscriptionModal(false, path);
        } else {
            openSubscriptionModal(false, path);
        }
    }, [showKlarityPlanUI]);

    useEffect(() => {
        if (isStripePaymentSuccessful && `${pendingChannel?.id}` === `${channelId}` && name) {
            handleListToChannel();
            setPendingSubscriptionChannel();
        }
    }, [isStripePaymentSuccessful, handleListToChannel, pendingChannel?.id, channelId, name, pendingChannel]);

    // const handleListAfterPayment = useCallback(() => {
    //     setIsFromPaymentSite(false);
    //     handleListToChannel();
    // }, [handleListToChannel]);

    const previewPageLink = useMemo(() => {
        if (user?.id) {
            if (name?.toLowerCase() === PREVIEW_ZOCDOC_NAME.toLowerCase()) {
                return `/${PATH.PREVIEW}/${PATH.ZOCDOC}/${user.id}?channelId=${channelId}`;
            } else if (name?.toLowerCase() === PREVIEW_GOOGLE_NAME.toLowerCase()) {
                return `/${PATH.PREVIEW}/${PATH.GOOGLE}/${user.id}?channelId=${channelId}`;
            } else if (name?.toLowerCase() === PREVIEW_YELP_NAME.toLowerCase()) {
                return `/${PATH.PREVIEW}/${PATH.YELP}/${user.id}?channelId=${channelId}`;
            }
        }

        return '';
    }, [channelId, name, user]);

    const previewBtnText = useMemo(() => {
        if (user?.id) {
            if (name?.toLowerCase() === PREVIEW_ZOCDOC_NAME.toLowerCase()) {
                return PREVIEW_ZOCDOC_NAME;
            } else if (name?.toLowerCase() === PREVIEW_GOOGLE_NAME.toLowerCase()) {
                return PREVIEW_GOOGLE_NAME;
            }
        }

        return 'Profile';
    }, [name, user]);

    const tagRender = useMemo(() => {
        if (payPlanType === ChannelPayPlanTypeEnum.FREE) {
            return {
                cls: s.free,
                text: 'Free',
            };
        } else if (!payPlanType || payPlanType === ChannelPayPlanTypeEnum.PAID) {
            return {
                cls: s.paid,
                text: 'Standard',
            };
        } else if (payPlanType === ChannelPayPlanTypeEnum.PREMIUM) {
            return {
                cls: s.premium,
                text: 'Premium',
            };
        }

        return null;
    }, [payPlanType]);

    const channelStartButtonRender = useMemo(() => {
        const hasSubmit = !!checkHasSubmit(info?.profileStatus);
        if (hasSubmit && listStatus === 'not-listed') {
            if (klarityUserWithoutKiwiPlan) {
                if (myPlanLevel < klarityPlanLevel) {
                    return <Button type="primary" onClick={handlePay}>Upgrade to list</Button>;
                }
            } else if (isFree && payPlanType !== ChannelPayPlanTypeEnum.FREE) {
                return <Button type="primary" onClick={handlePay}>Upgrade to list</Button>;
            }
        }

        return <ChannelStartButton
            hasSubmit={hasSubmit}
            loading={loading}
            ghost={listStatus === 'started' || listStatus === 'submitted'}
            listStatus={listStatus}
            id={Number(channelId)}
            currentStep={currentStep}
            profileWebsite={providerWebsite}
            handleListToChannel={handleListToChannel}
        />;
    }, [info?.profileStatus, listStatus, loading, channelId, currentStep, providerWebsite, handleListToChannel, klarityUserWithoutKiwiPlan, isFree, payPlanType, myPlanLevel, klarityPlanLevel, handlePay]);

    const pageIsLoading = (loading || loadingSubscriptionPlan || listLoading || loadingHome);

    let width: string = '88%';
    if (window.screen.availWidth * 0.88 > 824) {
        width = '824px';
    }

    if (loading) {
        return <div className={s.spinnerWrap}><Spin spinning /></div>;
    }

    if (!isShow || activeStatus !== EChannelActiveStatus.ACTIVE) {
        return <ChannelNotFound />;
    }

    return (
        <Spin spinning={pageIsLoading}>
            <div className={s.wrapper}>
                {contextHolder}
                {/* <Modal
                    maskClosable={false}
                    centered
                    open={!pageIsLoading && isFromPaymentSite}
                    footer={null}
                    onCancel={() => setIsFromPaymentSite(false)}
                    width={width}
                    className={commonS.modalFixHeightWrap}
                >
                    <PaymentSuccess onList={handleListAfterPayment} />
                </Modal> */}
                <Modal
                    centered
                    open={open}
                    onOk={handleModalOk}
                    onCancel={handleCloseModal}
                    width={1000}
                    className={commonS.modalFixHeightWrap}
                    confirmLoading={listLoading}
                >
                    <SingleServiceTypeChannelModal
                        logo={logo}
                        name={name}
                        checkedIds={singleServiceTypeIds}
                        onServiceListFeedback={handleServiceListFeedback}
                        onChange={handleSingleServiceTypeSelected}
                    />
                </Modal>
                <div
                    onClick={() => {
                        navigate('/dashboard/channel');
                    }}
                    className={s.breadcrumb}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.70711 16.7071C9.31659 17.0976 8.68342 17.0976 8.2929 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.2929 3.29289C8.68342 2.90237 9.31659 2.90237 9.70712 3.29289C10.0976 3.68342 10.0976 4.31658 9.70712 4.70711L5.41422 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41422 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z" fill="#6B7280" />
                    </svg>
                    <div className={s.breadcrumbItem}>Back to Channel store</div>
                </div>
                <div className={s.header}>
                    <div className={s.intro}>
                        <div className={s.info}>
                            <div className={s.name}>{name}</div>
                            <ChannelStatus status={listStatus} />
                            {tagRender && <div className={`${s.tag} ${tagRender.cls}`}>{tagRender.text}</div>}
                        </div>
                        <div className={s.details}>
                            {/* <div className={s.detail}>
                            <img src={ClockIcon} />
                            <div className={s.content}>{estimatedApplicationTime}mins</div>
                        </div> */}
                            {
                                listStatus === 'listed' &&
                                (
                                    <div className={s.detail}>
                                        <img src={DocumentIcon} />
                                        <div className={s.content}>Channel profile is up to date</div>
                                    </div>
                                )
                            }
                            <div className={s.detail}>
                                <img src={GlobeIcon} />
                                <div className={s.linkContent} onClick={directoWebsite}><span style={{ marginRight: 6 }}>{website}</span><img src={ExternalLinkIcon} /></div>
                            </div>
                        </div>
                    </div>
                    <div className={s.headerButtonBox}>
                        {
                            listStatus === 'under-review' && previewPageLink &&
                            <Link to={previewPageLink}>
                                <div className={s.viewProfile}>
                                    Preview my {previewBtnText} Page
                                </div>
                            </Link>
                        }
                        {channelStartButtonRender}
                    </div>
                </div>
                {
                    !hideTip && news && (
                        <div className={s.tip}>
                            <div className={s.tipContent}>{news}</div>
                            <div className={s.close} onClick={handleHideTip} />
                        </div>
                    )
                }
                <div className={s.body}>
                    <div className={s.desc}>
                        <div className={s.intro}>
                            <div className={s.title}>
                                What is {name}?
                            </div>
                            <div
                                className={s.content}
                                dangerouslySetInnerHTML={
                                    { __html: channelDesc }
                                }
                            />
                        </div>
                        <div className={s.intro}>
                            <div className={s.title}>
                                How does it work?
                            </div>
                            <div
                                className={s.content}
                                dangerouslySetInnerHTML={
                                    { __html: economics }
                                }
                            />
                        </div>
                        <div className={s.intro}>
                            <div className={s.title}>
                                What are providers’ feedback?
                            </div>
                            <div
                                className={s.content}
                                dangerouslySetInnerHTML={
                                    { __html: caseStudy }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default ChannelDetailPage;
