import React, { useState, useEffect } from 'react';
import { Spin, Divider, Button, Form, message } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';

import WeekItem from './components/WeekItem';
import TimeZoneSelect from 'components/form/TimeZoneSelect';

import { TimeFormFieldEnum, TimeFormInterface } from './types';
import { providerAvailabilitySave } from 'api/appointmentSetting';
import { IproviderAvailabilityItem } from 'types/appointmentSetting';
import { getUserId, setTimeZone } from 'utils/localstore';
import useAvailability from 'hooks/useAvailability';

const TimezoneOperationhours = () => {
    const [form] = Form.useForm<TimeFormInterface>();
    const [weekList, setWeekList] = useState<IproviderAvailabilityItem[]>([]);
    const [availabilityList, loading, fetchData] = useAvailability();
    const uid = getUserId();

    const changeTimeList = (e: IproviderAvailabilityItem[]) => {
        setWeekList(e);
    };

    const onUpdate = async () => {
        try {
            const formValues = await form.validateFields();
            const params = {
                week: weekList,
                timezone: formValues[TimeFormFieldEnum.TIME_ZONE],
                providerId: uid,
            };
            const { data } = await providerAvailabilitySave(params, uid!);
            if (data.code === 200) {
                setTimeZone(formValues[TimeFormFieldEnum.TIME_ZONE]);
                message.success('Update Hours of Operation success!');
                fetchData();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const sortByWeekDay = (a:IproviderAvailabilityItem, b:IproviderAvailabilityItem) => {
        const day:any = {
            sunday: 0,
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
        };
        return day[a.weekDay!] - day[b.weekDay!];
    };

    useEffect(() => {
        if (availabilityList) {
            const { timezone, week } = availabilityList;
            form.setFieldsValue({ [TimeFormFieldEnum.TIME_ZONE]: timezone });
            week?.sort(sortByWeekDay);
            setWeekList(week || []);
        }
    }, [availabilityList]);

    return (
        <Spin spinning={loading}>
            <div className={s.wrap}>
                <div className="bg-white rounded-lg px-7 pt-3 py-7">
                    <div className={`${s.title} text-gray-800 text-xl font-medium`}>Time zone and operation hours</div>
                    <Divider />
                    <div className="mt-4">
                        <Form
                            form={form}
                            name="basic"
                            className={commonS.formStyle1}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item
                                label={<div className={s.hourTitle}>Time zone</div>}
                                name={[TimeFormFieldEnum.TIME_ZONE]}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Time zone is required' },
                                ]}
                            >
                                <TimeZoneSelect className={s.timezone} />
                            </Form.Item>
                        </Form>
                        <div className={s.hourTitle}>
                            Hours of Operation
                        </div>
                        <div className="w-2/3">
                            {
                                weekList && weekList.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <WeekItem weekList={weekList} weekItemData={item} changeTimeList={changeTimeList} />
                                            <Divider />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <Button className="mt-7" onClick={onUpdate}>Update</Button>
                </div>
            </div>
        </Spin>
    );
};

export default TimezoneOperationhours;
