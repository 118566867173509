import React, { useState, useEffect } from 'react';
import { Switch, message } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import TimePicker from 'components/form/TimePicker';
import { IproviderAvailabilityItem, TimeItemType } from 'types/appointmentSetting';
import s from './s.module.less';

type Props = {
    weekList?: IproviderAvailabilityItem[],
    weekItemData?: IproviderAvailabilityItem,
    changeTimeList?: (val: IproviderAvailabilityItem[]) => void,
};

const initValues: TimeItemType = {
    fromHour: undefined,
    fromMinute: undefined,
    toHour: undefined,
    toMinute: undefined,
};

export default function WeekItem({ weekList, weekItemData, changeTimeList }: Props) {
    const [hourFromToData, setHourFromToData] = useState<TimeItemType[]>([]);

    const callBackFunc = (val: TimeItemType[]) => {
        weekList?.map((item) => {
            if (item.weekDay === weekItemData?.weekDay) {
                item.hourFromTo = val;
            }
            return item;
        });
        changeTimeList?.(weekList!?.slice());
    };

    const onSwitchChange = (checked: boolean) => {
        const newData = [...hourFromToData];
        if (checked === true) {
            newData.push(initValues);
        } else {
            newData.splice(0, hourFromToData.length);
            callBackFunc(newData);
        }
        setHourFromToData(newData);
    };
    const addItem = () => {
        if (hourFromToData.length === 10) return message.error('Max 10 time slot per day');
        const newData = [...hourFromToData];
        newData.push(initValues);
        setHourFromToData(newData);
    };

    const deleteItem = (i: number) => {
        const newData = [...hourFromToData];
        newData.splice(i, 1);
        setHourFromToData(newData);
    };

    const timePickerFunc = (val: TimeItemType, i:number) => {
        const newData = [...hourFromToData];
        newData[i] = val;
        setHourFromToData(newData);
        callBackFunc(newData);
    };

    useEffect(() => {
        if (weekItemData) {
            setHourFromToData(weekItemData?.hourFromTo || []);
        }
    }, [weekItemData]);

    return (
        <div className={s.wrap}>
            <div className={s.switchWrap}>
                <Switch checked={hourFromToData.length > 0} onChange={(checked: boolean) => onSwitchChange(checked)} />
                <span className={s.weekDayLabel}>{weekItemData?.weekDay}</span>
            </div>

            <div className="flex justify-between w-3/4">
                <div className="flex flex-col">
                    {
                        hourFromToData && hourFromToData.length >= 1 ?
                            (

                                hourFromToData.map((item, i) => {
                                    return (
                                        <div key={Math.random()} className="mt-1 mb-1 flex items-center">
                                            <TimePicker value={item} onChange={(val) => timePickerFunc(val, i)} />
                                            {
                                                hourFromToData.length >= 1 && <CloseOutlined className="ml-10 w-6 h-6 text-gray-400" onClick={() => deleteItem(i)} />
                                            }
                                        </div>
                                    );
                                })

                            )
                            : <span className="text-gray-700 font-normal text-lg mt-1 mb-1">Unavailable</span>
                    }
                </div>
                {
                    hourFromToData.length >= 1 && <PlusOutlined className="text-indigo-700 w-6 h-6 cursor-pointer mt-1 mb-1" onClick={() => addItem()} />
                }
            </div>
        </div>
    );
}
