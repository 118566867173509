import { ColorKey } from 'constants/calendar';
import { Event } from 'types/calendar';
import { Channel, Service } from 'types/common';

export const isAppointment = (event:Event, services: Service[], channels: Channel[]):boolean => {
    if (!event.providerId) {
        return false;
    }

    if (ColorKey.appointment === event.colorId) {
        //need consider services
        return true;
    }
    if (services && event.colorId && services.find((service:Service) => { return service.colorId === event.colorId; })) {
        return true;
    }
    const title = event.summary;

    if (channels && title && channels.find((channel:Channel) => { return title.includes(`[${channel.name}]`); })) {
        return true;
    }
    return false;
};

export const isBpEvent = (event:Event):boolean => {
    const title = event.summary;
    if (ColorKey.event === event.colorId && title.includes('[Klarity]')) {
        return true;
    }
    return false;
};

export const isBlockTime = (event:Event):boolean => {
    const title = event.summary;
    if (ColorKey.blockTime === event.colorId && title.includes('[Klarity]')) {
        return true;
    }
    return false;
};
