import React from 'react';
import { Tabs } from 'antd';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

import SettingIcon from 'assets/setting/Link.svg';
import SettingArrow from 'assets/setting/Chevron right.svg';
import AviabilityPage from './components/AviabilityPage';
import ServicesPage from './components/ServicesPage';
import BillingPage from './components/BillingPage';

const Page = () => {
    const navigate = useNavigate();
    return (
        <div className={`${s.wrap} px-6 py-12`}>
            <div className={s.nav}>
                <div className="flex">
                    <img className="w-5 h-5" style={{ marginRight: '10px' }} src={SettingIcon} />
                    <div className="text-gray-500 font-medium text-sm cursor-pointer" onClick={() => navigate(`${PATH.DASHBOARD}/${PATH.APPOINTMENT}`)}>Appointment</div>
                </div>
                <img className={s.arrow} src={SettingArrow} alt="" />
                <div className="text-indigo-700">Setting</div>
            </div>
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: 'Availability',
                        key: '1',
                        children: <AviabilityPage />,
                    },
                    {
                        label: 'Services',
                        key: '2',
                        children: <ServicesPage />,
                    },
                    {
                        label: 'Billing',
                        key: '3',
                        children: <BillingPage />,
                    },
                ]}
            />
        </div>
    );
};

export default Page;
