import React from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import farewellPic from 'assets/channel/farewell.svg';
import { Channel } from 'types/common';
import dayjs from 'dayjs';

interface IProps {
    removedChannel?: Channel;
    onClose: () => void;
}

const RemoveSuccessfully = ({
    removedChannel,
    onClose,
}: IProps) => {
    return (
        <div className={s.wrap}>
            <div className={s.title}>Channel has been removed successfully</div>
            <div className={s.content}>
                <img className={s.pic} src={farewellPic} />
                <p>Your request to remove the channel has been received successfully. Rest assured, you can still utilize this channel up until <strong>{removedChannel && removedChannel.nextPaymentDue ? dayjs(removedChannel.nextPaymentDue).format('MM/DD/YYYY') : ''}</strong>. Do keep in mind that while we aim for the exact end date, the deactivation might take place 1 or 2 days before or after. </p>
                <p>If you reconsider your decision, you have the opportunity to reactivate the channel during its "Pending Remove" status.</p>
            </div>
            <div className={s.footer}>
                <Button type="primary" onClick={onClose}>Close</Button>
            </div>
        </div>
    );
};

export default RemoveSuccessfully;
