// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--UOmz0 {
  background-color: #F9FAFC;
}
.s-module__wrap--UOmz0 .s-module__nav--wwoQw {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.s-module__wrap--UOmz0 .s-module__nav--wwoQw .s-module__arrow--H_R3b {
  padding: 0 8px;
}
.s-module__wrap--UOmz0 .ant-divider {
  margin: 2px 0;
}
.s-module__wrap--UOmz0 .ant-tabs-nav {
  margin: 18px 0 !important;
}
.s-module__wrap--UOmz0 .ant-tabs-nav-list {
  display: flex;
  width: calc(2/3 * 100%);
  background-color: #fff;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.s-module__wrap--UOmz0 .ant-tabs-nav-list .ant-tabs-tab:nth-child(1) {
  border-right: 1px solid #E5E7EB;
}
.s-module__wrap--UOmz0 .ant-tabs-nav-list .ant-tabs-tab:nth-child(3) {
  border-left: 1px solid #E5E7EB;
}
.s-module__wrap--UOmz0 .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0 !important;
}
.s-module__wrap--UOmz0 .ant-tabs-tab {
  width: calc(1/3 * 100%);
  display: block;
  text-align: center;
  height: 60px;
  line-height: 60px;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
}
.s-module__wrap--UOmz0 .ant-tabs-tab .ant-tabs-tab-btn {
  color: #6b7280;
}
.s-module__wrap--UOmz0 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #111827;
}
.s-module__wrap--UOmz0 .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentSettingPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAFA;EAII,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AAPA;EAQM,cAAA;AAEN;AAVA;EAcM,aAAA;AADN;AAbA;EAkBM,yBAAA;AAFN;AAhBA;EAsBM,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,yBAAA;EACA,2CAAA;EACA,kBAAA;AAHN;AAxBA;EA8BQ,+BAAA;AAHR;AA3BA;EAiCQ,8BAAA;AAHR;AA9BA;EAsCM,2BAAA;AALN;AAjCA;EA0CM,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,UAAA;EACA,gBAAA;EACA,eAAA;AANN;AA3CA;EAoDM,cAAA;AANN;AA9CA;EAwDM,cAAA;AAPN;AAjDA;EA2DM,SAAA;AAPN","sourcesContent":[".wrap {\n  background-color:#F9FAFC;\n\n  .nav{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    .arrow{\n      padding: 0 8px;\n    }\n  }\n\n  :global {\n    .ant-divider {\n      margin: 2px 0;\n    }\n\n    .ant-tabs-nav {\n      margin:  18px 0 !important;\n    }\n\n    .ant-tabs-nav-list {\n      display: flex;\n      width: calc(2/3 * 100%);\n      background-color: #fff;\n      border: 1px solid #E5E7EB;\n      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n      border-radius: 8px;\n\n      .ant-tabs-tab:nth-child(1)  {\n        border-right: 1px solid #E5E7EB;\n      }\n       .ant-tabs-tab:nth-child(3)  {\n        border-left: 1px solid #E5E7EB;\n      }\n    }\n    \n    .ant-tabs-top >.ant-tabs-nav::before {\n      border-bottom: 0 !important;\n    }\n    \n    .ant-tabs-tab {\n      width: calc(1/3 * 100%);\n      display: block;\n      text-align: center;\n      height: 60px;\n      line-height: 60px;\n      padding: 0;\n      font-weight: 500;\n      font-size: 18px;\n    }\n    .ant-tabs-tab .ant-tabs-tab-btn {\n      color: #6b7280;\n    }\n\n    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n      color: #111827;\n    }\n    .ant-tabs .ant-tabs-tab+.ant-tabs-tab {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--UOmz0`,
	"nav": `s-module__nav--wwoQw`,
	"arrow": `s-module__arrow--H_R3b`
};
export default ___CSS_LOADER_EXPORT___;
