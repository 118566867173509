// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/practiceFront/warningIconYellow.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--e9289 {
  padding: 24px 0;
}
.s-module__wrap--e9289 .s-module__editItem--YjrHi {
  position: relative;
  margin-bottom: 24px;
}
.s-module__wrap--e9289 .s-module__editItem--YjrHi .s-module__anchor--iu7bP {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
}
.s-module__wrap--e9289 .s-module__emptyFields--tNtN2 {
  padding: 16px;
  display: flex;
  align-items: flex-start;
  background-color: var(--yellow-50);
  border-radius: 6px;
  margin-bottom: 16px;
}
.s-module__wrap--e9289 .s-module__emptyFields--tNtN2 .s-module__warningIcon--vRhUe {
  margin-right: 12px;
  width: 20px;
  height: 20px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: contain;
}
.s-module__wrap--e9289 .s-module__emptyFields--tNtN2 .s-module__filedsList--cfzbS {
  flex: 1;
  font-family: 'Inter';
  font-style: normal;
}
.s-module__wrap--e9289 .s-module__emptyFields--tNtN2 .s-module__filedsList--cfzbS .s-module__fieldsTitle--LiS93 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--yellow-800);
}
.s-module__wrap--e9289 .s-module__emptyFields--tNtN2 .s-module__filedsList--cfzbS ul {
  margin: 0;
}
.s-module__wrap--e9289 .s-module__emptyFields--tNtN2 .s-module__filedsList--cfzbS ul .s-module__field--G9sOJ {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: var(--yellow-700);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/CustomizePracticeFrontPage/components/HomePage/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAFA;EAIQ,kBAAA;EACA,mBAAA;AACR;AANA;EAQY,kBAAA;EACA,UAAA;EACA,OAAA;EACA,WAAA;AACZ;AAZA;EAgBQ,aAAA;EACA,aAAA;EACA,uBAAA;EACA,kCAAA;EACA,kBAAA;EACA,mBAAA;AADR;AApBA;EAwBU,kBAAA;EACA,WAAA;EACA,YAAA;EACA,oEAAA;EACA,wBAAA;AADV;AA3BA;EA+BU,OAAA;EACA,oBAAA;EACA,kBAAA;AADV;AAhCA;EAoCY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,wBAAA;AADZ;AAtCA;EA0CY,SAAA;AADZ;AAzCA;EA6Cc,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,wBAAA;AADd","sourcesContent":[".wrap {\n    padding: 24px 0;\n\n    .editItem {\n        position: relative;\n        margin-bottom: 24px;\n\n        .anchor {\n            position: absolute;\n            top: -50px;\n            left: 0;\n            width: 100%;\n        }\n    }\n\n    .emptyFields {\n        padding: 16px;\n        display: flex;\n        align-items: flex-start;\n        background-color: var(--yellow-50);\n        border-radius: 6px;\n        margin-bottom: 16px;\n    \n        .warningIcon {\n          margin-right: 12px;\n          width: 20px;\n          height: 20px;\n          background: url('assets/practiceFront/warningIconYellow.svg') no-repeat center;\n          background-size: contain;\n        }\n        .filedsList {\n          flex: 1;\n          font-family: 'Inter';\n          font-style: normal;\n    \n          .fieldsTitle {\n            font-weight: 500;\n            font-size: 14px;\n            line-height: 20px;\n            color: var(--yellow-800);\n          }\n          ul {\n            margin: 0;\n    \n            .field {\n              font-weight: 400;\n              font-size: 14px;\n              line-height: 20px;\n              text-decoration-line: underline;\n              color: var(--yellow-700);\n            }\n          }\n        }\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--e9289`,
	"editItem": `s-module__editItem--YjrHi`,
	"anchor": `s-module__anchor--iu7bP`,
	"emptyFields": `s-module__emptyFields--tNtN2`,
	"warningIcon": `s-module__warningIcon--vRhUe`,
	"filedsList": `s-module__filedsList--cfzbS`,
	"fieldsTitle": `s-module__fieldsTitle--LiS93`,
	"field": `s-module__field--G9sOJ`
};
export default ___CSS_LOADER_EXPORT___;
