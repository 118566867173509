import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import { EditModuleEnum, IProviderStore } from 'types/practiceFront';
import ProviderStore from 'store/PracticeFront/provider';
import EditStatusStore from 'store/PracticeFront/editStatus';
import ShowEditingTipStore from 'store/PracticeFront/showEditingTip';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import PracticeFrontPreviewStore from 'store/PracticeFrontPreview';
import {
    useNavigate,
} from 'react-router-dom';
import { PATH } from 'constants/path';
import { EServiceType, ServerMeta } from 'types/common';
import { AllState } from 'constants/common';
import { TKlarityServiceServerItemForPF } from 'types/provider';
import { findTargetMetaFromListByKey } from 'utils/common';
import { Radio, Space } from 'antd';
interface IProps {
    isFree: boolean;
    setTabKey: (key: string) => void;
    scrollToTheEditingModule: () => void;
    refetch: () => void;
}

const KlarityServices = ({
    isFree,
    setTabKey,
    scrollToTheEditingModule,
    refetch,
}: IProps) => {
    const [, setPreviewStore] = PracticeFrontPreviewStore.useStore();
    const [getProvider] = ProviderStore.useStore();
    const [getEditStatus] = EditStatusStore.useStore();
    const [getShowEditingTip, setShowEditingTip] = ShowEditingTipStore.useStore();
    const showEditingTip = getShowEditingTip('data');
    const editStatusMap = getEditStatus('data') as Record<EditModuleEnum, boolean>;
    const providerData = getProvider('data');
    const [supportState, setSupportState] = React.useState<ServerMeta[]>([]);
    const [currentType, setCurrentType] = React.useState<"1" | "2">("1"); //1 new patient, 2 existing patient

    const [newSpecialtyServiceMap, setNewSpecialtyServiceMap] = React.useState<Record<string, TKlarityServiceServerItemForPF[]>>()
    const [existSpecialtyServiceMap, setExistSpecialtyServiceMap] = React.useState<Record<string, TKlarityServiceServerItemForPF[]>>()


    const [selectState, setSelectState] = React.useState<ServerMeta>();
    const gloablEditStatus = useMemo(() => !editStatusMap || !!Object.values(editStatusMap).find((e) => e), [editStatusMap]);
    const navigate = useNavigate();

    const { contact, seoCity, seoData, containSeoApp, email, pfKlarityServiceList } = providerData! as IProviderStore;
    const loaded = !!email;

    React.useEffect(() => {
        if (!loaded) {
            return;
        }

        const stateCode: string[] = [];
        if (pfKlarityServiceList) {
            pfKlarityServiceList.forEach((service) => {
                if (service.state && !stateCode.includes(service.state)) {
                    stateCode.push(service.state);
                }
            });
        }
        const _supportState = AllState.filter((state) => stateCode.includes(state.dictKey));
        setSupportState(_supportState);
        if (_supportState && _supportState.length > 0) {
            setSelectState(_supportState[0]);
        }
    }, [pfKlarityServiceList, loaded]);

    React.useEffect(() => {
        const isFromSupport = findTargetMetaFromListByKey(selectState?.content, supportState);
        if (isFromSupport) {
            const sevicesAfterFilter = pfKlarityServiceList?.filter((item) => {
                return item.state.toLowerCase() === selectState?.content?.toLowerCase() || item.state.toLowerCase() === selectState?.dictKey?.toLowerCase();
            });
            const _newSpecialtyServiceMap: Record<string, TKlarityServiceServerItemForPF[]> = {};
            const _existSpecialtyServiceMap: Record<string, TKlarityServiceServerItemForPF[]> = {};

            sevicesAfterFilter?.forEach((service) => {
                const isForNewPatient = service.serviceType === EServiceType.INIT || service.serviceType === EServiceType.CONSULTATION;
                if (isForNewPatient) {
                    if (service.serviceSpecialtyName) {
                        if (!_newSpecialtyServiceMap[service.serviceSpecialtyName]) {
                            _newSpecialtyServiceMap[service.serviceSpecialtyName] = [];
                        }
                        _newSpecialtyServiceMap[service.serviceSpecialtyName].push(service);
                    }
                } else {
                    if (service.serviceSpecialtyName) {
                        if (!_existSpecialtyServiceMap[service.serviceSpecialtyName]) {
                            _existSpecialtyServiceMap[service.serviceSpecialtyName] = [];
                        }
                        _existSpecialtyServiceMap[service.serviceSpecialtyName].push(service);
                    }
                }
            });
            setNewSpecialtyServiceMap(_newSpecialtyServiceMap);
            setExistSpecialtyServiceMap(_existSpecialtyServiceMap);
            const hasNew = Object.keys(_newSpecialtyServiceMap).length > 0;
            const hasExist = Object.keys(_existSpecialtyServiceMap).length > 0;
            if (!hasNew && hasExist) {
                setCurrentType("2");
            } else {
                setCurrentType("1");
            }
        } else {
            setCurrentType("1");
            setNewSpecialtyServiceMap(undefined)
            setExistSpecialtyServiceMap(undefined)
        }
    }, [pfKlarityServiceList, selectState, supportState])

    React.useEffect(() => {
        setPreviewStore("serviceStateKey", selectState?.dictKey || '');
    }, [selectState])

    React.useEffect(() => {
        setPreviewStore("serviceStateTypeKey", currentType);
    }, [currentType])

    const hasSupportState = supportState.length > 0;

    const handleShowUpgardePlan = useCallback(() => {
        openSubscriptionModal();
    }, []);

    const handleClickUniprofile = () => {
        navigate(`${PATH.CREATE_UNI_PROFILE}?type=uniprofile&scroll=klarity-service`)
    }

    const hasOnlyOneState = supportState && supportState.length === 0;
    const hasNewData = Object.keys(newSpecialtyServiceMap || {}).length > 0;
    const hasExistData = Object.keys(existSpecialtyServiceMap || {}).length > 0;
    const canSwith = hasNewData && hasExistData;

    return (
        <div className={s.wrap}>
            <div className={s.module}>
                <div className={s.title}>Services</div>
                <div className={s.des}>
                    The services displayed here are based on the services defined in
                    <span
                        className={s.high}
                        onClick={handleClickUniprofile}
                    >“Uniprofile”</span>.
                    To modify your services, please go to
                    <span
                        className={s.high}
                        onClick={handleClickUniprofile}
                    >“Uniprofile”</span> &gt; “Klarity Service and Price”.
                </div>
                {
                    hasSupportState && !hasOnlyOneState && (
                        <div className={s.stateWrap}>
                            <div>User the tab below to review services offered in different states.</div>
                            <div className={s.stateList}>
                                {
                                    supportState.map((state) => (
                                        <div
                                            key={state.dictKey}
                                            onClick={() => setSelectState(state)}
                                            className={`${s.stateItem} ${selectState?.dictKey === state.dictKey ? s.stateItemActive : ''}`}
                                        >{state.content}</div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                {
                    hasSupportState && (
                        <div className={s.typeWrap}>
                            <Radio.Group
                                disabled={!canSwith}
                                onChange={(e) => {
                                    setCurrentType(e.target.value);
                                }}
                                value={currentType}>
                                <Space direction="vertical">
                                    <Radio value={"1"}>Services for new patients</Radio>
                                    <Radio value={"2"}>Services for existing patients</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default KlarityServices;
