// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/starIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--RZzhy {
  padding: 24px 0;
}
.s-module__wrap--RZzhy .s-module__editItem--NKZOg {
  margin-bottom: 24px;
}
.s-module__paidTip--H5Olu {
  margin-bottom: 40px;
  display: flex;
  padding: 16px;
  border-radius: 6px;
  background: var(--blue-50);
}
.s-module__paidTip--H5Olu .s-module__starIcon--EoQRF {
  margin-top: 2px;
  margin-right: 12px;
  flex: 0 0 16px;
  height: 16px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: contain;
}
.s-module__paidTip--H5Olu .s-module__TipContent--ZrYys {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.s-module__paidTip--H5Olu .s-module__TipContent--ZrYys .s-module__tipTitle--p5gfF {
  margin-bottom: 20px;
  color: var(--gray-700);
  font-size: 20px;
}
.s-module__paidTip--H5Olu .s-module__TipContent--ZrYys .s-module__tipText--BWd5o {
  margin-bottom: 12px;
  font-size: 14px;
  color: var(--gray-900);
}
.s-module__paidTip--H5Olu .s-module__TipContent--ZrYys .s-module__upgradeAction--DH62z {
  cursor: pointer;
  color: var(--blue-700);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/CustomizePracticeFrontPage/components/Appointment/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAFA;EAIQ,mBAAA;AACR;AAGA;EACE,mBAAA;EACA,aAAA;EACA,aAAA;EACA,kBAAA;EACA,0BAAA;AADF;AAJA;EAQI,eAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,oEAAA;EACA,wBAAA;AADJ;AAZA;EAiBM,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFN;AAlBA;EAuBQ,mBAAA;EACA,sBAAA;EACA,eAAA;AAFR;AAvBA;EA6BM,mBAAA;EACA,eAAA;EACA,sBAAA;AAHN;AA5BA;EAkCM,eAAA;EACA,sBAAA;AAHN","sourcesContent":[".wrap {\n    padding: 24px 0;\n\n    .editItem {\n        margin-bottom: 24px;\n    }\n}\n\n.paidTip {\n  margin-bottom: 40px;\n  display: flex;\n  padding: 16px;\n  border-radius: 6px;\n  background: var(--blue-50);\n\n  .starIcon {\n    margin-top: 2px;\n    margin-right: 12px;\n    flex: 0 0 16px;\n    height: 16px;\n    background: url('assets/common/starIcon.svg') no-repeat center;\n    background-size: contain;\n  }\n\n  .TipContent {\n      font-family: Inter;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 20px;\n\n      .tipTitle {\n        margin-bottom: 20px;\n        color: var(--gray-700);\n        font-size: 20px;\n      }\n\n    .tipText {\n      margin-bottom: 12px;\n      font-size: 14px;\n      color: var(--gray-900);\n    }\n    .upgradeAction {\n      cursor: pointer;\n      color: var(--blue-700);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--RZzhy`,
	"editItem": `s-module__editItem--NKZOg`,
	"paidTip": `s-module__paidTip--H5Olu`,
	"starIcon": `s-module__starIcon--EoQRF`,
	"TipContent": `s-module__TipContent--ZrYys`,
	"tipTitle": `s-module__tipTitle--p5gfF`,
	"tipText": `s-module__tipText--BWd5o`,
	"upgradeAction": `s-module__upgradeAction--DH62z`
};
export default ___CSS_LOADER_EXPORT___;
