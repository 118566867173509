import dayjs from 'dayjs';
import React from 'react';
import s from './s.module.less';
import { Event, EventWithTimeOverlap } from 'types/calendar';
import { getEventColor, isEventTimeOverlap } from 'utils/calendar';
import { Tooltip } from 'antd';
import useCalendarColors from 'hooks/useCalendarColors';

type Props = {
    useAdminApi?: boolean
    day:dayjs.Dayjs
    inx:number
    events:Event[],
    onEventClick?: (event:Event)=>void
    log?:boolean
};

const WeekDay = (props:Props) => {
    const { day, events, onEventClick, useAdminApi } = props;
    const [colors] = useCalendarColors({ useAdminApi });

    const isCurrentDay = day.format('DD-MM-YY') === dayjs().format('DD-MM-YY');
    const eventsWithTimeOverlap:EventWithTimeOverlap[] = React.useMemo(() => {
        const ret:EventWithTimeOverlap[] = [];
        for (let i = 0; i < events.length; i++) {
            const currentEvent = events[i];
            const timeOverlapKeys:string[] = [];
            for (let j = 0; j < i; j++) {
                const event2 = events[j];
                if (isEventTimeOverlap(currentEvent, event2)) {
                    timeOverlapKeys.push(event2.id);
                }
            }
            ret.push({ ...events[i], timeOverlapKeys });
        }
        return ret;
    }, [events]);

    return (
        <div className={s.wrap}>
            <div className={s.itemWrap}>
                <div className={s.header}>
                    <div>{day.format('ddd').toUpperCase()}</div>
                    <div className={s.headerDate}><span className={isCurrentDay ? `${s.dayLabel} ${s.today}` : s.dayLabel}>{day.format('DD')}</span></div>
                </div>
                <div
                    className={s.content}
                >
                    <div
                        className={s.eventWrap}
                        onClick={() => {
                        }}
                    >
                        {eventsWithTimeOverlap.map((evt, idx) => {
                            const starTimetArray = dayjs(new Date(evt.start.dateTime)).format('HH-mm').split('-');
                            const endTimeArray = dayjs(new Date(evt.end.dateTime)).format('HH-mm').split('-');
                            const starDay = dayjs(new Date(evt.start.dateTime)).format('MM-DD');
                            const endDay = dayjs(new Date(evt.end.dateTime)).format('MM-DD');
                            const startHour = parseInt(starTimetArray[0], 10);
                            const startMin = parseInt(starTimetArray[1], 10);
                            const endHour = parseInt(endTimeArray[0], 10);
                            const endMin = parseInt(endTimeArray[1], 10);
                            const timeLabel = `${dayjs(new Date(evt.start.dateTime)).format('hh:mm')} - ${dayjs(new Date(evt.end.dateTime)).format('hh:mm A')}`;

                            let top = startHour * 50 + (startMin * 5) / 6;
                            const bottom = endHour * 50 + (endMin * 5) / 6;
                            if (startHour > endHour && endHour !== 0) {
                                top = 0;
                            }

                            const color = getEventColor(evt, colors);
                            const overlapCount = evt.timeOverlapKeys.length;
                            let height = bottom - top;
                            if (height === 0) {
                                //diff date
                                height = 50 * 24;
                                if (starDay === endDay) {
                                    //same date
                                    height = 20;
                                }
                            }
                            let narrowLayout = false;
                            if (height > 0 && height < 15) {
                                narrowLayout = true;
                            }
                            const toolContent = (
                                <>
                                    <div className={s.toolTextTitle}>{evt.summary}</div>
                                    <div className={s.tooltTextTime}>{timeLabel}</div>
                                </>
                            );
                            return (
                                <div
                                    key={idx}
                                    className={narrowLayout ? `${s.event} ${s.narrowEvent}` : s.event}
                                    style={{
                                        top,
                                        height: `${height}px`,
                                        background: color,
                                        right: 8,
                                        left: overlapCount * 20,
                                    }}
                                    onClick={() => {
                                        if (onEventClick) {
                                            onEventClick(evt);
                                        }
                                    }}
                                >
                                    <p className={s.title}>
                                        <Tooltip autoAdjustOverflow color="#fff" overlayClassName={s.toolText} placement="top" title={toolContent}>
                                            {evt.summary || 'No title'}
                                        </Tooltip>
                                    </p>
                                    <p className={s.time}>{timeLabel}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeekDay;
