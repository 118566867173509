import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import EmailAddress from 'components/PracticeFrontFromComponents/EmailAddress';
import ProviderStore from 'store/PracticeFront/provider';
import { EditModuleEnum, IProviderStore } from 'types/practiceFront';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import AppointmentLink from 'components/PracticeFrontFromComponents/AppointmentLink';
import EditStatusStore from 'store/PracticeFront/editStatus';
import ShowEditingTipStore from 'store/PracticeFront/showEditingTip';
import { updateProviderReadNews } from 'api/practiceFront';
import { showNewFlag } from 'utils/provider';

interface IProps {
    isKlarityUser?: boolean;
    emailNotify: string | null;
    isFree: boolean;
    appointmentNewFlag: boolean | null;
    onSubmit: (formValue, field: EditModuleEnum, callback?: () => void | Function) => void;
    setTabKey: (key: string) => void;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    scrollToTheEditingModule: () => void;
    // refetch: () => void;
}

const AppointmentPage = ({
    isKlarityUser,
    emailNotify,
    appointmentNewFlag,
    isFree,
    onEditStatusChange,
    setTabKey,
    onSubmit,
    scrollToTheEditingModule,
    // refetch,
}: IProps) => {
    const [getProvider] = ProviderStore.useStore();
    const [getEditStatus] = EditStatusStore.useStore();
    const providerData = getProvider('data');
    const [getShowEditingTip, setShowEditingTip] = ShowEditingTipStore.useStore();
    const showEditingTip = getShowEditingTip('data');
    const editStatusMap = getEditStatus('data') as Record<EditModuleEnum, boolean>;
    const gloablEditStatus = useMemo(() => !editStatusMap || !!Object.values(editStatusMap).find((e) => e), [editStatusMap]);

    const { appointmentIntegrationUrl, useIntegrationUrl } = providerData as IProviderStore;

    useEffect(() => {
        if (showNewFlag(appointmentNewFlag)) {
            updateProviderReadNews();
        }
    }, [appointmentNewFlag]);

    const handleShowUpgardePlan = useCallback(() => {
        openSubscriptionModal();
    }, []);

    const initFormValue = useMemo(() => {
        const formValue = {
            useIntegrationUrl: !!useIntegrationUrl,
            appointmentIntegrationUrl,
        };

        // klarity plan的用户，可能会出现有appointmentIntegrationUrl，但是useIntegrationUrl 为null的情况
        // 这就需要判断如果有appointmentIntegrationUrl，也得让checkbox 选中
        if (isKlarityUser) {
            formValue.useIntegrationUrl = !!useIntegrationUrl || !!appointmentIntegrationUrl;
        }

        return formValue;
    }, [appointmentIntegrationUrl, isKlarityUser, useIntegrationUrl]);

    return (
        <div className={s.wrap}>
            {
                isFree &&
                <div className={s.paidTip}>
                    <div className={s.starIcon} />
                    <div className={s.TipContent}>
                        <div className={s.tipTitle}>Appointment module not enabled</div>
                        <div className={s.tipText}><strong>Enabling the appointment module</strong> on Practice Front allows  visitors to request or book appointments directly from your website. Our study shows that more patients want online bookings now than ever.</div>
                        <a className={s.upgradeAction} onClick={handleShowUpgardePlan}>Upgrade plan</a>
                    </div>
                </div>
            }
            <div className={s.editItem}>
                <AppointmentLink
                    isKlarityUser={isKlarityUser}
                    emailNotify={emailNotify}
                    appointmentNewFlag={appointmentNewFlag}
                    scrollToTheEditingModule={scrollToTheEditingModule}
                    isEditing={editStatusMap[EditModuleEnum.APPOINTMENT_LINK]}
                    onEditStatusChange={onEditStatusChange}
                    setShowEditingTip={setShowEditingTip}
                    showEditingTip={showEditingTip}
                    gloablEditStatus={gloablEditStatus}
                    initFormValue={initFormValue}
                    onSubmit={onSubmit}
                    setTabKey={setTabKey}
                    isFree={isFree}
                />
            </div>
            {/* <div className={s.editItem}>
                <EmailAddress isFree={isFree} initFormValue={email} />
            </div> */}
        </div>
    );
};

export default AppointmentPage;
