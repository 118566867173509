import React from 'react';
import { Button } from 'antd';
import s from './s.module.less';
import { IproviderBillingList } from 'types/appointmentSetting';
import useChannel from 'hooks/useChannel';

type BillingDisplayProps = {
    data?: IproviderBillingList,
    onEdit?: () => void
};

export default function BillingDisplay(props: BillingDisplayProps) {
    const [channels] = useChannel();
    const { data, onEdit } = props;
    const { bankName, bankRoutingNumber, accountHolder, accountNumber, address, zip, state, channel } = (data || {})!;

    const targetChannel = channels.find((item) => { return channel && `${item.id}` === channel; });
    return (
        <>
            <div className={s.row}>
                <div className={s.displayItem}>
                    <div className={`${s.label} ${s.required}`}>Bank name</div>
                    <div className={s.value}>{ bankName }</div>
                </div>
            </div>
            <div className={s.row}>
                <div className={s.displayItem}>
                    <div className={`${s.label} ${s.required}`}>Bank routing number</div>
                    <div className={s.value}>{ bankRoutingNumber }</div>
                </div>
            </div>
            <div className={s.row}>
                <div className={s.displayItem}>
                    <div className={`${s.label} ${s.required}`}>Account holder</div>
                    <div className={s.value}>{ accountHolder }</div>
                </div>
            </div>
            <div className={s.row}>
                <div className={s.displayItem}>
                    <div className={`${s.label} ${s.required}`}>Account number</div>
                    <div className={s.value}>{ accountNumber }</div>
                </div>
            </div>
            <div className={s.row}>
                <div className={s.displayItem}>
                    <div className={`${s.label} ${s.required}`}>Address</div>
                    <div className={s.value}>{ address }</div>
                </div>
                <div className={s.displayItem}>
                    <div className={`${s.label} ${s.required}`}>State</div>
                    <div className={s.value}>{ state }</div>
                </div>
                <div className={s.displayItem}>
                    <div className={`${s.label} ${s.required}`}>ZIP code</div>
                    <div className={s.value}>{ zip }</div>
                </div>
            </div>
            <div className={s.row}>
                <div className={s.displayItem}>
                    <div className={`${s.label} ${s.required}`}>Linked channels</div>
                    <div className={s.value}>{ targetChannel?.name }</div>
                </div>
            </div>
            <Button type="primary" style={{ marginTop: '30px' }} onClick={onEdit}>Edit</Button>
        </>
    );
}
