import React, { useState } from 'react';
import { Divider, Form, message } from 'antd';
import { getUserId } from 'utils/localstore';

import { BillingFormFieldEnum, BillingType } from './types';
import { IproviderBillingCreate, IproviderBillingList } from 'types/appointmentSetting';
import { providerBillingCreate, providerBillingDelete } from 'api/appointmentSetting';

import BillingItem from '../components/Item';
import BillingForm from '../components/Form';
import s from './s.module.less';

type Props = {
    billingData?: IproviderBillingList,
    index?: number,
    onChange?: () => void
};

export default function BillingWrap({ billingData, index, onChange }: Props) {
    const [isShow, setIsShow] = useState(false);
    const [isEditForm, setIsEditForm] = useState(false);
    const [form] = Form.useForm<BillingType>();

    const addBiilingAccount = () => {
        setIsShow(!isShow);
    };

    const onCancel = () => {
        setIsShow(!isShow);
        setIsEditForm(false);
    };

    const onDelete = async () => {
        const id = billingData?.id;
        try {
            const { data } = await providerBillingDelete(id);
            if (data.code === 200) {
                message.success(data.message);
                if (onChange) {
                    onChange?.();
                }
                setIsEditForm(false);
                setIsShow(false);
                form.setFieldsValue({
                    [BillingFormFieldEnum.BANK_NAME]: '',
                    [BillingFormFieldEnum.BANK_ROUTING_NUMBER]: '',
                    [BillingFormFieldEnum.ACCOUNT_HOLDER]: '',
                    [BillingFormFieldEnum.ACCOUNT_NUMBER]: '',
                    [BillingFormFieldEnum.BILLING_ADDRESS]: '',
                    [BillingFormFieldEnum.ZIP_CODE]: '',
                    [BillingFormFieldEnum.STATE]: '',
                    [BillingFormFieldEnum.CHANNEL]: [],
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onEdit = () => {
        setIsEditForm(true);
        const { accountHolder, accountNumber, address, bankName, bankRoutingNumber, channel, state, zip } = billingData!;
        form.setFieldsValue({
            [BillingFormFieldEnum.BANK_NAME]: bankName,
            [BillingFormFieldEnum.BANK_ROUTING_NUMBER]: bankRoutingNumber,
            [BillingFormFieldEnum.ACCOUNT_HOLDER]: accountHolder,
            [BillingFormFieldEnum.ACCOUNT_NUMBER]: accountNumber,
            [BillingFormFieldEnum.BILLING_ADDRESS]: address,
            [BillingFormFieldEnum.ZIP_CODE]: zip,
            [BillingFormFieldEnum.STATE]: state,
            [BillingFormFieldEnum.CHANNEL]: channel?.split(',')?.map((item) => {
                if (typeof item === 'string') {
                    return parseInt(item, 10);
                }
                return item;
            }),
        });
    };

    const onSave = async () => {
        try {
            const formValues = await form.validateFields();
            const params: IproviderBillingCreate = {
                accountHolder: formValues[BillingFormFieldEnum.ACCOUNT_HOLDER],
                accountNumber: formValues[BillingFormFieldEnum.ACCOUNT_NUMBER],
                address: formValues[BillingFormFieldEnum.BILLING_ADDRESS],
                bankName: formValues[BillingFormFieldEnum.BANK_NAME],
                bankRoutingNumber: formValues[BillingFormFieldEnum.BANK_ROUTING_NUMBER],
                channel: formValues[BillingFormFieldEnum.CHANNEL].join(','),
                bankOrder: index + 1,
                providerId: getUserId(),
                state: formValues[BillingFormFieldEnum.STATE],
                zip: formValues[BillingFormFieldEnum.ZIP_CODE],
            };
            const { data } = await providerBillingCreate(params);
            if (data.code === 200) {
                if (onChange) {
                    onChange?.();
                }
                setIsEditForm(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const renderHeaderTitle = () => {
        let title = '';
        switch (index) {
            case 0:
                title = 'first';
                break;
            case 1:
                title = 'second';
                break;
            case 2:
                title = 'third';
                break;
            default:
                break;
        }
        return title;
    };

    const renderHeader = () => {
        return (
            <div className={s.wrap}>
                {
                    billingData?.bankOrder !== 0 ? (
                        <>
                            <div className="flex items-center cursor-pointer">
                                <div className={`${s.title} text-gray-800 text-xl font-medium`}>Billing account information</div>
                            </div>
                            <Divider />
                        </>
                    ) : (
                        <div className="flex items-center cursor-pointer" onClick={addBiilingAccount}>
                            <span className={s.addIcon}>{isShow ? '-' : '+'}</span>
                            <span className="ml-4 text-gray-800 text-xl font-medium"> {`Add a ${renderHeaderTitle()} billing account`}</span>
                        </div>
                    )
                }
            </div>
        );
    };

    const renderItem = () => {
        return (
            <>
                {
                    isEditForm ? <BillingForm onCancel={onCancel} onDelete={onDelete} onSave={onSave} form={form} />
                        : <BillingItem data={billingData} onEdit={onEdit} />
                }
            </>
        );
    };

    return (
        <div className={s.wrap}>
            {
                renderHeader()
            }
            {
                billingData?.bankOrder !== 0 ? renderItem() : (isShow && <BillingForm onCancel={onCancel} onDelete={onDelete} onSave={onSave} form={form} />)
            }
        </div>
    );
}
