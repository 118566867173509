import React from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import WeekDay from '../WeekDay';
import { getDateKey } from 'utils/calendar';
import { Event } from 'types/calendar';

type Props = {
    weekDaysArray:dayjs.Dayjs[]
    dateEventMap:Record<string, Event[]>
    onEventClick?: (event:Event)=>void,
    onAddAppointment?: (start:dayjs.Dayjs, end: dayjs.Dayjs)=>void
};

const Week = (props:Props) => {
    const { weekDaysArray, dateEventMap, onEventClick } = props;
    const timeArray:string[] = ['0 PM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
        '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM', '0',
    ];
    return (
        <div className={s.wrap}>
            <div className={s.weekBg}>
                {timeArray.map((item) => {
                    return (
                        <div className={s.rowLine} key={item}>
                            <span className={s.label}>{item}</span>
                            <hr className={s.line} />
                        </div>
                    );
                })}
            </div>
            <div className={s.contentWrap}>
                {weekDaysArray.map((day, i) => {
                    const key = getDateKey(day);
                    return (
                        <div
                            onClick={() => {
                            }}
                            className={s.weekDay}
                            key={i}
                        >
                            <div
                                onClick={(e) => {
                                    const offsetTop = e.nativeEvent.offsetY - 106;
                                    const targetHour = Math.floor(offsetTop / 50);
                                    const min = (offsetTop % 50) > 25 ? 30 : 0;
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    const startTime = day.hour(targetHour).minute(min);
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    const endTime = day.hour(targetHour).minute(min).add(1, 'hour');
                                }}
                                className={s.timeCatch}
                            />
                            <WeekDay
                                onEventClick={onEventClick}
                                day={day}
                                key={i}
                                inx={i}
                                events={dateEventMap[key] || []}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Week;
