import dayjs from 'dayjs';

export enum FormFieldEnum {
    SUMMARY = 'summary',
    DESCRIPTION = 'description',
    TIME = 'time',
}

export interface FormInterface {
    [FormFieldEnum.SUMMARY]: string;
    [FormFieldEnum.DESCRIPTION]: string;
    [FormFieldEnum.TIME]: dayjs.Dayjs[];
}
