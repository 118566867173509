import dayjs from 'dayjs';
import { RecurringType } from 'types/calendar';

export enum FormFieldEnum {
    EVENT_NAME = 'eventName',
    TIME = 'time',
    REPEAT = 'repeat',
    GUEST_EMAIL = 'guestEmail',
    MEETING_LINK = 'meetingLink',
    LOCATION = 'location',
}

export interface FormInterface {
    [FormFieldEnum.EVENT_NAME]: string;
    [FormFieldEnum.TIME]: dayjs.Dayjs[];
    [FormFieldEnum.REPEAT]: RecurringType;
    [FormFieldEnum.GUEST_EMAIL]: string;
    [FormFieldEnum.MEETING_LINK]: string;
    [FormFieldEnum.LOCATION]: string;
}
