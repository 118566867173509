import React, { useCallback, useState } from 'react';
import s from './s.module.less';
import { Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface IProps {
    loading: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const RemovalConfirm = ({
    loading,
    onCancel,
    onConfirm,
}: IProps) => {
    const [checked, setChecked] = useState(false);
    const [showErrorTip, setShowErrorTip] = useState(false);
    const onChange = useCallback((e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    }, []);

    const handleCancel = useCallback(() => {
        setChecked(false);
        onCancel();
    }, [onCancel]);

    const handleConfirm = useCallback(() => {
        setShowErrorTip(!checked);
        if (checked) {
            onConfirm();
        }
    }, [checked, onConfirm]);

    return (
        <div className={s.wrap}>
            <div className={s.title}>Confirm channel removal</div>
            <div className={s.content}>
                <div className={s.pstart}>Removing this channel will deactivate your profile. There are a few things to keep in mind:</div>
                <ul>
                    <li><strong>After deactivation, your existing patients might not be able to find you on this channel.</strong></li>
                    <li>
                        <p><strong>Depending on the channel, some listings could be permanently closed. </strong></p>
                        <p>There's also a chance you won't be able to reactivate with the same practice name due to individual channel policies.</p>
                    </li>
                    <li>
                        <p><strong>After requesting removal, you still can access and use this channel until the end of your current payment period. </strong></p>
                        <p>However, note that the exact deactivation date might be 1 or 2 days before or after the payment period's end date.</p>
                    </li>
                </ul>
                <Checkbox className={s.confirmCheckbox} checked={checked} onChange={onChange}>I understand that I will lose access to certain channels and data by leaving this plan.</Checkbox>
                {
                    showErrorTip &&
                    <div className={s.errorTip}>
                        <div className={s.errorIcon} />
                        <div>Please acknowledge the changes by reading each section and ticking the checkbox before proceeding.</div>
                    </div>
                }
            </div>
            <div className={s.footer}>
                <Button loading={loading} disabled={!checked} onClick={handleConfirm}>Continue to remove</Button>
                <Button type="primary" onClick={handleCancel}>Keep the channel</Button>
            </div>
        </div>
    );
};

export default RemovalConfirm;
