/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Tabs, message } from 'antd';
import s from './s.module.less';
import HomePage from '../HomePage';
import Contact from '../Contact';
import AppointmentPage from '../Appointment';
import PracticeFrontPreviewStore from 'store/PracticeFrontPreview';
import { EditModuleEnum, IProviderStore } from 'types/practiceFront';
import EditStatusStore from 'store/PracticeFront/editStatus';
import { addEducation, addExperience, addPractice, updateAboutMe, updateBasicInfo, updateEducation, updateExperience, updateInsuranceInfo, updateMyPhilosophy, updatePractice, updateSpecialtiesAndApproch, updateSEOcity, updateAppointmentLink } from 'api/practiceFront';
import { providerServiceCreate, providerServiceUpdate } from 'api/appointmentSetting';
import ProviderStore from 'store/PracticeFront/provider';
import ShowEditingTipStore from 'store/PracticeFront/showEditingTip';
import { EPracticeFrontPreviewPage } from 'types/channel';
import { EmptyFields } from 'constants/common';
import useRefreshPreview from 'hooks/useRefreshPreview';
import { getUserId } from 'utils/localstore';
import ModuleTabIndex from 'constants/practiceFront';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import { showNewFlag } from 'utils/provider';
import KlarityServices from '../KlarityServices';

interface IProps {
    isKlarityUser?: boolean;
    isFree: boolean;
    refetch: () => void;
    setLoading: (loading: boolean) => void;
    innerId?: string
}

const Page = forwardRef(({
    isKlarityUser,
    isFree,
    refetch,
    setLoading,
    innerId,
}: IProps, ref) => {
    const [, setPreviewStore] = PracticeFrontPreviewStore.useStore();
    const [getProvider] = ProviderStore.useStore();
    const [tabKey, setTabKey] = useState<string>('1');
    // const [getUser] = ProviderStore.useStore();
    const providerData = getProvider('data') || {};

    const [getEditStatus, setEditStatus] = EditStatusStore.useStore();
    const [getShowEditingTip, setShowEditingTip] = ShowEditingTipStore.useStore();
    const showEditingTip = getShowEditingTip('data');
    const editStatusMap = getEditStatus('data');
    const gloablEditStatus = useMemo(() => !editStatusMap || !!Object.values(editStatusMap).find((e) => e), [editStatusMap]);
    const [setPreview] = useRefreshPreview();
    const [emptyFields, setEmptyFields] = useState<Array<string>>([]);

    const { myPhilosophy, specialtiesApproaches, experience, education, contact, appointmentNewFlag, appointmentIntegrationUrl, useIntegrationUrl, emailNotify, pfKlarityServiceList } = providerData as IProviderStore;

    const hasService = isKlarityUser && pfKlarityServiceList && pfKlarityServiceList?.length > 0;

    useEffect(() => {
        const res = [];
        (!myPhilosophy?.treatmentPhilosophy?.replace(/<\/?.+?>/g, '').replace(/&nbsp;/g, '').replace(/ /g, '') || !myPhilosophy.treatmentApproach) && res.push(EmptyFields.MyPhilosophy);
        (!experience || experience.length === 0) && res.push(EmptyFields.WorkExperience);
        (!education || education.length === 0) && res.push(EmptyFields.Education);
        (!(specialtiesApproaches?.specialtyList?.length > 0) || !(specialtiesApproaches?.conditionTreatedList?.length > 0)) && res.push(EmptyFields.SpecialtiesApproaches);

        setEmptyFields([...res]);
    }, [education, experience, myPhilosophy, specialtiesApproaches]);

    const handleWindowScroll = useCallback((top: number = 0) => {
        window.scrollTo({
            left: 0,
            top,
            behavior: 'smooth',
        });
    }, []);

    const handleSetShowEditingTip = useCallback((status: boolean) => {
        setShowEditingTip('data', status);
    }, []);

    const heightToTop = useCallback((ele) => {
        if (ele) {
            const root = document.body;
            let height = 0;
            do {
                height += ele.offsetTop;
                ele = ele.offsetParent;
            } while (ele && ele !== root);
            return height;
        }
    }, []);

    const scrollToTheEditingModule = useCallback(() => {
        if (editStatusMap) {
            const editingModule = Object.keys(editStatusMap).find((k: string) => {
                const v: boolean = editStatusMap[k as EditModuleEnum];

                return v;
            });

            if (editingModule) {
                const el = document.getElementById(editingModule);
                const scrollTop = heightToTop(el) || 0;

                handleWindowScroll(scrollTop - 50);
            }
        }
    }, [editStatusMap, heightToTop, handleWindowScroll]);

    const handleEditStatusChange = useCallback((status: boolean, filed: EditModuleEnum) => {
        if (gloablEditStatus && status) {
            if (editStatusMap) {
                const editingModulaEntry = Object.entries(editStatusMap).find(([, v]) => !!v);

                if (editingModulaEntry) {
                    const [moduleName] = editingModulaEntry;

                    const tabIndex = ModuleTabIndex[moduleName];

                    setTabKey(tabIndex.toString());
                    handleSetShowEditingTip(true);
                    scrollToTheEditingModule();
                    message.error('Please save your changes before proceeding');
                }
            }
        } else if (editStatusMap) {
            handleSetShowEditingTip(false);
            editStatusMap[filed] = status;
            setEditStatus('data', {
                ...editStatusMap,
            });
        }
    }, [gloablEditStatus, editStatusMap, handleSetShowEditingTip, scrollToTheEditingModule, setEditStatus]);

    useImperativeHandle(ref, () => ({
        beforNext: (status: boolean, bookingLinkCheckedWithoutLinkValue: boolean) => {
            if (status) {
                handleSetShowEditingTip(true);
                if (editStatusMap) {
                    const editingModulaEntry = Object.entries(editStatusMap).find(([, v]) => !!v);

                    if (editingModulaEntry) {
                        const [moduleName] = editingModulaEntry;

                        const tabIndex = ModuleTabIndex[moduleName];

                        setTabKey(tabIndex.toString());
                        handleSetShowEditingTip(true);
                        scrollToTheEditingModule();
                        message.error('Please save your changes before proceeding');
                    }
                }
                setTimeout(() => {
                    scrollToTheEditingModule();
                }, 100);
            } else if (bookingLinkCheckedWithoutLinkValue) {
                setTabKey('3');
                setTimeout(() => {
                    const bookingLinkAnchorEl = document.getElementById('bookingLinkCheckbox');

                    if (bookingLinkAnchorEl) {
                        // bookingLinkAnchorEl.scrollIntoView();
                        const { offsetTop } = bookingLinkAnchorEl;

                        handleWindowScroll(offsetTop + 280);
                    }
                }, 200);
            } else {
                handleSetShowEditingTip(false);
            }
        },
    }));

    const handleSubmit = useCallback(async (formValue, filed: EditModuleEnum, callback?: () => void | Function) => {
        setLoading(true);
        let res = {
            error: '',
        };
        if (filed === EditModuleEnum.BASICINFO) {
            res = await updateBasicInfo(formValue);
        } else if (filed === EditModuleEnum.INSURANCE) {
            res = await updateInsuranceInfo(formValue);
        } else if (filed === EditModuleEnum.ABOUTME) {
            res = await updateAboutMe(formValue);
        } else if (filed === EditModuleEnum.MYPHILOSOPHY) {
            res = await updateMyPhilosophy(formValue);
        } else if (filed === EditModuleEnum.SEPCIALITIESAPPROACH) {
            res = await updateSpecialtiesAndApproch(formValue);
        } else if (filed === EditModuleEnum.WORKEXPERIENCE) {
            const { id } = formValue;

            if (id) {
                res = await updateExperience(formValue);
            } else {
                res = await addExperience(formValue);
            }
        } else if (filed === EditModuleEnum.EDUCATION) {
            const { id } = formValue;

            if (id) {
                res = await updateEducation(formValue);
            } else {
                res = await addEducation(formValue);
            }
        } else if (filed === EditModuleEnum.SERVICE) {
            const { id } = formValue;

            if (id) {
                res = await providerServiceUpdate(formValue, id);
            } else {
                res = await providerServiceCreate({ ...formValue, providerId: getUserId() });
            }
        } else if (filed === EditModuleEnum.CONTACT) {
            if (!contact.id) {
                res = await addPractice(formValue);
            } else {
                res = await updatePractice(formValue);
            }
        } else if (filed === EditModuleEnum.LOCAL_SEO) {
            res = await updateSEOcity(formValue);
        } else if (filed === EditModuleEnum.APPOINTMENT_LINK) {
            res = await updateAppointmentLink(formValue);
        }

        if (!res.error) {
            if (filed === EditModuleEnum.LOCAL_SEO) {
                setPreviewStore('showLocalSEO', false);
            }
            handleEditStatusChange(false, filed);
            setPreview('flag', {});
            const callbackResp = callback?.();
            await refetch();
            if (typeof callbackResp === 'function') {
                callbackResp();
            }
        } else {
            message.error(res.error);
        }
        setLoading(false);
    }, [setLoading, contact, handleEditStatusChange, refetch, setPreview]);

    const handleTabClick = useCallback((key: string) => {
        setTabKey(key);
        handleWindowScroll(0);
    }, [handleWindowScroll]);

    const handleShowUpgardePlan = useCallback(() => {
        openSubscriptionModal();
    }, []);

    const setUlToIframe = useCallback(async () => {
        if (useIntegrationUrl) {
            if (appointmentIntegrationUrl) {
                let appointmentLink = appointmentIntegrationUrl;
                if (appointmentLink && !appointmentLink.startsWith('http://') && !appointmentLink.startsWith('https://')) {
                    appointmentLink = `http://${appointmentLink}`;
                }

                setPreview('currentPage', appointmentLink as any);
            } else {
                setPreview('currentPage', EPracticeFrontPreviewPage.DIRECT_BOOKING);
            }
        } else {
            setPreview('currentPage', EPracticeFrontPreviewPage.BOOKING);
        }
    }, [appointmentIntegrationUrl, useIntegrationUrl]);

    return (
        <div className={s.wrap} id={innerId}>
            <div className={s.header}>
                <div
                    className={s.title}
                >
                    Customize and launch your Practice Front
                </div>
                <div className={s.des}>Practice Front is the patient-facing website for your practice. It has prebuilt themes to ensure your website looks professional and is optimized for SEO. Your UniProfile information has been pre-populated on your website. Review the information and add more details to ensure your website is optimized for your target audience and effectively communicates your message.</div>
            </div>
            {
                tabKey === '1' && isFree && (
                    <div className={s.paidTip}>
                        <div className={s.starIcon} />
                        <div className={s.TipContent}>
                            <div className={s.tipText}>Upgrade to paid plan to unlock premium features <strong>including Contact Form, Appointment, and enhanced Local Search Engine Optimization.</strong></div>
                            <a className={s.upgradeAction} onClick={handleShowUpgardePlan}>Upgrade plan</a>
                        </div>
                    </div>
                )
            }
            <Tabs
                tabBarStyle={{ position: 'sticky', top: 0, backgroundColor: '#f9fafc', zIndex: 2 }}
                onTabClick={handleTabClick}
                onChange={(key) => {
                    setPreview('showLocalSEO', false);
                    setPreview('previewContactForm', false);
                    setPreview('serviceStateKey', '');
                    if (key === '1') {
                        setPreview('currentPage', EPracticeFrontPreviewPage.HOME);
                    }
                    if (key === '2') {
                        setPreview('currentPage', EPracticeFrontPreviewPage.CONTACT);
                    }
                    if (key === '3') {
                        setUlToIframe();
                    }
                    if (key === '4') {
                        setPreview('currentPage', EPracticeFrontPreviewPage.SERVICES);
                    }
                }}
                defaultActiveKey="1"
                activeKey={tabKey}
                destroyInactiveTabPane
                items={[
                    {
                        key: '1',
                        label: 'Homepage',
                        children: <HomePage emptyFields={emptyFields} setTabKey={setTabKey} setShowEditingTip={handleSetShowEditingTip} scrollToTheEditingModule={scrollToTheEditingModule} showEditingTip={showEditingTip} providerData={providerData} onEditStatusChange={handleEditStatusChange} onSubmit={handleSubmit} refetch={refetch} />,
                    },
                    {
                        key: '2',
                        label: 'Contact',
                        children: <Contact isFree={isFree} scrollToTheEditingModule={scrollToTheEditingModule} setTabKey={setTabKey} onSubmit={handleSubmit} onEditStatusChange={handleEditStatusChange} refetch={refetch} />,
                    },
                    hasService && {
                        key: '4',
                        label: 'Services',
                        children: <KlarityServices isFree={isFree} scrollToTheEditingModule={scrollToTheEditingModule} setTabKey={setTabKey} refetch={refetch} />,
                    },
                    {
                        key: '3',
                        label: <span className={`${showNewFlag(appointmentNewFlag) && s.newMark}`}>Appointment</span>,
                        children: <AppointmentPage
                            isKlarityUser={isKlarityUser}
                            appointmentNewFlag={appointmentNewFlag}
                            emailNotify={emailNotify}
                            isFree={isFree}
                            scrollToTheEditingModule={scrollToTheEditingModule}
                            setTabKey={setTabKey}
                            onSubmit={handleSubmit}
                            onEditStatusChange={handleEditStatusChange}
                        />,
                    },
                ].filter(Boolean) as any}
                centered
            />
        </div>
    );
});

export default Page;
