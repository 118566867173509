import React, { useCallback, useContext } from 'react';
import s from './s.module.less';
import SuccessImage from 'assets/channel/channelMangementDelegationSuccess.svg';
import { Button } from 'antd';
import ChannelContext from 'pages/dashboard/ChannelMainPage/context';

interface IProps {
    isNonSelected?: boolean;
    closeFullScreenPopLayout?: () => void;
}

const Success = ({ closeFullScreenPopLayout, isNonSelected }: IProps) => {
    const { hideChannelManagementDelegationNotice } = useContext(ChannelContext);
    const handleClick = useCallback(() => {
        closeFullScreenPopLayout?.();
        hideChannelManagementDelegationNotice?.();
    }, [hideChannelManagementDelegationNotice, closeFullScreenPopLayout]);
    return (
        <div className={s.wrap}>
            <img src={SuccessImage} alt="success" />
            {
                isNonSelected ? (
                    <>
                        <div className={s.title}>Thank you for your interest</div>
                        <p>{'Thanks for your interest in Kiwi\'s Channel management delegation.  We\'re currently working on expanding our support to include more channels.'}</p>
                    </>
                ) : (
                    <>
                        <div className={s.title}>{'You\'re all set!'}</div>
                        <p>{'Thank you for providing your channel credentials! We\'re working on linking your accounts, and you can expect to see increased visibility and patient engagement soon.'}</p>
                    </>
                )
            }
            <p>{'Feel free to explore Kiwi\'s other features and consider adding more channels to further expand your reach. You can find more channels on the Channels page.'}</p>
            <Button type="primary" onClick={handleClick}>Back to Channels</Button>
        </div>
    );
};

export default Success;
