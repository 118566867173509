import React from 'react';
import TimezoneOperationhours from './TimezoneOperationhours';
import DatesOverride from './DatesOverride';

const AviabilityPage = () => {
    return (
        <>
            <TimezoneOperationhours />
            <DatesOverride />
        </>
    );
};

export default AviabilityPage;
