// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--yAjs2 {
  padding: 12px 32px 42px;
}
.s-module__wrap--yAjs2 .s-module__title--npbBG {
  margin-bottom: 8px;
  color: var(--primary-black);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  text-align: center;
}
.s-module__wrap--yAjs2 .s-module__pic--lj0fV {
  margin: auto;
  max-width: 33%;
}
.s-module__wrap--yAjs2 p {
  color: var(--gray-700);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.s-module__wrap--yAjs2 .s-module__footer--j5yOy {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ChannelMainPage/components/KlarityMyChannel/components/RemoveSuccessfully/s.module.less"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;AAFA;EAIQ,kBAAA;EACA,2BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACR;AAZA;EAeQ,YAAA;EACA,cAAA;AAAR;AAhBA;EAoBQ,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADR;AAzBA;EA8BQ,gBAAA;EACA,aAAA;EACA,uBAAA;AAFR","sourcesContent":[".wrap {\n    padding: 12px 32px 42px;\n\n    .title {\n        margin-bottom: 8px;\n        color: var(--primary-black);\n        font-family: Inter;\n        font-size: 24px;\n        font-style: normal;\n        font-weight: 800;\n        line-height: 48px;\n        text-align: center;\n    }\n\n    .pic {\n        margin: auto;\n        max-width: 33%;\n    }\n\n    p {\n        color: var(--gray-700);\n        text-align: center;\n        font-family: Inter;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 24px;\n    }\n\n    .footer {\n        margin-top: 48px;\n        display: flex;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--yAjs2`,
	"title": `s-module__title--npbBG`,
	"pic": `s-module__pic--lj0fV`,
	"footer": `s-module__footer--j5yOy`
};
export default ___CSS_LOADER_EXPORT___;
