import React from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import MonthDay from '../MonthDay';
import { getDateKey } from 'utils/calendar';
import { Event } from 'types/calendar';

type Props = {
    monthDaysMatrix:dayjs.Dayjs[][]
    dateEventMap:Record<string, Event[]>
    onEventClick?: (event:Event)=>void
};

const Month = (props:Props) => {
    const { monthDaysMatrix, dateEventMap, onEventClick } = props;
    return (
        <div className={s.wrap}>
            {monthDaysMatrix.map((row, i) => {
                return (
                    <div className={s.rowWrap} key={i}>
                        {row.map((day, idx) => {
                            const key = getDateKey(day);
                            return (
                                <MonthDay onEventClick={onEventClick} events={dateEventMap[key] || []} day={day} key={idx} rowInx={i} />
                            ); })}
                    </div>
                );
            })}
        </div>
    );
};

export default Month;
