import React from 'react';
import { Modal, Form, message, Input, Button, Tag } from 'antd';
import commonS from 'styles/common.module.less';
import { Event, ServerEvent } from 'types/calendar';
import { FormFieldEnum, FormInterface } from './types';
import TimeRangerPicker from 'components/form/TimeRangerPicker';
import RepeatsSelect from 'components/form/RepeatsSelect';
import { getUserId } from 'utils/localstore';
import { getTimeStrForGoogleCalandar, getRecurrence, getRecurrenceTypeFromStr } from 'utils/calendar';
import { calendarCreateEvent, CreateEventType, calendarUpdateEvent, UpdateEventType, calendarDeleteEvent, DeleteEventType } from 'api/google';
import dayjs from 'dayjs';
import s from './s.module.less';
import { ColorKey } from 'constants/calendar';
import useTimezone from 'hooks/useTimezone';
import { EventData } from 'data/calendar';
import { SUPPORT_TIME_ZONE } from 'constants/common';
import { getBrowserTimezoneOffset } from 'utils/common';
import type { NotificationInstance } from 'antd/es/notification/interface';

type Props = {
    onCancel:()=>void
    event?:Event //for edit fun
    onSuccess?: ()=>void
    api?:NotificationInstance
};

const AddBlocktimeModal = (props:Props) => {
    const { onCancel, event, onSuccess, api } = props;
    const [form] = Form.useForm<FormInterface>();
    const [submiting, setSubmiting] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [,forceUpdate] = React.useState({});
    const [, myTimezone] = useTimezone();
    const [currentDay, setCurrentDay] = React.useState<dayjs.Dayjs | undefined>(undefined);
    const isEdit = !!event;
    //const isRecurringEvent = event?.id?.includes('_20');
    const isRecurringEvent = event?.recurrence && isEdit;
    let showTimezone = false;
    if (myTimezone?.value) {
        const target = SUPPORT_TIME_ZONE.find((item) => item.dictKey.toLowerCase() === (myTimezone?.value as string).toLowerCase());
        if (target && target.offset !== getBrowserTimezoneOffset()) {
            showTimezone = true;
        }
    }

    const showSuccessNotification = (evt: CreateEventType | UpdateEventType) => {
        const timeLabel = `${dayjs(new Date(evt.start)).format('hh:mm')} - ${dayjs(new Date(evt.end)).format('hh:mm A')}`;
        const title = isEdit ? 'Update block time success' : 'Add block time success';
        onSuccess?.();
        if (!api) {
            message.success(title);
            return;
        }
        api?.success({
            message: title,
            description: (
                <div>
                    <div className={s.notiTitle}>{evt.summary}</div>
                    <div className={s.notiTime}>{timeLabel}</div>
                </div>),
            placement: 'topRight',
            duration: 5,
        });
    };

    const showDeleteNotification = (sumary: string) => {
        onSuccess?.();
        if (!api) {
            message.success('Delete block time success');
            return;
        }
        api?.warning({
            message: 'Delete block time success',
            description: (
                <div>
                    <div className={s.notiTitle}>{sumary} is deleted</div>
                </div>),
            placement: 'topRight',
            duration: 5,
        });
    };

    const onOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const recurrence = getRecurrence(formValues.time[0], formValues.repeat);
            const title = `[Klarity] - ${formValues.description}`;
            const data:CreateEventType = {
                providerId: getUserId()!,
                summary: title,
                description: formValues.description,
                end: getTimeStrForGoogleCalandar(formValues.time[1], myTimezone.value as string), //'2023-01-05T17:00:00-07:00'
                start: getTimeStrForGoogleCalandar(formValues.time[0], myTimezone.value as string),
                timeZone: myTimezone?.value as string, //'America/Toronto'
                //timeZone: 'America/Toronto',
                colorId: ColorKey.blockTime,
            };
            if (recurrence) {
                //https://developers.google.com/calendar/api/guides/recurringevents
                data.recurrence = recurrence;
            }
            let result;
            if (isEdit) {
                const updateData:UpdateEventType = {
                    eventId: event.id,
                    ...data,
                };
                result = await calendarUpdateEvent(updateData!);
            } else {
                result = await calendarCreateEvent(data!);
            }
            if (!result.error) {
                showSuccessNotification(data);
            }
        } catch (e:any) {
            console.error(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    const onDelete = async () => {
        try {
            setDeleting(true);
            const data:DeleteEventType = {
                eventId: event!.id,
            };
            const result = await calendarDeleteEvent(data);
            if (!result.error) {
                showDeleteNotification(event?.summary || 'Block time');
            }
        } catch (e:any) {
            console.error(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setDeleting(false);
        }
    };

    const onDeleteAll = async () => {
        message.info('Coming soon');
        return;
        try {
            setDeleting(true);
            const data:DeleteEventType = {
                eventId: event!.id,
            };
            const result = await calendarDeleteEvent(data);
            if (!result.error) {
                message.success('Delete success');
                onSuccess?.();
            }
        } catch (e:any) {
            console.error(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setDeleting(false);
        }
    };

    React.useEffect(() => {
        if (event) {
            const serverEvent:ServerEvent = EventData.transferGoogleEventToServerEvent(event);
            form.setFieldsValue({
                [FormFieldEnum.DESCRIPTION]: serverEvent.description,
                [FormFieldEnum.TIME]: [dayjs(serverEvent.start), dayjs(serverEvent.end)],
                [FormFieldEnum.REPEAT]: getRecurrenceTypeFromStr(serverEvent.recurrence),
            });
            forceUpdate({});
        }
    }, [event]);
    return (
        <Modal
            title={event ? 'Edit block time' : 'Add block time'}
            closable
            className={commonS.modalFixHeightWrap}
            width="660px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            onOk={onOk}
            open
            footer={
                <div>
                    {/* {
                        isEdit && isRecurringEvent &&
                        <Button
                            onClick={onDeleteAll}
                            loading={deleting}
                            danger
                        >
                            Delete all repeated event
                        </Button>
                    } */}
                    {isEdit &&
                    <Button
                        onClick={onDelete}
                        type="primary"
                        loading={deleting}
                        danger
                    >
                        {isRecurringEvent ? 'Delete all' : 'Delete'}
                    </Button>
                    }
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onOk}>Save</Button>
                </div>
            }
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <div className={s.module}>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '320px' }}
                                label="Description:"
                                name={[FormFieldEnum.DESCRIPTION]}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Description name is required' },
                                ]}
                            >
                                <Input maxLength={300} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '550px' }}
                                label={
                                    <div>
                                        Date and time {showTimezone && <Tag color="red">{myTimezone?.value}</Tag>}:
                                    </div>
                                }
                                name={[FormFieldEnum.TIME]}
                                rules={[{ required: true, message: 'Date and time is required' }]}
                            >
                                <TimeRangerPicker
                                    onChange={(val) => {
                                        setCurrentDay(val[0]);
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {
                        //wait logic for BE
                        (!isEdit || isRecurringEvent || true) &&
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '360px' }}
                                    label="Repeats:"
                                    name={[FormFieldEnum.REPEAT]}
                                    dependencies={[FormFieldEnum.TIME]}
                                >
                                    <RepeatsSelect day={currentDay} />
                                </Form.Item>
                            </div>
                        </div>
                    }
                </div>
            </Form>
        </Modal>
    );
};

export default AddBlocktimeModal;
