import React from 'react';
import dayjs from 'dayjs';
import { Modal, Form, message, Button, Input, Tag } from 'antd';
import commonS from 'styles/common.module.less';
import { Event, ServerEvent } from 'types/calendar';
import { FormFieldEnum, FormInterface } from './types';
import TimeRangerPicker from 'components/form/TimeRangerPicker';
import s from './s.module.less';
import { EventData } from 'data/calendar';
import { calendarDeleteEvent, calendarUpdateEvent, CreateEventType, DeleteEventType, UpdateEventType } from 'api/google';
import { getTimeStrForGoogleCalandar } from 'utils/calendar';
import useTimezone from 'hooks/useTimezone';
import { SUPPORT_TIME_ZONE } from 'constants/common';
import { getBrowserTimezoneOffset } from 'utils/common';
import { NotificationInstance } from 'antd/es/notification/interface';

type Props = {
    onCancel:()=>void
    event?:Event //for edit fun
    onSuccess?: ()=>void
    api?:NotificationInstance
};

const EditCommonEventModal = (props:Props) => {
    const { onCancel, event, onSuccess, api } = props;
    const [form] = Form.useForm<FormInterface>();
    const [,forceUpdate] = React.useState({});
    const [, myTimezone] = useTimezone();
    const [submiting, setSubmiting] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const isEdit = !!event;

    let showTimezone = false;
    if (myTimezone?.value) {
        const target = SUPPORT_TIME_ZONE.find((item) => item.dictKey.toLowerCase() === (myTimezone?.value as string).toLowerCase());
        if (target && target.offset !== getBrowserTimezoneOffset()) {
            showTimezone = true;
        }
    }
    const showSuccessNotification = (evt: CreateEventType | UpdateEventType) => {
        const timeLabel = `${dayjs(new Date(evt.start)).format('hh:mm')} - ${dayjs(new Date(evt.end)).format('hh:mm A')}`;
        const title = isEdit ? 'Update calendar event success' : 'Add calendar event success';
        onSuccess?.();
        if (!api) {
            message.success(title);
            return;
        }
        api?.success({
            message: title,
            description: (
                <div>
                    <div className={s.notiTitle}>{evt.summary}</div>
                    <div className={s.notiTime}>{timeLabel}</div>
                </div>),
            placement: 'topRight',
            duration: 5,
        });
    };

    const showDeleteNotification = (sumary: string) => {
        onSuccess?.();
        if (!api) {
            message.success('Delete calendar event success');
            return;
        }
        api?.warning({
            message: 'Delete calendar event success',
            description: (
                <div>
                    <div className={s.notiTitle}>{sumary} is deleted</div>
                </div>),
            placement: 'topRight',
            duration: 5,
        });
    };
    const onOk = async () => {
        if (!isEdit) {
            message.error('Unexpect error');
        }
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const data:UpdateEventType = {
                eventId: event!.id,
                summary: formValues.summary,
                description: formValues.description,
                end: getTimeStrForGoogleCalandar(formValues.time[1], myTimezone.value as string), //'2023-01-05T17:00:00-07:00'
                start: getTimeStrForGoogleCalandar(formValues.time[0], myTimezone.value as string),
                timeZone: myTimezone?.value as string, //'America/Toronto'
                //timeZone: 'America/Toronto',
                colorId: event?.colorId, // || ColorKey.appointment,
            };
            const result = await calendarUpdateEvent(data!);
            if (!result.error) {
                showSuccessNotification(data);
            }
        } catch (e:any) {
            console.error(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    const onDelete = async () => {
        try {
            setDeleting(true);
            const data:DeleteEventType = {
                eventId: event!.id,
            };
            const result = await calendarDeleteEvent(data);
            if (!result.error) {
                showDeleteNotification(event?.summary || 'Calendar event');
            }
        } catch (e:any) {
            console.error(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setDeleting(false);
        }
    };

    React.useEffect(() => {
        if (event) {
            const serverEvent:ServerEvent = EventData.transferGoogleEventToServerEvent(event);

            form.setFieldsValue({
                [FormFieldEnum.SUMMARY]: serverEvent.summary,
                [FormFieldEnum.DESCRIPTION]: serverEvent.description,
                [FormFieldEnum.TIME]: [dayjs(serverEvent.start), dayjs(serverEvent.end)],
            });
            forceUpdate({});
        }
    }, [event]);

    return (
        <Modal
            title="Edit google calendar event"
            closable
            className={commonS.modalFixHeightWrap}
            width="660px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            onOk={onOk}
            open
            footer={
                <div>
                    {isEdit &&
                    <Button
                        onClick={onDelete}
                        type="primary"
                        loading={deleting}
                        danger
                    >
                        Delete
                    </Button>
                    }
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onOk}>Save</Button>
                </div>
            }
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <div className={s.module}>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '550px' }}
                                label={
                                    <div>
                                        Date and time {showTimezone && <Tag color="red">{myTimezone?.value}</Tag>}:
                                    </div>
                                }
                                name={[FormFieldEnum.TIME]}
                                rules={[{ required: true, message: 'Date and time is required' }]}
                            >
                                <TimeRangerPicker />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '320px' }}
                                label="Summary:"
                                name={[FormFieldEnum.SUMMARY]}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Summary is required' },
                                ]}
                            >
                                <Input maxLength={300} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '320px' }}
                                label="Description:"
                                name={[FormFieldEnum.DESCRIPTION]}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Description is required' },
                                ]}
                            >
                                <Input.TextArea
                                    rows={5}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default EditCommonEventModal;
