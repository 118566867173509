import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Form, message } from 'antd';
// import FormItem from './formItem';
import commonS from 'styles/common.module.less';
import { ISubmitKlarityConnectChannel } from 'types/klarityPlan';
import FormItem from 'pages/dashboard/KlarityProcessPages/KlarityPlanProcess/components/ConnectChannel/formItem';
import { getCMDAccessInfoByProviderid } from 'api/channel';

type TChannel = { id: number, price: number, name: string, fieldName: string };

const labels: string[][] = [
    ['Username', 'Password'],
    ['Email address', 'Password'],
];

interface IProps {
    connectChannes?: TChannel[];
    formId: string;
    handleSubmit?: (data: ISubmitKlarityConnectChannel[]) => void;
}

const AccountAccess = ({
    connectChannes,
    formId,
    handleSubmit,
}: IProps) => {
    const [formInstance] = Form.useForm();
    const selectedChannels = useMemo(() => connectChannes || [], [connectChannes]);
    // const [enableValidation, setEnableValidation] = useState(false);
    const [accessInfo, setAccessInfo] = useState<any>();

    const fetchAccessInfo = useCallback(async () => {
        try {
            const res = await getCMDAccessInfoByProviderid();

            if (res && !res.error) {
                setAccessInfo(res?.data?.data);
            } else {
                message.error(res?.error);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchAccessInfo();
        // setEnableValidation(true);
    }, [fetchAccessInfo]);

    const handleFinish = useCallback(async (values) => {
        const keys = Object.keys(values);
        const formData = keys.reduce((r, c) => {
            const channel = selectedChannels.find((item) => item.fieldName === c);
            const channelInAccessInfo = accessInfo.find((e) => e.channelName === channel?.name);

            r[c] = {
                channelId: channel?.id,
                channelName: channel?.name,
                account: values[c].userName,
                password: values[c].password,
                id: channelInAccessInfo?.id,
            };

            return r;
        }, {});

        handleSubmit?.(Object.values(formData));
    }, [handleSubmit, selectedChannels, accessInfo]);

    return (
        <div className={s.wrap}>
            <div className={s.step}>Account access and control</div>
            <div className={s.manageChannel}>
                <div className={s.list}>
                    <div className={s.item}>
                        <div className={s.title}>🔑 We won’t change your credential.</div>
                        <div className={s.desc}>{'You\'ll still have the keys to your accounts!'}</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.title}>🔍 You can edit your profile.</div>
                        <div className={s.desc}>major changes that affect visibility, we recommend contacting us.</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.title}>🕹️ No extra cost incurred.</div>
                        <div className={s.desc}>{'You\'ll continue to be billed directly by the channel. No additional cost incurred from Kiwi.'}</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.title}>🚀 Supercharge your reach.</div>
                        <div className={s.desc}>Your profiles will attract more clients with our SEO boost and multi-location reach.</div>
                    </div>
                </div>
            </div>
            {
                selectedChannels.length > 0 &&
                    (
                        <div className={s.info}>
                            <div className={s.form}>
                                <div className={s.title}>🔓 Rest assured, your data is safe with us — enter your channel login information below.</div>
                                <div className={s.desc}>Only authorized Kiwi Support Specialists will have access to your credentials. We take your privacy and security seriously.</div>
                                <Form
                                    form={formInstance}
                                    id={formId}
                                    className={commonS.formStyle1}
                                    layout="vertical"
                                    onFinish={handleFinish}
                                >
                                    {
                                        selectedChannels.map((channel, index) => {
                                            const { name, id, fieldName } = channel;
                                            return (
                                                <div key={id} className={s.formItemBox}>
                                                    <Form.Item
                                                        name={fieldName}
                                                        rules={[
                                                            {
                                                                validator: (rules, value) => {
                                                                    if (!value?.userName || !value?.password) {
                                                                        return Promise.reject();
                                                                    }
                                                                    return Promise.resolve(true);
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <FormItem itemBoxClass={s.formItemWidth} label={labels[index]} title={name} />
                                                    </Form.Item>
                                                </div>
                                            );
                                        })
                                    }
                                </Form>
                            </div>
                        </div>
                    )
            }
        </div>
    );
};

export default AccountAccess;
