import React from 'react';
import { Spin } from 'antd';
import { IproviderBillingList } from 'types/appointmentSetting';
import s from './s.module.less';
import useBilling from 'hooks/useBilling';
import BillingWrap from './components/BillingWrap';

const BillingPage = () => {
    const [items, loading, fetchData] = useBilling();

    return (
        <Spin spinning={loading}>
            <div className={s.wrap}>
                {
                    items && items.map((item: IproviderBillingList, index: number) => {
                        return (
                            <div key={index} className="bg-white rounded-lg px-7 py-3 mb-4">
                                <BillingWrap
                                    billingData={item}
                                    index={index}
                                    key={index}
                                    onChange={fetchData}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </Spin>

    );
};

export default BillingPage;
