import { Button, Form, Input, message, Modal, Spin, Switch } from 'antd';
import React from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import commonS from 'styles/common.module.less';
import { EMPTY_PLACEHOLDER, REQUIRED_FIELD } from 'constants/common';
import { getUserId } from 'utils/localstore';
import { updateBookingInfo } from 'api/practiceFront';
import ProviderStore from 'store/PracticeFront/provider';
import useGetPracticeFrontFormData from 'hooks/useGetPracticeFrontFormData';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import SubscriptionStore from 'store/Subscription';
import { CouponType } from 'types/provider';

type Props = {
    onSuccess?: () => void,
};

const AppointmentLinkEdit = (prop: Props) => {
    const { onSuccess } = prop;
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = React.useState(false);
    const [, fetchLoading, refetch] = useGetPracticeFrontFormData();
    const [getProvider] = ProviderStore.useStore();
    const providerData = getProvider('data');
    const [formInstance] = Form.useForm();
    const [hasLinkError, setHasLinkError] = React.useState(false);
    const [hasPhoneError, setHasPhoneError] = React.useState(false);
    const [submiting, setSubmiting] = React.useState(false);
    const { contact, seoCity, seoData, containSeoApp, appointmentIntegrationUrl } = providerData!;
    const VFD = contact?.vfdPhoneLine;
    const [homeInfo, loadingHomeInfo, refetchHomeInfo] = useProviderHomeInfo();
    const [getSubscription] = SubscriptionStore.useStore();
    const currentPlan = getSubscription('currentPlan');

    const isKlarity = homeInfo && homeInfo.klarityUser;
    const isKlarityWithKiwiPlan = homeInfo && homeInfo.klarityUser && !homeInfo.klarityUserWithoutKiwiPlan;
    const isInHeadwayDiscountCycle = currentPlan?.couponType === CouponType.HEADWAY;

    const canEditLink = (!isKlarity || isKlarityWithKiwiPlan) && !isInHeadwayDiscountCycle;
    const hasVFD = !!VFD;

    const canEdit = canEditLink || !hasVFD;

    const _appointmentIntegrationUrl = appointmentIntegrationUrl?.replace(/https?:\/\//g, '');

    const handleSubmit = async () => {
        try {
            setSubmiting(true);
            const formValues = await formInstance.validateFields();
            let { appointmentIntegrationUrl } = formValues;
            if (appointmentIntegrationUrl?.indexOf('https://') !== 0) {
                appointmentIntegrationUrl = `https://${appointmentIntegrationUrl}`;
            }
            const data: Record<string, string> = {
                appointmentIntegrationUrl,
            };
            if (!hasVFD) {
                data.practicePhone = formValues.practicePhone;
            }
            if (canEditLink) {
                data.appointmentIntegrationUrl = appointmentIntegrationUrl;
            }
            if (Object.keys(data).length === 0) {
                setIsEdit(false);
                return;
            }
            const res = await updateBookingInfo(data);
            if (!res.error) {
                refetch();
                setIsEdit(false);
                message.success('Appointment booking information updated!');
            }
        } catch (e: any) {
            console.error(e);
            if (e.errorFields) {
                //form error
                return;
            }
            //const msg = e?.toString?.() || 'data error';
            //message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (formInstance && contact) {
            const VFD = contact?.vfdPhoneLine;
            formInstance.setFieldsValue({
                appointmentIntegrationUrl: _appointmentIntegrationUrl,
                practicePhone: VFD || contact.practicePhone,
            });
        }
    }, [contact, formInstance, _appointmentIntegrationUrl]);

    return (
        <div className={s.module}>
            <div className={s.mTitle}>
                <div className={s.left}>
                    Appointment booking information
                </div>
                <div className={s.right}>
                    {
                        isEdit && (
                            <div className={s.btnWrap}>
                                <Button
                                    onClick={() => {
                                        setIsEdit(false);
                                    }}
                                    className={s.btn}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    loading={submiting}
                                    onClick={handleSubmit}
                                    type="primary"
                                    className={s.btn}
                                >
                                    Save
                                </Button>
                            </div>
                        )
                    }
                    {
                        !isEdit && (
                            <Button
                                onClick={() => {
                                    setIsEdit(true);
                                }}
                                disabled={!canEdit}
                                className={s.btn}
                            >
                                Edit
                            </Button>
                        )
                    }
                </div>
            </div>
            <div className={s.content}>
                {
                    isEdit &&
                    <Form
                        form={formInstance}
                        name="appointmentLink"
                        className={commonS.formStyle1}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <div className={s.itemBox}>
                            <div className={s.item}>
                                <Form.Item
                                    label="Scheduling link"
                                    name="appointmentIntegrationUrl"
                                    validateFirst
                                    rules={[
                                        { required: canEditLink, message: REQUIRED_FIELD },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            if (!val) {
                                                setHasLinkError(true);
                                            } else {
                                                setHasLinkError(false);
                                            }
                                        }}
                                        disabled={!canEditLink}
                                        addonBefore="https://"
                                    />
                                </Form.Item>
                                <p className={`${s.tips} ${hasLinkError ? s.tipsError : ''}`}>
                                    Scheduling link updates will automatically be reflected on your practice front page
                                </p>
                                <Form.Item
                                    label={hasVFD ? 'Virtual phone line' : 'Practice phone number'}
                                    name="practicePhone"
                                    validateFirst
                                    rules={[
                                        { required: !hasVFD, message: REQUIRED_FIELD },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            if (hasVFD) {
                                                return;
                                            }
                                            const val = e.target.value;
                                            if (!val) {
                                                setHasPhoneError(true);
                                            } else {
                                                setHasPhoneError(false);
                                            }
                                        }}
                                        maxLength={30}
                                        disabled={hasVFD}
                                    />
                                </Form.Item>
                                <p className={`${s.tips} ${hasPhoneError ? s.tipsError : ''}`}>
                                    Updated phone number will be reflected on both your practice front page and UniProfile
                                </p>
                            </div>
                        </div>
                    </Form>
                }
                {
                    !isEdit && (
                        <div className={s.view}>
                            <div className={s.displayItem}>
                                <div className={s.label}>
                                    Scheduling link
                                </div>
                                <div className={s.value}>
                                    {_appointmentIntegrationUrl || EMPTY_PLACEHOLDER}
                                </div>
                            </div>
                            <div className={s.displayItem}>
                                <div className={s.label}>
                                    {hasVFD ? 'Virtual phone line' : 'Practice phone number'}
                                </div>
                                <div className={s.value}>
                                    {hasVFD ? VFD : contact?.practicePhone || EMPTY_PLACEHOLDER}
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default AppointmentLinkEdit;
