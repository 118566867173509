import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { PATH } from 'constants/path';
import s from './s.module.less';
import ProviderStore from 'store/Provider';
import SubscriptionStore from 'store/Subscription';
import LandingIcon from './Frame.png';
import {
    LoadingOutlined,
} from '@ant-design/icons';
import AppointmentManagePage from '../AppointmentManagePage';
import { getHasViewAppointment, setHasViewAppointment } from 'utils/localstore';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import { isFreeUser } from 'utils/provider';

const Page = () => {
    const navigate = useNavigate();
    const [getUser] = ProviderStore.useStore();
    const [getSubscription] = SubscriptionStore.useStore();
    const user = getUser('data');
    const loadingUser = getUser('loading');
    const loadingPlan = getSubscription('loading');
    const hasPlan = !isFreeUser();

    if (!user || loadingUser || loadingPlan) {
        return <div style={{ padding: 48 }}><LoadingOutlined /></div>;
    }

    if (getHasViewAppointment() && hasPlan) {
        return <AppointmentManagePage />;
    }
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h1>Appointment</h1>
                <div className={s.des}>
                    Mange appointments from different patient channels in one place.
                </div>
            </div>
            <div className={s.content}>
                <div className={s.info}>
                    Facing challenges in juggling numerous calendars from different patient channels? Our Appointment is the solution you've been searching for. Designed to seamlessly integrate, this system ensures that patient bookings from all channels are directly and effortlessly consolidated into a singular calendar. Say goodbye to the hassle of toggling between multiple platforms and embrace a more organized, streamlined approach to managing your patient appointments. This tool not only simplifies your booking process but also minimizes the risk of overlapping appointments or double bookings. Experience efficiency like never before with our Appointment.
                </div>
                <Button
                    type="primary"
                    size="large"
                    className={s.btn}
                    onClick={() => {
                        if (!hasPlan) {
                            openSubscriptionModal();
                            return;
                        }
                        setHasViewAppointment('true');
                        navigate(`${PATH.DASHBOARD}/${PATH.APPOINTMENT_MANAGEMENT}`);
                    }}
                >
                    {/* Start without Gmail access */}
                    {hasPlan ? 'Start' : 'Upgrade to unlock'}
                </Button>
                <div className={s.imgWrap}>
                    <img src={LandingIcon} />
                </div>
            </div>
        </div>
    );
};

export default Page;
