import dayjs from 'dayjs';
import React from 'react';
import s from './s.module.less';
import { Event, EventWithTimeOverlap } from 'types/calendar';
import { getEventColor, isEventTimeOverlap } from 'utils/calendar';
import useCalendarColors from 'hooks/useCalendarColors';

type Props = {
    useAdminApi?: boolean
    day:dayjs.Dayjs
    events:Event[]
    onEventClick?: (event:Event)=>void
};

const Day = (props:Props) => {
    const { day, events, onEventClick, useAdminApi } = props;
    const [colors] = useCalendarColors({ useAdminApi });
    const timeArray:string[] = ['0 PM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
        '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM', '0',
    ];
    const isCurrentDay = day.format('DD-MM-YY') === dayjs().format('DD-MM-YY');
    const eventsWithTimeOverlap:EventWithTimeOverlap[] = React.useMemo(() => {
        const ret:EventWithTimeOverlap[] = [];
        for (let i = 0; i < events.length; i++) {
            const currentEvent = events[i];
            const timeOverlapKeys:string[] = [];
            for (let j = 0; j < i; j++) {
                const event2 = events[j];
                if (isEventTimeOverlap(currentEvent, event2)) {
                    timeOverlapKeys.push(event2.id);
                }
            }
            ret.push({ ...events[i], timeOverlapKeys });
        }
        return ret;
    }, [events]);

    return (
        <div className={s.wrap}>
            <div className={s.itemHeader}>
                <div className={s.header}>
                    {day.format('ddd').toUpperCase()}
                </div>
                <div><span className={isCurrentDay ? `${s.dayLabel} ${s.today}` : s.dayLabel}>{day.format('DD')}</span></div>
            </div>
            <div
                className={s.content}
            >
                <div className={s.timeWrap}>
                    <div className={s.dayBg}>
                        {timeArray.map((item) => {
                            return (
                                <div className={s.rowLine} key={item}>
                                    <span className={s.label}>{item}</span>
                                    <hr className={s.line} />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    className={s.eventWrap}
                    onClick={() => {
                    }}
                >
                    {eventsWithTimeOverlap.map((evt, idx) => {
                        const starTimetArray = dayjs(new Date(evt.start.dateTime)).format('HH-mm').split('-');
                        const endTimeArray = dayjs(new Date(evt.end.dateTime)).format('HH-mm').split('-');
                        const startHour = parseInt(starTimetArray[0], 10);
                        const startMin = parseInt(starTimetArray[1], 10);
                        const endHour = parseInt(endTimeArray[0], 10);
                        const endMin = parseInt(endTimeArray[1], 10);
                        const timeLabel = `${dayjs(new Date(evt.start.dateTime)).format('hh:mm')} - ${dayjs(new Date(evt.end.dateTime)).format('hh:mm A')}`;

                        let top = startHour * 50 + (startMin * 5) / 6;
                        const bottom = endHour * 50 + (endMin * 5) / 6;
                        if (startHour > endHour && endHour !== 0) {
                            top = 0;
                        }
                        const color = getEventColor(evt, colors);
                        const overlapCount = evt.timeOverlapKeys.length;
                        const isAccept = true; //BE not get evt.status === EventStatus.ACCEPTED || evt.status === EventStatus.CONFIRMED;
                        return (
                            <div
                                key={idx}
                                className={s.event}
                                style={{
                                    top,
                                    height: `${bottom - top}px`,
                                    background: isAccept ? color : '#fff',
                                    right: 0,
                                    color: isAccept ? '#fff' : color,
                                    left: overlapCount * 20,
                                }}
                                onClick={() => {
                                    if (onEventClick) {
                                        onEventClick(evt);
                                    }
                                }}
                            >
                                <p className={s.title}>{evt.summary || 'No title'}</p>
                                <p className={s.time}>{timeLabel}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Day;
