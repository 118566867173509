import React, { useState } from 'react';
import { Spin, Divider, Button, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { providerOverrideDelete } from 'api/appointmentSetting';
import { IproviderOverrideList, TimeItemType } from 'types/appointmentSetting';
import AddOverrideModal from './components/AddOverrideModal';
import useOverride from 'hooks/useOverride';
import dayjs from 'dayjs';

const DatesOverride = () => {
    const [openAddAppointmentModal, setOpenAddAppointmentModal] = useState(false);
    const [overrideList, loading, fetchData] = useOverride();
    const onCancel = () => {
        setOpenAddAppointmentModal(false);
    };
    const deleteBlockoutDatesItem = async (item: IproviderOverrideList) => {
        try {
            const { id } = item;
            const { data } = await providerOverrideDelete(id);
            if (data.code === 200) {
                message.success(data.message);
                fetchData();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const transferTimePicker = (val) => {
        const { fromHour, fromMinute, toHour, toMinute } = val;
        // eslint-disable-next-line no-nested-ternary
        const startHour = `${fromHour < 10 ? `0${fromHour}` : (fromHour > 12 ? (fromHour === (23 || 22) ? fromHour - 12 : `0${fromHour - 12}`) : fromHour)}`;
        const startMinute = `${fromMinute < 10 ? `0${fromMinute}` : fromMinute}`;
        const startLabel = `${startHour}:${startMinute} ${fromHour >= 12 ? 'PM' : 'AM'}`;

        // eslint-disable-next-line no-nested-ternary
        const endHour = `${toHour < 10 ? `0${toHour}` : (toHour > 12 ? (toHour === (23 || 22) ? toHour - 12 : `0${toHour - 12}`) : toHour)}`;
        const endMinute = `${toMinute < 10 ? `0${toMinute}` : toMinute}`;
        const endLabel = `${endHour}:${endMinute} ${toHour >= 12 ? 'PM' : 'AM'}`;
        return `${startLabel}-${endLabel}`;
    };

    return (
        <Spin spinning={loading}>
            <div className="bg-white rounded-lg px-7 py-3 mt-7">
                <div className="text-gray-800 text-xl font-medium">Date and time override</div>
                <Divider />
                <Button
                    className="mt-4"
                    onClick={() => {
                        setOpenAddAppointmentModal(true);
                    }}
                >+ Add an override
                </Button>
                <div className="mt-7 mb-7">
                    {
                        overrideList && overrideList.map((item, index) => {
                            const timeArr = item?.time;
                            return (
                                <div className="flex pl-6 pr-6 pt-4 pb-4 items-center justify-between w-2/3 mh-24 mb-4 border-gray-300 border-solid shadow-sm rounded-lg" style={{ borderWidth: '1px' }} key={index}>
                                    <div className="flex flex-col text-sm text-gray-500">
                                        <span className="text-gray-900 font-medium text-sm">{dayjs(item.overrideDate).format('dddd, MMMM DD, YYYY')}</span>
                                        {
                                            timeArr && timeArr?.map((i: TimeItemType, idx: number) => {
                                                return <span className="text-sm font-normal text-gray-500" key={idx}><i className="mr-1">·</i>{transferTimePicker(i)}</span>;
                                            })
                                        }
                                    </div>
                                    <CloseOutlined className="w-6 h-6 text-gray-400" onClick={() => deleteBlockoutDatesItem(item)} />
                                </div>
                            );
                        })
                    }
                </div>
                {
                    openAddAppointmentModal && <AddOverrideModal onCancel={onCancel} onFetchData={fetchData} />
                }
            </div>
        </Spin>
    );
};

export default DatesOverride;
