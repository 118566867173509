import { Button, message, Modal, Spin, Switch } from 'antd';
import React from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import AvaIcon from 'assets/common/ava.png';
import { EAvaTone } from 'types/ai';
import AppointmentLinkEdit from './components/AppointmentLinkEdit';
import NotificationEmailEdit from './components/NotificationEmailEdit';
import useProspectSettingInfo from 'hooks/useProspectSettingInfo';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields, SettingEnum } from 'types/enumerationData';
import { saveProspectSetting } from 'api/prospect'
import { setShowAvaSettingInProsepect } from 'utils/localstore';

const AvaSettingProspectPage = () => {
    const navigate = useNavigate();
    const [isOpenAva, setIsOpenAva] = React.useState(false);
    const [avaTony, setAvaTony] = React.useState<EAvaTone>();

    const [hasLoaded, setHasLoaded] = React.useState(false);


    const avaTonyOption = [
        {
            dictKey: EAvaTone.FRIENDLY,
            content: EAvaTone.FRIENDLY,
        },
        {
            dictKey: EAvaTone.CASUAL,
            content: EAvaTone.CASUAL,
        },
        {
            dictKey: EAvaTone.EMPATHETIC,
            content: EAvaTone.EMPATHETIC,
        },
        {
            dictKey: EAvaTone.FORMAL,
            content: EAvaTone.FORMAL,
        }
    ]
    const [info, loadingInfo] = useProspectSettingInfo();
    const [switching, setSwitching] = React.useState(false);
    const [changingTony, setChangingTony] = React.useState(false);

    React.useEffect(() => {
        if (info) {
            setIsOpenAva(info.avaAutoReply);
            setAvaTony(info.avaTone);
            setShowAvaSettingInProsepect('true');
            setHasLoaded(true);
        }
    }, [info])

    const handleChangeAvaSwitch = async (check: boolean) => {
        const current = isOpenAva;
        setIsOpenAva(check);
        setSwitching(true);
        const result = await saveProspectSetting(null, check);
        if (!result.error) {
            message.success('Save successfully');
        } else {
            //roll back
            setIsOpenAva(current);
        }
        setSwitching(false);
    }

    const handleAvaTonyChange = async (tonyName: string) => {
        if (!tonyName) {
            return;
        }
        const current = avaTony;
        setAvaTony(tonyName as EAvaTone);
        setChangingTony(true);
        const result = await saveProspectSetting(tonyName, null);
        if (!result.error) {
            message.success('Save successfully');
        } else {
            //roll back
            setAvaTony(tonyName as EAvaTone);
        }
        setChangingTony(false);
    }

    if (!info || !hasLoaded) {
        return <div className={s.loadingWrap}><Spin /></div>
    }

    return (
        <div className={s.wrap}>
            <div
                onClick={() => {
                    navigate(`${PATH.DASHBOARD}/${PATH.PROSPECTS}`);
                }}
                className={s.nav}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.70711 16.7071C9.31659 17.0976 8.68342 17.0976 8.2929 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.2929 3.29289C8.68342 2.90237 9.31659 2.90237 9.70712 3.29289C10.0976 3.68342 10.0976 4.31658 9.70712 4.70711L5.41422 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41422 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                        fill="currentColor"
                    />
                </svg>
                Back to prospects
            </div>
            <div className={s.header}>
                <h3 className={s.title}>Settings</h3>
            </div>
            <Spin spinning={loadingInfo}>
                <div className={s.content}>
                    <div className={s.module}>
                        <div className={s.mTitle}>
                            <div className={s.left}>
                                Auto-reply
                            </div>
                            <div className={s.right}>
                                {
                                    isOpenAva && (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                                <circle cx="4" cy="4" r="3" fill="#00816B" />
                                            </svg>
                                            <img src={AvaIcon} className={s.avaIcon} alt="ava" />
                                            <span className={s.textOn}>auto-reply on</span>
                                        </>
                                    )
                                }
                                {
                                    !isOpenAva && (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                                <circle cx="4" cy="4" r="3" fill="#6B7280" />
                                            </svg>
                                            <span className={s.avaText}>Ava</span>
                                            <span className={s.textOff}>auto-reply on</span>
                                        </>
                                    )
                                }
                                <Switch
                                    defaultChecked={isOpenAva}
                                    loading={switching}
                                    checked={isOpenAva}
                                    onChange={handleChangeAvaSwitch}
                                />
                            </div>
                        </div>
                        <div className={s.switchContent}>
                            <div className={s.des}>
                                By enabling auto-reply, Ava will instantly respond to prospect inquiries using information from your UniProfile and appointment settings. This will save you time while ensuring accurate, and personalized replies that clearly communicate your requirements.
                            </div>
                            <div className={s.des}>
                                With auto-reply off, Ava will seamlessly switch to Co-pilot mode. She'll draft responses for you to review and send it to prospects, giving you full control over the conversation.
                            </div>
                        </div>
                    </div>

                    <div className={s.module}>
                        <div className={s.mTitle}>
                            <div className={s.left}>
                                Set Ava's tone
                            </div>
                        </div>
                        <div className={s.des} style={{ marginTop: '24px' }}>
                            Ava will generally use the tone you selected, but may adapt her responses to match the context of each message.
                        </div>
                        <div className={s.tonyContent}>
                            <div className={s.left}>
                                <Spin spinning={changingTony}>
                                    {
                                        avaTonyOption.map((item) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        if (changingTony) {
                                                            return;
                                                        }
                                                        handleAvaTonyChange(item.content);
                                                    }}
                                                    className={`${s.tonyItem} ${avaTony === item.content ? s.tonyItemActive : ''}`}>
                                                    {item.content}
                                                </div>
                                            )
                                        })
                                    }
                                </Spin>
                            </div>
                            <div className={s.right}>
                                <div className={s.p0}>
                                    Ava will reply with a {avaTony} tone like:
                                </div>
                                <div className={s.p1}>
                                    <div className={s.p1Icon}>
                                        P
                                    </div>
                                    <div className={s.p1Content}>
                                        Hello, I'm reaching out to inquire if you are currently accepting new patients and if you have experience treating anxiety.
                                    </div>
                                </div>
                                <div className={s.p2}>
                                    <div className={s.p2Content}>
                                        {
                                            avaTony === EAvaTone.FRIENDLY
                                            &&
                                            <div className={s.text}>
                                                Thank you for reaching out, Elisabeth. I'm currently accepting new patients and would be happy to assist with your anxiety treatment. To schedule an appointment, please visit my appointment link: <a>bookinglink.com/provider</a>. If you have any further questions, you can contact me via 123-555-6688.
                                            </div>
                                        }
                                        {
                                            avaTony === EAvaTone.CASUAL
                                            &&
                                            <div className={s.text}>
                                                Hey Elisabeth! Thanks for getting in touch. I am accepting new patients and would be happy to help you with your anxiety. Feel free to book an appointment online at <a>bookinglink.com/provider</a> or give me a call at 123-555-6688 if you have any questions.
                                            </div>
                                        }
                                        {
                                            avaTony === EAvaTone.EMPATHETIC
                                            &&
                                            <div className={s.text}>
                                                Hello Elisabeth, I understand reaching out about anxiety can be a big step, so thank you for contacting me. I am currently accepting new patients and specialize in anxiety treatment. Please feel free to schedule an appointment at your convenience at <a>bookinglink.com/provider</a> or call me at 123-555-6688 if you'd like to discuss anything further.
                                            </div>
                                        }
                                        {
                                            avaTony === EAvaTone.FORMAL
                                            &&
                                            <div className={s.text}>
                                                Dear Ms. Elisabeth,
                                                <br />
                                                Thank you for your inquiry. I am pleased to confirm that I am currently accepting new patients and have extensive experience in treating anxiety. Please visit <a>bookinglink.com/provider</a> to schedule an appointment, or contact my office directly at 123-555-6688 if you require additional information.
                                            </div>
                                        }
                                        <div className={s.name}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M6.30337 11.2923C8.92923 11.2923 11.0579 9.1636 11.0579 6.53774C11.0579 3.91188 8.92923 1.7832 6.30337 1.7832C3.67751 1.7832 1.54883 3.91188 1.54883 6.53774C1.54883 9.1636 3.67751 11.2923 6.30337 11.2923ZM8.50656 5.76935C8.73866 5.53726 8.73866 5.16096 8.50656 4.92886C8.27447 4.69677 7.89817 4.69677 7.66607 4.92886L5.70905 6.88588L4.94066 6.1175C4.70856 5.8854 4.33226 5.8854 4.10017 6.1175C3.86807 6.34959 3.86807 6.72589 4.10017 6.95799L5.2888 8.14662C5.5209 8.37872 5.8972 8.37872 6.12929 8.14662L8.50656 5.76935Z" fill="#069D70" />
                                            </svg>
                                            Replied by
                                            <img src={AvaIcon} className={s.avaIconName} alt="ava" />
                                        </div>
                                    </div>
                                    <div className={s.p2Icon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                            <path d="M17 33C25.8365 33 33 25.8365 33 17C33 8.16346 25.8365 1 17 1C8.16346 1 1 8.16346 1 17C1 25.8365 8.16346 33 17 33Z" fill="#F0FCF6" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.273438 16.9997C0.273438 7.76153 7.76251 0.272461 17.0007 0.272461C26.2389 0.272461 33.728 7.76153 33.728 16.9997C33.728 26.2379 26.2389 33.727 17.0007 33.727C7.76251 33.727 0.273438 26.2379 0.273438 16.9997ZM17.0007 1.72701C8.56583 1.72701 1.72798 8.56485 1.72798 16.9997C1.72798 25.4346 8.56583 32.2725 17.0007 32.2725C25.4356 32.2725 32.2734 25.4346 32.2734 16.9997C32.2734 8.56485 25.4356 1.72701 17.0007 1.72701Z" fill="#00816B" />
                                            <path d="M26.1445 20.4282C27.7809 20.4282 28.8111 19.3133 28.8111 17.6769C28.8111 16.0405 27.7809 14.7139 26.1445 14.7139V17.7615V20.4282Z" fill="#A3CDC3" />
                                            <path d="M8.23828 14.7134C6.60188 14.7134 5.57168 15.8283 5.57168 17.4647C5.57168 19.1011 6.60188 20.4277 8.23828 20.4277V17.3801V14.7134Z" fill="#A3CDC3" />
                                            <path d="M7.77344 12.9287C7.77344 11.8576 8.64173 10.9893 9.71283 10.9893H24.8817C25.9528 10.9893 26.8211 11.8576 26.8211 12.9287V22.7641C26.8211 23.8352 25.9528 24.7035 24.8817 24.7035H9.71283C8.64173 24.7035 7.77344 23.8352 7.77344 22.7641V12.9287Z" fill="#00816B" />
                                            <path d="M16.2383 8.61914H18.524V11.6668H16.2383V8.61914Z" fill="#00816B" />
                                            <path d="M9.38281 14.3671C9.38281 13.296 10.2511 12.4277 11.3222 12.4277H23.4434C24.5145 12.4277 25.3828 13.296 25.3828 14.3671V21.155C25.3828 22.2261 24.5145 23.0944 23.4434 23.0944H11.3222C10.2511 23.0944 9.38281 22.2261 9.38281 21.155V14.3671Z" fill="white" />
                                            <path d="M20.4715 18.7778C20.7988 18.7778 21.0641 18.3798 21.0641 17.8889C21.0641 17.398 20.7988 17 20.4715 17C20.1442 17 19.8789 17.398 19.8789 17.8889C19.8789 18.3798 20.1442 18.7778 20.4715 18.7778Z" fill="#00816B" />
                                            <path d="M15.7305 19.666C16.2569 20.017 16.8755 20.2043 17.5082 20.2043C18.141 20.2043 18.7596 20.017 19.286 19.666" fill="#00816B" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M15.4283 19.4647C15.5397 19.2976 15.7655 19.2524 15.9326 19.3639C16.3993 19.675 16.9477 19.8411 17.5086 19.8411C18.0696 19.8411 18.618 19.675 19.0847 19.3639C19.2518 19.2524 19.4776 19.2976 19.589 19.4647C19.7004 19.6318 19.6552 19.8576 19.4881 19.969C18.9019 20.3598 18.2132 20.5684 17.5086 20.5684C16.8041 20.5684 16.1153 20.3598 15.5291 19.969C15.362 19.8576 15.3169 19.6318 15.4283 19.4647Z" fill="#00816B" />
                                            <path d="M14.5457 18.7778C14.873 18.7778 15.1383 18.3798 15.1383 17.8889C15.1383 17.398 14.873 17 14.5457 17C14.2184 17 13.9531 17.398 13.9531 17.8889C13.9531 18.3798 14.2184 18.7778 14.5457 18.7778Z" fill="#00816B" />
                                            <path d="M20.0482 7.85682C20.0482 8.69839 18.8543 9.38063 17.3815 9.38063C15.9088 9.38063 14.7148 8.69839 14.7148 7.85682C14.7148 7.01524 15.9088 6.33301 17.3815 6.33301C18.8543 6.33301 20.0482 7.01524 20.0482 7.85682Z" fill="#A3CDC3" />
                                        </svg>
                                    </div>
                                </div>
                                <div className={s.p3}>
                                    *Your prospects or patients will see this message in an email.
                                </div>
                            </div>
                        </div>
                    </div>
                    <AppointmentLinkEdit />
                    <NotificationEmailEdit />
                </div>
            </Spin>
        </div>
    );
};

export default AvaSettingProspectPage;
