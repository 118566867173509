import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { getUserId } from 'utils/localstore';
import FullScreenPopLayout from 'components/FullScreenPopLayout';
import { Button, message } from 'antd';
import ExisttingChannel from './components/ExisttingChannel';
import AccountAccess from './components/AccountAccess';
import SuccessPage from './components/Success';
import { TChannel } from './type';
import { submitNonCMDChannelSelected, submitRecommandChannels, updateAccessInfo } from 'api/channel';
import { ISubmitRecommandChannelInput, IUpdateAccessInfo } from 'types/channel';
// import ChannelContext from 'pages/dashboard/ChannelMainPage/context';
import { ISubmitKlarityConnectChannel } from 'types/klarityPlan';
import { Email } from 'constants/contact';

const ChannelMagementDelegationNotice = () => {
    const formId = useRef('accountForm');
    const popLayoutContentId = useRef('popLayoutContentId');
    const [showFullScreenPopLayout, setShowFullScreenPopLayout] = useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [connectChannes, setConnectChannes] = useState<TChannel[]>([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [step, setStep] = useState(1);
    const providerId = getUserId();
    const [showNonSeletedSuccess, setShowNonSeletedSuccess] = useState(false);
    const [showTip, setShowTip] = useState(true);

    const handleExpandIconClick = useCallback(() => {
        // setHideChannelManagementDelegationForever('true');
        // setShowFullScreenPopLayout(false);
        setShowTip((pre) => !pre);
        // hideChannelManagementDelegationNotice();
    }, []);
    const handleTry = useCallback(() => {
        setShowFullScreenPopLayout(true);
    }, []);

    const handleNonChannelSelectedSubmit = useCallback(async () => {
        setSubmitLoading(true);

        try {
            const res = await submitNonCMDChannelSelected();
            if (res && !res.error) {
                setShowNonSeletedSuccess(true);
                setStep(3);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setSubmitLoading(false);
    }, []);

    const handleNextClick = useCallback(() => {
        if (step === 1 && connectChannes.length === 0) {
            handleNonChannelSelectedSubmit();
        } else {
            setStep(step + 1);
        }
    }, [connectChannes, handleNonChannelSelectedSubmit, step]);

    // 保存账号密码
    const handleAccountInfoSubmit = useCallback(async (formData: IUpdateAccessInfo[]) => {
        setSubmitLoading(true);

        try {
            const res = await updateAccessInfo(formData);
            if (res && !res.error) {
                setStep(step + 1);
                setShowNonSeletedSuccess(false);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setSubmitLoading(false);
    }, [step]);

    // 保存选中的channels
    const handleChannelSubmit = useCallback(async (accountInfoSubmitData: IUpdateAccessInfo[]) => {
        setSubmitLoading(true);

        try {
            const formData: ISubmitRecommandChannelInput = {
                channelIdList: connectChannes.map((e) => e.id),
                providerId: providerId?.toString(),
                listingType: 'MANAGED',
            };
            const res = await submitRecommandChannels(formData);
            if (res && !res.error) {
                handleAccountInfoSubmit?.(accountInfoSubmitData);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setSubmitLoading(false);
    }, [connectChannes, handleAccountInfoSubmit, providerId]);

    const handleAccountFormSubmit = useCallback((value: ISubmitKlarityConnectChannel[]) => {
        const formData: IUpdateAccessInfo[] = value.map((e) => ({ ...e, providerId: Number(providerId) }));

        handleChannelSubmit(formData);
    }, [handleChannelSubmit, providerId]);

    const closeFullScreenPopLayout = useCallback(() => {
        setShowFullScreenPopLayout(false);
        setStep(1);
        setNextButtonDisabled(true);
    }, []);

    const popLayContent = useMemo(() => {
        let content = null;
        switch (step) {
            case 1:
                content = (
                    <ExisttingChannel
                        setNextButtonDisabled={(v) => setNextButtonDisabled(!!v)}
                        setConnectChannes={(v) => setConnectChannes(v)}
                    />
                );
                break;
            case 2:
                content = (
                    <AccountAccess
                        connectChannes={connectChannes}
                        formId={formId.current}
                        handleSubmit={handleAccountFormSubmit}
                    />
                );
                break;
            case 3:
                content = (
                    <SuccessPage isNonSelected={showNonSeletedSuccess} closeFullScreenPopLayout={closeFullScreenPopLayout} />
                );
                break;
            default: {
                content = null;
            }
        }

        return content;
    }, [closeFullScreenPopLayout, connectChannes, handleAccountFormSubmit, showNonSeletedSuccess, step]);

    useEffect(() => {
        document.getElementById(popLayoutContentId.current)?.scrollTo(0, 0);
    }, [step]);

    const handleBack = useCallback(() => {
        if (step === 1) {
            setShowFullScreenPopLayout(false);
        } else {
            setStep(step - 1);
        }
    }, [step]);

    const handleNeedHelp = useCallback(() => {
        window.open(`mailto:${Email.support}`);
    }, []);

    return (
        <>
            <div className={s.paidUserNotice}>
                <div className={s.noticeIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                        <path d="M11.9204 8.85718L10.3896 14.7619L11.8111 21.8695L8.8587 20.5027L6.83577 17.2223L6.17969 14.5979L8.20262 11.1535L11.9204 8.85718Z" fill="#C0EAFF" />
                        <path d="M13.668 15.4179C13.668 16.2589 13.8638 17.0655 14.2124 17.6602C14.561 18.2549 15.0339 18.589 15.5269 18.589C16.0199 18.589 16.4927 18.2549 16.8413 17.6602C17.1899 17.0655 17.3858 16.2589 17.3858 15.4179C17.3858 14.5769 17.1899 13.7703 16.8413 13.1756C16.4927 12.5809 16.0199 12.2468 15.5269 12.2468C15.0339 12.2468 14.561 12.5809 14.2124 13.1756C13.8638 13.7703 13.668 14.5769 13.668 15.4179Z" fill="#C0EAFF" />
                        <path d="M12.9045 23.6736C12.7952 23.6736 12.6858 23.6189 12.5765 23.5643L9.02268 20.9399C7.92921 20.2292 7.05443 19.1904 6.50769 17.9329C6.17965 17.1674 6.01562 16.3473 6.01562 15.4726C6.01562 14.5978 6.17965 13.7777 6.50769 13.0122C6.99975 11.7547 7.87453 10.7706 9.02268 10.0599L12.3578 7.70889C12.6311 7.54487 12.9592 7.59955 13.1232 7.81824C13.2872 8.09161 13.2326 8.41965 13.0139 8.58367L9.67876 10.9346C8.69464 11.5361 7.98388 12.4108 7.54649 13.395C7.27312 14.051 7.1091 14.7618 7.1091 15.4726C7.1091 16.1833 7.21845 16.8394 7.49182 17.4955C7.92921 18.5343 8.63996 19.4091 9.56942 20.0105L13.1232 22.6348C13.3419 22.7988 13.3966 23.1816 13.2326 23.4003C13.2326 23.5643 13.0685 23.6736 12.9045 23.6736Z" fill="#1F87DD" />
                        <path d="M15.854 25.1499C12.5736 25.1499 10.0039 20.8854 10.0039 15.5273C10.0039 10.1693 12.5736 5.8501 15.854 5.8501C16.6194 5.8501 17.3849 6.06879 18.0956 6.56086C18.369 6.72488 18.4237 7.05292 18.2596 7.32629C18.0956 7.59966 17.7676 7.65433 17.4942 7.49031C16.9475 7.16227 16.4554 6.94357 15.854 6.94357C13.2843 6.94357 11.0974 10.8801 11.0974 15.4727C11.0974 20.0653 13.2843 24.0564 15.854 24.0564C18.4237 24.0564 20.6106 20.1199 20.6106 15.5273C20.6106 13.2857 20.1185 11.0988 19.1891 9.51324C19.0251 9.23987 19.1344 8.91183 19.4078 8.7478C19.6812 8.58378 20.0092 8.69313 20.1732 8.9665C21.1573 10.7707 21.7041 13.067 21.7041 15.5273C21.7588 20.8854 19.1891 25.1499 15.854 25.1499Z" fill="#1F87DD" />
                        <path d="M15.8548 19.5732C14.3786 19.5732 13.2305 17.7689 13.2305 15.5273C13.2305 13.2857 14.3786 11.4814 15.8548 11.4814C17.331 11.4814 18.4791 13.2857 18.4791 15.5273C18.4791 17.7689 17.331 19.5732 15.8548 19.5732ZM15.8548 12.5202C15.144 12.5202 14.3239 13.7231 14.3239 15.4726C14.3239 17.2222 15.144 18.425 15.8548 18.425C16.5656 18.425 17.3857 17.2222 17.3857 15.4726C17.3857 13.7231 16.5656 12.5202 15.8548 12.5202Z" fill="#1F87DD" />
                        <path d="M15.8547 12.5204H15.8001L13.0117 12.1924C12.7383 12.1377 12.5196 11.8643 12.5196 11.5909C12.5743 11.3176 12.8477 11.0989 13.121 11.0989L15.9094 11.4269C16.1828 11.4816 16.4015 11.755 16.4015 12.0283C16.4015 12.3017 16.1281 12.5204 15.8547 12.5204ZM11.2621 20.0654C10.9888 20.0654 10.7701 19.8467 10.7154 19.5733C10.6607 19.2999 10.8794 19.0266 11.2075 18.9719L15.8547 18.4798C16.1281 18.4252 16.4015 18.6439 16.4561 18.9719C16.5108 19.2453 16.2921 19.5186 15.9641 19.5733L11.3168 20.0654H11.2621Z" fill="#1F87DD" />
                        <path d="M23.1286 11.0442C22.9646 11.0442 22.8553 10.9895 22.7459 10.8802C22.5272 10.6615 22.5272 10.3335 22.7459 10.1148L23.5113 9.34932C23.73 9.13063 24.0581 9.13063 24.2768 9.34932C24.4955 9.56802 24.4955 9.89606 24.2768 10.1148L23.5113 10.8802C23.402 10.9895 23.238 11.0442 23.1286 11.0442ZM23.3473 21.8149C23.1833 21.8149 23.074 21.7603 22.9646 21.6509L22.1992 20.8855C21.9805 20.6668 21.9805 20.3387 22.1992 20.12C22.4179 19.9014 22.7459 19.9014 22.9646 20.12L23.73 20.8855C23.9487 21.1042 23.9487 21.4322 23.73 21.6509C23.6207 21.7603 23.5113 21.8149 23.3473 21.8149ZM24.8235 16.0195H23.1833C22.8553 16.0195 22.6366 15.8008 22.6366 15.4728C22.6366 15.1447 22.8553 14.926 23.1833 14.926H24.8235C25.1516 14.926 25.3703 15.1447 25.3703 15.4728C25.3703 15.8008 25.0969 16.0195 24.8235 16.0195Z" fill="#1F87DD" />
                    </svg>
                </div>
                <div className={s.noticeContent}>
                    <div className={s.textArea}>
                        <div className={s.noticeTitle}>New <span>feature</span>: Channel management delegation</div>
                        <div className={`${s.noticeText} ${showTip ? s.showText : s.hideText}`}>Want to boost the performance of your existing channels (like Psychology Today)? Delegate the channel profile optimization to Kiwi and let the marketing pros to boost your performance. This new service is free for paid users.</div>
                    </div>
                    <div className={s.try} onClick={handleTry}>Try it</div>
                    <div className={s.closeIcon} onClick={handleExpandIconClick}>
                        <div className={`${s.iconBox} ${showTip ? s.expandIcon : s.collapseIcon}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {
                showFullScreenPopLayout && (
                    <FullScreenPopLayout
                        addHeader
                        contentId={popLayoutContentId.current}
                        wrapClass={s.popLayoutClass}
                        headerClass={s.popLayoutHeaderClass}
                        footerClass={step !== 3 ? s.popLayoutFooterClass : s.successPagePopLayoutFooterClass}
                        headerRightButtons={
                            <Button type="primary" ghost onClick={handleNeedHelp}>Need Help?</Button>
                        }
                        footerButton={
                            step !== 3 ? (
                                <>
                                    {
                                        step !== 3 && (
                                            <Button
                                                type="default"
                                                onClick={handleBack}
                                            >Back
                                            </Button>
                                        )
                                    }
                                    {
                                        step === 2 ? (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                form={formId.current}
                                                loading={submitLoading}
                                            >Next
                                            </Button>
                                        ) : (
                                            <Button
                                                loading={submitLoading}
                                                type="primary"
                                                disabled={nextButtonDisabled}
                                                onClick={handleNextClick}
                                            >Next
                                            </Button>
                                        )
                                    }
                                </>
                            ) : ''
                        }
                    >
                        {popLayContent}
                    </FullScreenPopLayout>
                )
            }
        </>
    );
};

export default ChannelMagementDelegationNotice;
