import dayjs from 'dayjs';
import { RecurringType } from 'types/calendar';

export enum FormFieldEnum {
    DESCRIPTION = 'description',
    TIME = 'time',
    REPEAT = 'repeat',
}

export interface FormInterface {
    [FormFieldEnum.DESCRIPTION]: string;
    [FormFieldEnum.TIME]: dayjs.Dayjs[];
    [FormFieldEnum.REPEAT]: RecurringType;
}
