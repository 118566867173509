import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ActiveChannelCard from '../ActiveChannelCard';
import UpcomingChannelCard from '../UpcomingChannelCard';
import s from './s.module.less';
import { useRequest } from 'ahooks';
import { getLiveChannelList, getUpcomingChannelList, updateVoteById, createNewChannel } from 'api/channel';
import { Channel, EListStatus, Meta, ChannelPayPlanTypeEnum } from 'types/common';
import { Button, Dropdown, Form, Input, message, Popover, Select, Spin } from 'antd';
import commonS from 'styles/common.module.less';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import ProviderChannelsStore from 'store/ProviderChannles';
import { getPlanTypeByInfo } from 'utils/provider';
import { DEFAULT_CHANNEL_LISTING_LIMITATION } from 'constants/common';
import { EChannelPayType, TSubscriptionType } from 'types/subscription';
import SubscriptionStore from 'store/Subscription';
import ChannelSelectModal from 'components/ChannelSelectModal';
import useLiveChannelsWithCategory from 'hooks/useLiveChannelsWithCategory';
import ChannelMagementDelegationNotice from '../ChannelMagementDelegationNotice';
// import { filterAvailableChannel } from 'utils/channel';

interface INewChannel {
    name: string;
    website?: string;
}

interface IProps {
    showChannelManagementDelegationNotice?: boolean;
    isFree: boolean;
    setLiveChannelData: (data: Channel[]) => void;
}

const PAY_TYPE = 'payType';
const PRACTICE_TYPE = 'practiceType';
const PAYMENT_TYPE = 'paymentType';
const SORT = 'SORT';

const filterFieldMap = {
    [PAY_TYPE]: 'payPlanType',
    [PRACTICE_TYPE]: 'tags',
    [PAYMENT_TYPE]: 'tags',
    [SORT]: 'tags',
};

const ChannelStorePage = ({
    showChannelManagementDelegationNotice,
    isFree,
    setLiveChannelData,
    switchToMychannel,
}: IProps) => {
    const [showSubmitChannelSuccess, setShowSubmitChannelSuccess] = useState(false);
    const [getSubscription] = SubscriptionStore.useStore();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const { data: channelListData = { data: { data: [] } }, run: liveChannelRun, loading: liveChannelLoading } = useRequest(getLiveChannelList) || {};
    const { data: upcomingChannelList, run, loading: upcomingChannelLoading } = useRequest(getUpcomingChannelList);
    const [interestForm] = Form.useForm();
    const [setting] = useSettings();
    const payPlanTypes = setting[EnumFields.CHANNEL_PAY_PLAN_TYPE];
    const channelTags = setting[EnumFields.CHANNEL_TAG];
    const channelFocusTypes = setting[EnumFields.CHANNEL_FOCUS];
    const sortTypesSetting = setting[EnumFields.CHANNEL_SORT_TYPE];
    const sortTypes = sortTypesSetting?.map((e: Meta) => ({
        key: e.value,
        label: e.label,
    })) || [];

    const [payPlanTypeOptions, setPayPlanTypeOptions] = useState<Meta[]>([]);
    const [practiceTypeOptions, setPracticeTypeOptions] = useState<Meta[]>([]);
    const [channelFocusTypeOptions, setChannelFocusTypeOptions] = useState<Meta[]>([]);
    const [filterPramas, setFilterPramas] = useState<Record<string, string | string[]>>({
        [PAY_TYPE]: '',
        [PRACTICE_TYPE]: '',
        [PAYMENT_TYPE]: [],
        [SORT]: '',
    });
    const [allLiveChannelWithCategory, loadingAllLiveChannelWithCategory] = useLiveChannelsWithCategory();
    const [liveChannelList, setLiveChannelList] = useState<Channel[]>([]);
    const pageIndex = useRef(1);
    const [pageSize] = useState(10);
    const [upcomingPageData, setUpcomingPageData] = useState([]);
    const [voting, setVoting] = useState(false);
    const [getProviderChannels] = ProviderChannelsStore.useStore();
    const myChannels = getProviderChannels('data');
    const [modalSelectType, setModalSelectType] = React.useState(ChannelPayPlanTypeEnum.FREE);
    const [showChannelSelectModal, setShowChannelSelectModal] = React.useState(false);
    const [hasClickMoreStandar, setHasClickMoreStandar] = React.useState(false);

    useEffect(() => {
        const practiceTypes = channelTags?.filter((tag) => tag.value === 'In-person clinic only' || tag.value === 'Telehealth clinic only') || [];

        setPayPlanTypeOptions([
            {
                label: 'All',
                value: '',
            },
            ...(payPlanTypes || []),
        ]);
        setPracticeTypeOptions([
            {
                label: 'All',
                value: '',
            },
            ...(practiceTypes),
        ]);
        setChannelFocusTypeOptions(channelFocusTypes || []);
    }, [channelTags, payPlanTypes, channelFocusTypes]);

    useEffect(() => {
        if (channelListData?.data?.data && channelListData?.data?.data?.length > 0) {
            const channelsData = channelListData.data.data;
            setLiveChannelList(channelsData);
            if (typeof setLiveChannelData === 'function') {
                setLiveChannelData(channelsData);
            }
        }
    }, [channelListData, setLiveChannelData]);

    const getUpcommingDataByPage = useCallback((data = []) => {
        const upcomingListData = data || [];

        const endIndex = pageIndex.current * pageSize;
        const currentData = upcomingListData.slice(0, endIndex);

        setUpcomingPageData(currentData);
        pageIndex.current += 1;
    }, [pageSize]);

    useEffect(() => {
        const upcomingListData = upcomingChannelList?.data?.data;

        if (pageIndex.current === 1 && upcomingListData && upcomingListData.length > 0) {
            getUpcommingDataByPage(upcomingListData);
        }
    }, [getUpcommingDataByPage, upcomingChannelList]);

    const handleUpdateVote = useCallback(async (id: number, voted: boolean) => {
        const params = {
            id,
            voteType: voted ? 0 : 1,
        };
        setVoting(true);

        try {
            const res = await updateVoteById(params);

            if (res && !res.error) {
                pageIndex.current = 1;
                run();
            } else {
                message.error(res.error);
            }
        } catch (e) {
            console.error(e);
        }

        setVoting(false);
    }, [run]);

    const submitNewChannel = useCallback(async (formData: INewChannel) => {
        setLoading(true);
        setShowSubmitChannelSuccess(false);
        const res = await createNewChannel(formData);

        setLoading(false);
        if (!res?.error) {
            messageApi.open({
                type: 'success',
                content: 'Submit interest channel successfully!',
            });
            interestForm.resetFields();
            setShowSubmitChannelSuccess(true);
        } else {
            messageApi.open({
                type: 'error',
                content: res.error,
            });
        }
    }, [interestForm, messageApi]);

    const handleShowUpgardePlan = useCallback(() => {
        openSubscriptionModal();
    }, []);

    const handleFilter = (e: string | string[], type: string) => {
        filterPramas[type] = e;
        setFilterPramas({
            ...filterPramas,
        });
    };

    const handleClearFilter = useCallback(() => {
        const listData = channelListData?.data?.data || [];
        setFilterPramas({
            [PAY_TYPE]: '',
            [PRACTICE_TYPE]: '',
            [PAYMENT_TYPE]: [],
            [SORT]: '',
        });
        setLiveChannelList(listData);
    }, [channelListData]);

    const onOrderClick = (e) => {
        handleFilter(e.key, SORT);
    };

    const freeTip = useMemo(() => {
        let freeChannle = ['WebMD Standard', 'NextDoor'];
        if (myChannels?.freeList && myChannels?.freeList.length > 0) {
            freeChannle = myChannels.freeList.splice(0, 2).map((e: Channel) => e.name);
        }

        const channel1 = freeChannle[0];
        const channel2 = freeChannle[1] ? `and ${freeChannle[1]}` : '';

        return `Users on Free Plan can list on ${channel1} ${channel2}. Upgrade to Essential or Growth Membership Plan to gain access to 20+ channels to increase your online presence.`;
    }, [myChannels]);

    const spinning = liveChannelLoading || upcomingChannelLoading || loading || voting;

    const currentPlan = getSubscription('currentPlan');
    const freeChannelLimit = currentPlan?.freeChannelLimit === null ? null : (currentPlan?.freeChannelLimit || DEFAULT_CHANNEL_LISTING_LIMITATION.free);
    const paidChannelLimit = currentPlan?.paidChannelLimit === null ? null : (currentPlan?.paidChannelLimit || DEFAULT_CHANNEL_LISTING_LIMITATION.standard);

    const allFreeChannels = liveChannelList?.filter((e: Channel) => e.payPlanType === EChannelPayType.FREE) || [];
    const allStandardChannels = liveChannelList?.filter((e: Channel) => e.payPlanType === EChannelPayType.PAID) || [];
    const allPremiumChannels = liveChannelList?.filter((e: Channel) => e.payPlanType === EChannelPayType.PREMIUM) || [];

    const allFreeChannelAmount = allFreeChannels.length || 0;
    const allStandardChannelAmount = allStandardChannels.length || 0;

    const freeListWithListedAndUnderReview = allFreeChannels?.filter((e: Channel) => e.listStatus === EListStatus.LISTED || e.listStatus === EListStatus.UNDER_REVIEW)?.sort((a, b) => {
        if (a.listStatus === EListStatus.LISTED) {
            return -1;
        }
        if (b.listStatus === EListStatus.LISTED) {
            return 1;
        }
        return 0;
    });
    const standardListWithListedAndUnderReview = allStandardChannels?.filter((e: Channel) => e.listStatus === EListStatus.LISTED || e.listStatus === EListStatus.UNDER_REVIEW)?.sort((a, b) => {
        if (a.listStatus === EListStatus.LISTED) {
            return -1;
        }
        if (b.listStatus === EListStatus.LISTED) {
            return 1;
        }
        return 0;
    });

    //include under review channels
    const listedFreeChanneIds = freeListWithListedAndUnderReview?.map((e: Channel) => e.id);
    const listedStandardChanneIds = standardListWithListedAndUnderReview?.map((e: Channel) => e.id);

    const unListedFreeChannels = allFreeChannels.filter((e: Channel) => { return !listedFreeChanneIds?.includes(e.id); });
    const unListedStandardChannels = allStandardChannels.filter((e: Channel) => !listedStandardChanneIds?.includes(e.id));

    let maxSelectedAmount: number | null = null;
    if (modalSelectType === ChannelPayPlanTypeEnum.FREE) {
        if (freeChannelLimit === null) {
            maxSelectedAmount = allFreeChannelAmount - (listedStandardChanneIds?.length || 0);
        } else {
            maxSelectedAmount = (freeChannelLimit || 9) - (listedStandardChanneIds?.length || 0);
            if (maxSelectedAmount < 0) {
                maxSelectedAmount = 0;
            }
        }
    }
    if (modalSelectType === ChannelPayPlanTypeEnum.PAID) {
        //null mean unlimited
        if (paidChannelLimit === null) {
            maxSelectedAmount = null;
        } else {
            maxSelectedAmount = (paidChannelLimit || 0) - (listedStandardChanneIds?.length || 0);
            if (maxSelectedAmount < 0) {
                maxSelectedAmount = 0;
            }
        }
    }

    const currentPlanType = getPlanTypeByInfo(currentPlan);
    //diff with unListedFreeChannels and unListedStandardChannels
    const showAddFreeChannel = (freeListWithListedAndUnderReview?.length || 0) < (freeChannelLimit || allFreeChannelAmount || DEFAULT_CHANNEL_LISTING_LIMITATION.free);
    let showAddStandardChannel = false;
    if (paidChannelLimit === null) {
        showAddStandardChannel = (standardListWithListedAndUnderReview?.length || 0) < (allStandardChannelAmount || DEFAULT_CHANNEL_LISTING_LIMITATION.standard);
    } else {
        showAddStandardChannel = (standardListWithListedAndUnderReview?.length || 0) < (paidChannelLimit || 0);
    }

    let modalTitle = modalSelectType === ChannelPayPlanTypeEnum.PAID ? 'List on all standard channels' : 'List on all basic channels';
    if (currentPlanType === TSubscriptionType.GROWTH) {
        modalTitle = 'List on all basic and standard channels';
    }

    let displayStandardChannelList = allStandardChannels.filter((channel: Channel) => {
        return Object.entries(filterPramas).reduce((res, [k, v]) => {
            let result = false;
            const channelValue = (channel as any)[(filterFieldMap as any)[k]];
            if (!v || (v instanceof Array && v.length === 0)) {
                result = true;
            } else if (k === PAY_TYPE) {
                result = channelValue === v;
            } else if (channelValue) {
                try {
                    const tags = JSON.parse(channelValue);

                    if (v instanceof Array) {
                        result = !!v.find((selectedValue: string) => tags.includes(selectedValue));
                    } else {
                        result = tags.includes(v);
                    }
                } catch (e) {
                    console.error(e);
                    result = false;
                }
            }

            return result && res;
        }, true);
    });

    const showMore = displayStandardChannelList.length > 6 && !hasClickMoreStandar;

    if (!hasClickMoreStandar) {
        displayStandardChannelList = displayStandardChannelList.slice(0, 6);
    }

    const allListed = unListedFreeChannels?.length === 0 && unListedStandardChannels?.length === 0;

    // console.log({
    //     maxSelectedAmount,
    //     listedFreeChanneIds,
    //     listedStandardChanneIds,
    //     paidChannelLimit,
    //     freeChannelLimit,
    //     allFreeChannelAmount,
    //     allStandardChannelAmount,
    //     currentPlanType,
    // });

    return (
        <Spin tip="Loading" size="large" spinning={spinning}>
            <div className={s.wrapper}>
                <div className={s.inner}>
                    {contextHolder}
                    { showChannelManagementDelegationNotice && <ChannelMagementDelegationNotice /> }
                    <div className={s.channelBorad}>
                        {
                            allListed && (
                                <div className={s.allListed}>
                                    🎉 You’ve listed on all basic and standard channels.
                                    <span
                                        onClick={() => {
                                            switchToMychannel?.();
                                        }}
                                        className={s.check}
                                    >Check in My channels
                                    </span>
                                </div>
                            )
                        }

                        {
                            !allListed && currentPlanType === TSubscriptionType.FREE &&
                            <>
                                <p className={s.title}>
                                    Maximum your visibility and credibility with more channels
                                </p>
                                <p className={s.subtitle}>You have only 2 Basic channels in your plan.
                                    <span
                                        onClick={handleShowUpgardePlan}
                                        className={s.highlight}
                                    > Upgrade to unlock more channels
                                    </span>
                                </p>
                            </>
                        }
                        {
                            !allListed && currentPlanType === TSubscriptionType.ESSENTIAL &&
                            <>
                                <p className={s.title}>
                                    Maximum your visibility and credibility with more channels
                                </p>
                                <p className={s.subtitle}>You have 2 Basic channels and 6 standard channel in your plan.
                                    <span
                                        onClick={handleShowUpgardePlan}
                                        className={s.highlight}
                                    > Upgrade to unlock unlimited channels
                                    </span>
                                </p>
                            </>
                        }
                        {
                            !allListed && currentPlanType === TSubscriptionType.GROWTH &&
                            <>
                                <p className={s.title}>
                                    List on all available channels by one click
                                </p>
                                <p className={s.subtitle} style={{ marginTop: 8, marginBottom: 12 }}>As a Growth plan user, you can be listed on all standard channels by one click
                                </p>
                                <Button
                                    onClick={() => {
                                        setShowChannelSelectModal(true);
                                    }}
                                    style={{ borderRadius: 6 }}
                                    type="primary"
                                >List me on all standard channels
                                </Button>
                            </>
                        }
                        {
                            !allListed && currentPlanType !== TSubscriptionType.GROWTH &&
                            <div className={s.channelListWrap}>
                                <div className={s.label}>{`Listed basic channels (${freeListWithListedAndUnderReview ? freeListWithListedAndUnderReview.length : 0}/${freeChannelLimit === null ? allFreeChannelAmount : freeChannelLimit})`}</div>
                                <div className={`${s.value} ${s.channelLogos}`}>
                                    {
                                        freeListWithListedAndUnderReview?.map((channel: Channel) => {
                                            const isListed = channel.listStatus === EListStatus.LISTED;
                                            if (isListed) {
                                                return (
                                                    <Popover content={<span>Live</span>} trigger="hover" key={channel.id}>
                                                        <img className={isListed ? s.channelLogo : `${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                    </Popover>);
                                            }
                                            return (
                                                <Popover content={<span>Under review</span>} trigger="hover" key={channel.id}>
                                                    <img className={`${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                </Popover>);
                                        })
                                    }
                                    {
                                        showAddFreeChannel &&
                                        <span
                                            onClick={() => {
                                                setModalSelectType(ChannelPayPlanTypeEnum.FREE);
                                                setShowChannelSelectModal(true);
                                            }}
                                            className={s.addIcon}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                                                <rect x="1" y="0.5" width="49" height="49" rx="24.5" stroke="#9CA3AF" strokeDasharray="4 4" />
                                                <path d="M25.5 12.5V25M25.5 25V37.5M25.5 25H38M25.5 25L13 25" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    }
                                </div>

                                {
                                    currentPlanType === TSubscriptionType.ESSENTIAL &&
                                    <>
                                        <div className={s.label}>Listed standard channels {paidChannelLimit !== null ? ` (${standardListWithListedAndUnderReview ? standardListWithListedAndUnderReview.length : 0}/${paidChannelLimit})` : ''}</div>
                                        <div className={`${s.value} ${s.channelLogos}`}>
                                            {
                                                standardListWithListedAndUnderReview?.map((channel: Channel) => {
                                                    const isListed = channel.listStatus === EListStatus.LISTED;
                                                    if (isListed) {
                                                        return (
                                                            <Popover content={<span>Live</span>} trigger="hover" key={channel.id}>
                                                                <img className={isListed ? s.channelLogo : `${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                            </Popover>);
                                                    }
                                                    return (
                                                        <Popover content={<span>Under review</span>} trigger="hover" key={channel.id}>
                                                            <img className={`${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                        </Popover>);
                                                })
                                            }
                                            {
                                                showAddStandardChannel &&
                                                <span
                                                    onClick={() => {
                                                        setModalSelectType(ChannelPayPlanTypeEnum.PAID);
                                                        setShowChannelSelectModal(true);
                                                    }}
                                                    className={s.addIcon}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                                                        <rect x="1" y="0.5" width="49" height="49" rx="24.5" stroke="#9CA3AF" strokeDasharray="4 4" />
                                                        <path d="M25.5 12.5V25M25.5 25V37.5M25.5 25H38M25.5 25L13 25" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                    {
                        isFree &&
                        <div className={s.paidTip}>
                            <div className={s.starIcon} />
                            <div className={s.TipContent}>
                                <div className={s.tipText}>{freeTip}</div>
                                <a className={s.upgradeAction} onClick={handleShowUpgardePlan}>Upgrade plan</a>
                            </div>
                        </div>
                    }
                    <div className={s.header}>
                        <div className={s.title}>Standard Channels</div>
                        <div className={s.subTitle}>Apply your listing application to various channels and begin attracting patients through those connections.</div>
                    </div>
                    <div className={s.filterBar}>
                        <div className={s.filterItems}>
                            <div className={s.filter}>
                                <div className={s.label}>Channel focus</div>
                                <div className={s.input}>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        maxTagCount="responsive"
                                        className={s.filterSelect}
                                        options={channelFocusTypeOptions}
                                        value={filterPramas[PAYMENT_TYPE]}
                                        onChange={(e: string) => handleFilter(e, PAYMENT_TYPE)}
                                    />
                                </div>
                            </div>
                            <div className={s.filter}>
                                <div className={s.label}>Practice type</div>
                                <div className={s.input}>
                                    <Select className={s.filterSelect} options={practiceTypeOptions} value={filterPramas[PRACTICE_TYPE]} onChange={(e: string) => handleFilter(e, PRACTICE_TYPE)} />
                                </div>
                            </div>
                            <div className={s.clearFilter} onClick={handleClearFilter}>
                                Clear filter
                            </div>
                        </div>
                        <Dropdown.Button className={s.sortButton} menu={{ items: sortTypes, onClick: onOrderClick }}>Sort</Dropdown.Button>
                    </div>
                    <div className={s.body}>
                        <div className={s.rChannelWrap}>
                            {displayStandardChannelList?.map((item: Channel) => {
                                return (
                                    <div key={item.id} className={s.rChannelItem}>
                                        <ActiveChannelCard
                                            key={item.id}
                                            item={item}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {
                        showMore &&
                        <div className={s.more}>
                            <div className={s.moreIn}>
                                <div
                                    onClick={() => {
                                        setHasClickMoreStandar(true);
                                    }}
                                    className={s.moreInIn}
                                >
                                    More Standard channels
                                </div>
                            </div>
                        </div>
                    }
                    <div className={s.header} style={{ marginTop: 24 }}>
                        <div className={s.title}>Premium Channels</div>
                        <div className={s.subTitle}>Apply your listing application to various channels and begin attracting patients through those connections.</div>
                    </div>
                    <div className={s.body}>
                        <div className={s.rChannelWrap}>
                            {allPremiumChannels?.map((item: Channel) => {
                                return (
                                    <div key={item.id} className={s.rChannelItem}>
                                        <ActiveChannelCard
                                            key={item.id}
                                            item={item}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={s.body}>
                        <h3>Upcoming channels</h3>
                        <div className={s.subTitle}>Subscribe to notifications for upcoming channels of interest to stay informed and be first to apply, promoting your practice.</div>
                        <div className={s.uWrap}>
                            <div className={s.uChannelWrap}>
                                {upcomingPageData?.map((item: Channel) => {
                                    return (
                                        <div key={item.id} className={s.uChannelItem}>
                                            <UpcomingChannelCard
                                                key={item.id}
                                                item={item}
                                                onUpdateVote={handleUpdateVote}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={s.deviceMore}>
                                {
                                    upcomingChannelList?.data?.data && upcomingPageData.length < upcomingChannelList.data.data.length && <div className={s.moreButton} onClick={() => getUpcommingDataByPage(upcomingChannelList?.data?.data)}>More upcoming channels</div>
                                }
                            </div>
                        </div>
                        <h3>Channels of your interest</h3>
                        <div className={s.subTitle}>Didn&apos;t see your preferred channels listed above? Let us know which channels you prefer and we&apos;ll keep adding new options based on your feedback.</div>
                        <div className={s.interestForm}>
                            <Form
                                id="form"
                                form={interestForm}
                                name="newChannelForm"
                                className={commonS.formStyle1}
                                autoComplete="off"
                                layout="vertical"
                                onFinish={submitNewChannel}
                            >
                                <Form.Item
                                    label="Name of the channel"
                                    name="name"
                                    rules={[
                                        { required: true, message: 'The name of channel  is a required field' },
                                    ]}
                                >
                                    <Input className={s.interestInput} />
                                </Form.Item>
                                <Form.Item
                                    label="Website"
                                    name="website"
                                >
                                    <Input className={s.interestInput} />
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={loading} className={s.submitButton} type="primary" htmlType="submit">Submit</Button>
                                </Form.Item>
                            </Form>
                            {
                                showSubmitChannelSuccess &&
                                <div className={s.submitChannelSuccess}>
                                    <div className={s.rightIcon} />
                                    <div className={s.successTip}>Thank you for sharing with us! If you have any other channels you're interested in, please feel free to add them.</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <ChannelSelectModal
                    isOps={false}
                    title={modalTitle}
                    show={showChannelSelectModal}
                    channelList={modalSelectType === ChannelPayPlanTypeEnum.PAID ? unListedStandardChannels : unListedFreeChannels}
                    channelListWithCategory={currentPlanType === TSubscriptionType.GROWTH ? { 'Basic channels': unListedFreeChannels, 'Standard channels': unListedStandardChannels } : undefined}
                    onClose={() => {
                        setShowChannelSelectModal(false);
                    }}
                    onSubmit={() => {
                        setShowChannelSelectModal(false);
                        liveChannelRun();
                    }}
                    showTopNav={modalSelectType === ChannelPayPlanTypeEnum.PAID}
                    maxSelectedAmount={currentPlanType === TSubscriptionType.GROWTH ? null : maxSelectedAmount}
                />
            </div>
        </Spin>
    );
};

export default ChannelStorePage;
