import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Spin, message, Modal } from 'antd';
import s from './s.module.less';
import UpdateUniProfile from './components/UpdateUniProfile';
import Preview from './components/Preview';
import useGetPracticeFrontFormData from 'hooks/useGetPracticeFrontFormData';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { getUrlParam } from 'utils/common';
import { launchPF } from 'api/practiceFront';
import PracticeFrontPreviewStore from 'store/PracticeFrontPreview';
import EditStatusStore, { editStatusMap as initStatusMap } from 'store/PracticeFront/editStatus';
import { EditModuleEnum, IProviderStore } from 'types/practiceFront';
import { setPFUrl, setHasViewLocalSeoInfo, getHasViewLocalSeoInfo } from 'utils/localstore';
import commonS from 'styles/common.module.less';
import useLoadBasicData from 'hooks/useLoadBasicData';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { isFreeUser, shouldShowKlarityPlanUI } from 'utils/provider';
import Aboutmetemplate from './imgs/Aboutmetemplate.png';
import Seologo from './imgs/seologo.svg';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import { HTML_DIV_ID } from 'constants/common';
import ProviderStore from 'store/PracticeFront/provider';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import { ServerMeta } from 'types/common';
import { EPracticeFrontPreviewPage } from 'types/channel';

type Ref = {
    beforNext: (status: boolean, bookingLinkCheckedWithoutLinkValue: boolean) => void;
};

const Page = () => {
    const navigate = useNavigate();
    const [, setPreviewStore] = PracticeFrontPreviewStore.useStore();
    const [getProvider] = ProviderStore.useStore();
    const providerData = getProvider('data') as IProviderStore;
    const type = getUrlParam('type');
    const scrollTo = getUrlParam('scrollTo');
    const [, fetchLoading, refetch] = useGetPracticeFrontFormData();
    const [loading, setLoading] = useState(false);
    const [launchLoading, setLaunchLoading] = useState(false);
    const [homeInfo, homeInfoLoading] = useProviderHomeInfo();

    const [getEditStatus, setEditStatus] = EditStatusStore.useStore();
    const editStatusMap = getEditStatus('data');
    const gloablEditStatus = useMemo(() => !editStatusMap || !!Object.values(editStatusMap).find((e) => e), [editStatusMap]);

    const childRef = useRef<Ref>();

    const [loadBaiscData] = useLoadBasicData();

    const { useIntegrationUrl, appointmentIntegrationUrl } = providerData || {};

    const [subscriptionPlan, loadingSubscriptionPlan, refresh] = useSubscriptionPlan();
    const isFree = isFreeUser();
    const [iframeLoadSuccess, setIframeLoadSuccess] = useState(false);
    const [showLocalSeoModal, setShowLocalSeoModal] = useState(!getHasViewLocalSeoInfo());

    const showKlarityPlanUI = shouldShowKlarityPlanUI(homeInfo);

    React.useEffect(() => {
        setPreviewStore("currentPage", EPracticeFrontPreviewPage.HOME);
    }, [])

    const scrollToSeoModule = () => {
        try {
            const localSeoDiv = document.getElementById(HTML_DIV_ID.LOCAL_SEO_WRAP);
            if (localSeoDiv) {
                const top = localSeoDiv.offsetTop + 100;
                window.scrollTo({
                    top,
                    behavior: 'smooth',
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        loadBaiscData();
    }, [loadBaiscData]);

    useEffect(() => {
        setLoading(fetchLoading);
    }, [fetchLoading]);

    useEffect(() => {
        if (scrollTo === HTML_DIV_ID.LOCAL_SEO_WRAP) {
            setTimeout(() => {
                scrollToSeoModule();
            }, 1000);
        }
    }, [scrollTo]);

    useEffect(() => {
        return () => {
            const newStatusMap = Object.keys(initStatusMap).reduce((p, c) => {
                p[c as EditModuleEnum] = false;

                return p;
            }, {} as Record<EditModuleEnum, boolean>);
            setEditStatus('data', newStatusMap);
        };
    }, []);

    const handleExit = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`, { replace: true });
    }, [navigate]);

    // const handleNext = useCallback(() => {
    //     childRef.current?.beforNext(gloablEditStatus);
    //     if (gloablEditStatus) {
    //         message.error('Please save your changes before proceeding');
    //     } else {
    //         // navigate(`/${PATH.PRACTICE_FRONT_VOTE_PAGE}`);
    //         navigate(`/${PATH.RECOMMEND_CHANNELS}`);
    //     }
    // }, [gloablEditStatus, navigate]);

    const handleNext = useCallback(async () => {
        if (gloablEditStatus) {
            message.error('Please save your changes before proceeding');
        } else {
            setLaunchLoading(true);
            const res = await launchPF();

            setLaunchLoading(false);
            if (!res?.error) {
                setPFUrl(res.data?.data);
                // navigate(`/${PATH.PRACTICE_FRONT_CREATE_SUCCESS}`);

                if (showKlarityPlanUI) {
                    navigate(`/${PATH.PRACTICE_FRONT_CREATE_SUCCESS}`);
                } else {
                    navigate(`/${PATH.RECOMMEND_CHANNELS}`);
                }
            } else {
                message.error(res.error);
            }
        }
    }, [gloablEditStatus, navigate, showKlarityPlanUI]);

    const handleSave = useCallback(() => {
        childRef.current?.beforNext(gloablEditStatus);
        if (gloablEditStatus) {
            message.error('Please save your changes before proceeding');
        } else {
            navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`);
        }
    }, [gloablEditStatus, navigate]);

    const handleLaunch = useCallback(async () => {
        const bookingLinkCheckedWithoutLinkValue = !!(useIntegrationUrl && !appointmentIntegrationUrl);
        childRef.current?.beforNext(gloablEditStatus, bookingLinkCheckedWithoutLinkValue);
        if (bookingLinkCheckedWithoutLinkValue) {
            message.error('Before launching your Practice Front, Please uncheck the "I want to use Direct booking link" checkbox without the booking link');
        } else if (gloablEditStatus) {
            message.error('Please save your changes before proceeding');
        } else {
            setLaunchLoading(true);
            const res = await launchPF();
            setLaunchLoading(false);
            if (!res?.error) {
                setPFUrl(res.data?.data);
                navigate(`/${PATH.RECOMMEND_CHANNELS}`);
            } else {
                message.error(res.error);
            }
        }
    }, [appointmentIntegrationUrl, gloablEditStatus, navigate, useIntegrationUrl]);

    const handleCheckItOut = () => {
        setShowLocalSeoModal(false);
        setHasViewLocalSeoInfo('true');
        scrollToSeoModule();
    };
    return (
        <Spin spinning={!!(loading || launchLoading || loadingSubscriptionPlan || homeInfoLoading)}>
            <div className={s.wrap}>
                <div className={s.left}>
                    <UpdateUniProfile isFree={isFree && !showKlarityPlanUI} innerId="pf-left" ref={childRef} refetch={refetch} setLoading={setLoading} isKlarityUser={showKlarityPlanUI} />
                </div>
                <div className={s.right}>
                    <Preview onLoad={setIframeLoadSuccess} />
                </div>
                <div className={s.footer}>
                    {
                        type === 'edit' ? <Button type="primary" ghost onClick={handleExit}>Exit</Button> : <div />
                    }
                    <div className={s.btns}>
                        {
                            type === 'edit' ?
                                <>
                                    <Button type="primary" ghost onClick={handleSave}>Save without launching</Button>
                                    <Button type="primary" loading={launchLoading} onClick={handleLaunch}>Launch my Practice Front </Button>
                                </> :
                                <Button type="primary" onClick={handleNext}>Next</Button>
                        }
                    </div>
                </div>
            </div>
            <Modal
                open={!shouldShowKlarityPlanUI && showLocalSeoModal}
                onCancel={() => {
                    setShowLocalSeoModal(false);
                    setHasViewLocalSeoInfo('true');
                }}
                footer={null}
                width={960}
                className={commonS.modalFixHeightWrap}
            >
                <div className={s.seoWrap}>
                    <div className={s.left}>
                        <img src={Aboutmetemplate} alt="" />
                    </div>
                    <div className={s.right}>
                        <h2>
                            <img src={Seologo} alt="" />
                            <div className={s.info}>
                                <p className={s.title}>
                                    LocalSEO+
                                </p>
                                <p className={s.subTitle}>
                                    Created by Kiwi Health
                                </p>
                            </div>
                        </h2>
                        <div className={s.label}>
                            New App
                        </div>
                        <h3>Use medical SEO to maximize your reach both physically and virtually</h3>
                        <p className={s.des}>
                            With an automated geo-targeting function,
                            Kiwi Health uses the advanced algorithms to automatically identify and include locations near your practice,
                            including specific ZIP codes and local interests. This will improve the SEO and online visibility for your practice,
                            so you can grow your practice effortlessly.
                        </p>
                        {isFree &&
                            <p className={s.btn}>
                                <Button
                                    size="large"
                                    type="primary"
                                    onClick={() => {
                                        openSubscriptionModal();
                                    }}
                                    block
                                >
                                    Upgrade to add
                                </Button>
                            </p>
                        }
                        {!isFree && (
                            <p className={s.btn}><Button onClick={handleCheckItOut} size="large" type="primary" block>Check it out</Button></p>
                        )}

                        {isFree && (
                            <p className={s.btn}><Button onClick={handleCheckItOut} size="large" block>Try it</Button></p>
                        )}
                    </div>
                </div>

            </Modal>
        </Spin>
    );
};

export default Page;
