export enum BillingFormFieldEnum {
    BANK_NAME = 'bankName',
    BANK_ROUTING_NUMBER = 'bankRoutingNumber',
    ACCOUNT_HOLDER = 'accountHolder',
    ACCOUNT_NUMBER = 'accountNumber',
    BILLING_ADDRESS = 'address',
    ZIP_CODE = 'zip',
    STATE = 'state',
    CHANNEL = 'channel',
}

export interface BillingType {
    [BillingFormFieldEnum.BANK_NAME]: string
    [BillingFormFieldEnum.BANK_ROUTING_NUMBER]: string
    [BillingFormFieldEnum.ACCOUNT_HOLDER]: string
    [BillingFormFieldEnum.ACCOUNT_NUMBER]: string
    [BillingFormFieldEnum.BILLING_ADDRESS]: string
    [BillingFormFieldEnum.ZIP_CODE] : string
    [BillingFormFieldEnum.STATE] : string
    [BillingFormFieldEnum.CHANNEL]: string[]
}
