import React from 'react';
import s from './s.module.less';
import dayjs from 'dayjs';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Event, ReminderOverrideMethod } from 'types/calendar';

import { Button, Radio, Spin, Popover } from 'antd';
import { getMonthDaysMatrix, getWeekDaysArray, getTimeStr, getDateKey } from 'utils/calendar';
import GoogleStore from 'store/Google';
import { calendarEventFetch, calendarEventCreate, calendarColorFetch } from 'api/googleNative';
import { get } from 'utils/googleClient';
import { useNavigate } from 'react-router-dom';
import Month from './components/Month';
import Week from './components/Week';
import Day from './components/Day';
import AddAppointmentModal from 'components/AddAppointmentModal';
import AddEventModal from './components/AddEventModal';
import AddBlocktimeModal from './components/AddBlocktimeModal';
import EditAppointmentModal from './components/EditCommonEventModal';
import { PATH } from 'constants/path';
import { formatDate } from 'utils/common';

enum EMode {
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day',
}

const Page = () => {
    const [getGoogle] = GoogleStore.useStore();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const googleClientReady = getGoogle('clientReady');
    const calendarReady = getGoogle('calendarReady');
    const token = getGoogle('token');
    const tokenExpiredAt = getGoogle('expiredAt') || 0;
    const [showAddEvent, setShowAddEvent] = React.useState(false);
    const [showAddAppointment, setShowAddAppointment] = React.useState(false);
    const [showAddBlocktime, setShowAddBlocktime] = React.useState(false);
    const [showEditAppointment, setShowEditAppointment] = React.useState(false);
    const [currentEvent, setCurrentEvent] = React.useState<Event | undefined>(undefined);
    React.useEffect(() => {
        if (googleClientReady && calendarReady) {
            const now = (new Date()).getTime();
            if (now > tokenExpiredAt || !token) {
                //need trigger from user?
                //apiCalendar.tokenClient.requestAccessToken({ prompt: 'consent' });
            }
        }
    }, [googleClientReady, calendarReady]);

    const [mode, setMode] = React.useState<EMode>(EMode.WEEK);
    const [monthIndex, setMonthIndex] = React.useState(dayjs().month());
    const [date, setDate] = React.useState(dayjs().date());
    const [currentDate, setCurrentDate] = React.useState<dayjs.Dayjs>(dayjs()); // not index
    const [currenMonthDaysMatrix, setCurrentMonthDaysMatrix] = React.useState(getMonthDaysMatrix(monthIndex));
    const [currenWeekDaysArray, setCurrenWeekDaysArray] = React.useState(getWeekDaysArray(date));
    const [allEvents, setAllEvents] = React.useState<Event[]>([]);

    // const endTime = dayjs('2023-02-01T00:00:00+08:00');
    // console.log('--------');
    // console.log('2023-02-01T00:00:00+08:00');
    // console.log(endTime.hour());
    // console.log(endTime.minute());
    // console.log(formatDate(endTime.hour(1).minute(0), 'YYYY-MM-DDThh:mm:ssZ'));

    const createContent = (
        <div className={s.createWrap}>
            <Button
                type="text"
                onClick={() => {
                    setShowAddAppointment(true);
                }}
                block
            >Appointments
            </Button>
            <Button
                type="text"
                onClick={() => {
                    setShowAddEvent(true);
                }}
                block
            >Events
            </Button>
            <Button
                type="text"
                onClick={() => {
                    setShowAddBlocktime(true);
                }}
                block
            >Block time
            </Button>
        </div>
    );

    const dateEventMap = React.useMemo(() => {
        const dateMap:Record<string, Event[]> = {};
        for (let i = 0; i < allEvents.length; i++) {
            const event = allEvents[i];
            const startDay = getDateKey(dayjs(new Date(event.start?.dateTime)));
            const endDay = getDateKey(dayjs(new Date(event.end?.dateTime)));
            const endTime = dayjs(new Date(event.end?.dateTime));
            if (startDay !== endDay) {
                if (dayjs(new Date(event.end?.dateTime)).date() - dayjs(new Date(event.start?.dateTime)).date() > 1) {
                    //do not consider mutiple day event
                    continue;
                }
            }
            if (!dateMap[startDay]) {
                dateMap[startDay] = [];
            }
            dateMap[startDay].push(event);
            if (startDay !== endDay && endTime.hour() !== 0 && endTime.minute() !== 0) {
                if (!dateMap[endDay]) {
                    dateMap[endDay] = [];
                }
                dateMap[endDay].push({
                    ...event,
                    start: {
                        //2023-02-01T00:00:00+08:00
                        dateTime: formatDate(endTime.hour(0).minute(0), 'YYYY-MM-DDTZhh:mm:ssZ'),
                        timeZone: event.start.timeZone,
                    },
                });
            }
        }
        return dateMap;
    }, [allEvents]);

    const fetchEvents = (start:string, end:string) => {
        setLoading(true);
        calendarEventFetch({
            maxResults: 100,
            orderBy: 'startTime', //must put orderBy and singleEvents
            singleEvents: true,
            timeMin: start,
            timeMax: end,
        }).then(({ data, error }: { data:{ items:Event[] }, error:unknown }) => {
            if (!error) {
                setAllEvents(data.items);
            }
            setLoading(false);
        });
    };

    React.useEffect(() => {
        setCurrentMonthDaysMatrix(getMonthDaysMatrix(monthIndex));
    }, [monthIndex]);

    React.useEffect(() => {
        setCurrenWeekDaysArray(getWeekDaysArray(date));
    }, [date]);

    React.useEffect(() => {
        if (!googleClientReady || !calendarReady) {
            return;
        }
        const targetDate = mode === EMode.MONTH ? dayjs(new Date(dayjs().year(), monthIndex, 1)) : dayjs(new Date(dayjs().year(), dayjs().month(), date));
        const timeMin = getTimeStr(targetDate.add(-1, 'month'));
        const timeMax = getTimeStr(targetDate.add(1, 'month'));
        fetchEvents(timeMin, timeMax);
    }, [googleClientReady, calendarReady, monthIndex, date, mode, token]);

    const onNext = () => {
        if (mode === EMode.MONTH) {
            setMonthIndex(monthIndex + 1);
        }
        if (mode === EMode.WEEK) {
            setDate(date + 7);
        }
        if (mode === EMode.DAY) {
            setCurrentDate(currentDate.add(1, 'day'));
        }
    };

    const onPrevious = () => {
        if (mode === EMode.MONTH) {
            setMonthIndex(monthIndex - 1);
        }
        if (mode === EMode.WEEK) {
            setDate(date - 7);
        }
        if (mode === EMode.DAY) {
            setCurrentDate(currentDate.add(-1, 'day'));
        }
    };

    const onToday = () => {
        setMonthIndex(dayjs().month());
        setDate(dayjs().date());
        setCurrentDate(dayjs());
    };

    const onRefresh = () => {
        const targetDate = mode === EMode.MONTH ? dayjs(new Date(dayjs().year(), monthIndex, 1)) : dayjs(new Date(dayjs().year(), dayjs().month(), date));
        const timeMin = getTimeStr(targetDate.add(-1, 'month'));
        const timeMax = getTimeStr(targetDate.add(1, 'month'));
        fetchEvents(timeMin, timeMax);
    };

    const onSetting = () => {
        navigate(`${PATH.DASHBOARD}/${PATH.APPOINTMENT_SETTING}`);
    };

    const onEvent = (event:Event) => {
        console.log({ event });
        setCurrentEvent(event);
        setShowEditAppointment(true);
    };

    let yearDisplay = dayjs(new Date(dayjs().year(), monthIndex)).format(
        'MMMM YYYY',
    );
    if (mode === EMode.WEEK) {
        yearDisplay = dayjs(new Date(dayjs().year(), dayjs().month(), date)).format(
            'MMMM YYYY',
        );
    }

    return (
        <div className={s.wrap}>
            <div className={s.clWrap}>
                <div className={s.header}>
                    <div className={s.left}>
                        <h1>Appointment</h1>
                        <div className={s.tip}>
                            Manage all your appointment from different channels
                        </div>
                    </div>
                    <div className={s.right}>
                        <Button
                            type="primary"
                            onClick={onSetting}
                        >
                            Setting
                        </Button>
                    </div>
                </div>
                <div className={s.calendarHeader}>
                    <div className={s.left}>
                        <span className={s.date}>
                            {yearDisplay}
                        </span>
                        <span
                            className={s.nav}
                            onClick={onPrevious}
                        >
                            <DoubleLeftOutlined />
                        </span>
                        <span
                            onClick={onNext}
                            className={s.nav}
                        >
                            <DoubleRightOutlined />
                        </span>
                        <span
                            className={s.today}
                            onClick={onToday}
                        >
                            Today
                        </span>

                    </div>
                    <div className={s.mid}>
                        <Radio.Group
                            onChange={(e) => {
                                if (e.target.value === mode) {
                                    return;
                                }
                                onToday();
                                setMode(e.target.value);
                            }}
                            value={mode}
                            buttonStyle="solid"
                        >
                            <Radio.Button value={EMode.DAY}>Day</Radio.Button>
                            <Radio.Button value={EMode.WEEK}>Week</Radio.Button>
                            <Radio.Button value={EMode.MONTH}>Month</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className={s.right}>
                        <Popover placement="bottomLeft" title={null} content={createContent} trigger="hover">
                            + Create
                        </Popover>
                        <span
                            onClick={onRefresh}
                        >
                            Refresh
                        </span>
                    </div>
                </div>
                <Spin spinning={loading}>
                    <div className={s.loading} />
                </Spin>
                <div className={s.monthWrap}>
                    {mode === EMode.DAY &&
                    <Day
                        onEventClick={onEvent}
                        day={currentDate}
                        events={dateEventMap[getDateKey(currentDate)] || []}
                    />}
                    {mode === EMode.MONTH &&
                    <Month
                        onEventClick={onEvent}
                        dateEventMap={dateEventMap}
                        monthDaysMatrix={currenMonthDaysMatrix}
                    />}
                    {mode === EMode.WEEK &&
                    <Week
                        onEventClick={onEvent}
                        dateEventMap={dateEventMap}
                        weekDaysArray={currenWeekDaysArray}
                    />}
                </div>

            </div>
            <div className={s.actions}>
                <div>
                    <Button
                        type="primary"
                        onClick={async () => {
                            const client = get();
                            client.requestAccessToken({ prompt: 'consent' });
                        }}
                    >
                        Auth
                    </Button>
                    <Button
                        onClick={() => {

                        }}
                    >
                        Logout
                    </Button>
                </div>
                <ul>
                    <li
                        onClick={() => {

                        }}
                    >
                        <Button>List upcoming event</Button>
                    </li>
                    <li
                        onClick={() => {
                            const event:Partial<Event> = {
                                summary: 'Test Event -Xiaocong',
                                location: '800 Howard St., San Francisco, CA 94103',
                                description: 'A chance to hear more about Google\'s developer products.',
                                start: {
                                    dateTime: '2023-01-05T09:00:00-07:00',
                                    timeZone: 'America/Los_Angeles',
                                },
                                end: {
                                    dateTime: '2023-01-05T17:00:00-07:00',
                                    timeZone: 'America/Los_Angeles',
                                },
                                recurrence: [
                                    'RRULE:FREQ=DAILY;COUNT=2',
                                ],
                                attendees: [
                                    { email: 'youming.tang@helloklarity.com' },
                                    { email: 'xiaocong.liu@helloklarity.com' },
                                ],
                                reminders: {
                                    useDefault: false,
                                    overrides: [
                                        { method: ReminderOverrideMethod.EMAIL, minutes: 24 * 60 },
                                        { method: ReminderOverrideMethod.POPUP, minutes: 10 },
                                    ],
                                },
                            };
                            calendarEventCreate(event).then(({ result }: any) => {
                                console.log(result);
                            });
                        }}
                    >
                        <Button>Insert event</Button>
                    </li>
                    <li
                        onClick={() => {
                            // const eventId = '1nd03ip0b8ivid31haqeeh82pg';
                            // apiCalendar.deleteEvent(eventId).then(({ result }: any) => {
                            //     console.log(result);
                            // });
                        }}
                    >
                        <Button>Delete event</Button>
                    </li>
                    <li
                        onClick={() => {
                            calendarColorFetch().then((result:any) => {
                                console.log({ result });
                            });
                        }}
                    >
                        <Button>Get color</Button>
                    </li>
                </ul>
            </div>
            {
                showAddAppointment && <AddAppointmentModal onCancel={() => { setShowAddAppointment(false); }} />
            }
            {
                showAddEvent && <AddEventModal onCancel={() => { setShowAddEvent(false); }} />
            }
            {
                showAddBlocktime && <AddBlocktimeModal onCancel={() => { setShowAddBlocktime(false); }} />
            }
            {
                showEditAppointment &&
                <EditAppointmentModal
                    event={currentEvent}
                    onCancel={() => {
                        setCurrentEvent(undefined);
                        setShowEditAppointment(false); }}
                />
            }
        </div>
    );
};

export default Page;
