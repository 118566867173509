// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--RZ9r1 {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.s-module__wrap--RZ9r1 img {
  margin-bottom: 32px;
  width: 380px;
  height: 380px;
}
.s-module__wrap--RZ9r1 .s-module__title--k4a_v {
  margin-bottom: 20px;
  color: var(--gray-900);
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;
}
.s-module__wrap--RZ9r1 p {
  width: 768px;
  margin-bottom: 32px;
  color: var(--gray-500);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ChannelMainPage/components/ChannelMagementDelegationNotice/components/Success/s.module.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AALA;EAOQ,mBAAA;EACA,YAAA;EACA,aAAA;AACR;AAVA;EAaQ,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAR;AApBA;EAwBQ,YAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AADR","sourcesContent":[".wrap {\n    padding-top: 16px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    img {\n        margin-bottom: 32px;\n        width: 380px;\n        height: 380px;\n    }\n\n    .title {\n        margin-bottom: 20px;\n        color: var(--gray-900);\n        text-align: center;\n        font-family: Inter;\n        font-size: 32px;\n        font-style: normal;\n        font-weight: 800;\n        line-height: 60px;\n    }\n\n    p {\n        width: 768px;\n        margin-bottom: 32px;\n        color: var(--gray-500);\n        text-align: center;\n        font-family: Inter;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 24px;\n        letter-spacing: 0.16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--RZ9r1`,
	"title": `s-module__title--k4a_v`
};
export default ___CSS_LOADER_EXPORT___;
