// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--_caKS {
  min-height: 100vh;
  padding-bottom: 56px;
}
.s-module__wrap--_caKS .ant-form-item-required::before {
  display: none !important;
}
.s-module__wrap--_caKS .s-module__row--mqao6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.s-module__wrap--_caKS .s-module__row--mqao6 .s-module__displayItem--yB6rP {
  flex: 1;
  margin-bottom: 12px;
}
.s-module__wrap--_caKS .s-module__row--mqao6 .s-module__displayItem--yB6rP .s-module__tips--mZ48m {
  padding: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--gray-900);
}
.s-module__wrap--_caKS .s-module__row--mqao6 .s-module__displayItem--yB6rP .s-module__tips--mZ48m img {
  flex: 0 0 20px;
  height: 20px;
}
.s-module__wrap--_caKS .s-module__row--mqao6 .s-module__displayItem--yB6rP .s-module__tips--mZ48m p {
  max-width: 100%;
}
.s-module__wrap--_caKS .s-module__row--mqao6 .s-module__displayItem--yB6rP .s-module__tips--mZ48m span {
  color: var(--primary-color);
  cursor: pointer;
}
.s-module__wrap--_caKS .s-module__row--mqao6 .s-module__displayItem--yB6rP .s-module__label--BHfM5 {
  margin-bottom: 4px;
  color: #6b7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__wrap--_caKS .s-module__row--mqao6 .s-module__displayItem--yB6rP .s-module__subLabel--uJXR_ {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-500);
}
.s-module__wrap--_caKS .s-module__row--mqao6 .s-module__displayItem--yB6rP .s-module__value--u79kW {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-900);
}
.s-module__wrap--_caKS .s-module__row--mqao6 .s-module__displayItem--yB6rP .s-module__textarea--Ty_45 {
  height: 188px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentSettingPage/components/BillingPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;AACF;AAHA;EAMM,wBAAA;AAAN;AANA;EAWI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAFJ;AAZA;EAgBM,OAAA;EA6CA,mBAAA;AA7CN;AAhBA;EAkBQ,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACR;AAxBA;EA0BU,cAAA;EACA,YAAA;AACV;AA5BA;EA+BU,eAAA;AAAV;AA/BA;EAmCU,2BAAA;EACA,eAAA;AADV;AAnCA;EAwCQ,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AAFR;AAzCA;EA8CQ,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAFR;AAhDA;EAqDQ,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAFR;AAtDA;EA2DQ,aAAA;AAFR","sourcesContent":[".wrap {\n  min-height: 100vh;\n  padding-bottom: 56px;\n\n  :global {\n    .ant-form-item-required::before {\n      display: none !important;\n    }\n  }\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 16px;\n    .displayItem {\n      flex: 1;\n      .tips {\n        padding: 16px;\n        margin-bottom: 16px;\n        font-weight: 500;\n        font-size: 14px;\n        line-height: 24px;\n        color: var(--gray-900);\n\n        img {\n          flex: 0 0 20px;\n          height: 20px;\n        }\n\n        p {\n          max-width: 100%;\n        }\n\n        span {\n          color: var(--primary-color);\n          cursor: pointer;\n        }\n      }\n      .label {\n        margin-bottom: 4px;\n        color: #6b7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n      .subLabel {\n        margin-bottom: 8px;\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 20px;\n        color: var(--gray-500);\n      }\n      .value {\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 20px;\n        color: var(--gray-900);\n      }\n      .textarea {\n        height: 188px;\n      }\n      margin-bottom: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--_caKS`,
	"row": `s-module__row--mqao6`,
	"displayItem": `s-module__displayItem--yB6rP`,
	"tips": `s-module__tips--mZ48m`,
	"label": `s-module__label--BHfM5`,
	"subLabel": `s-module__subLabel--uJXR_`,
	"value": `s-module__value--u79kW`,
	"textarea": `s-module__textarea--Ty_45`
};
export default ___CSS_LOADER_EXPORT___;
