import React, { useCallback } from 'react';
import s from './s.module.less';
import ChannelNotFoundSVG from 'assets/channel/channel-notfound.svg';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

const ChannelNotFound = () => {
    const navigate = useNavigate();

    const handleBackToChannels = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL}`);
    }, [navigate]);

    return (
        <div className={s.wrap}>
            <div className={s.box}>
                <img src={ChannelNotFoundSVG} />
                <div className={s.tipText}>Sorry we cannot find that channel</div>
                <Button type="default" onClick={handleBackToChannels}>Back to Channels</Button>
            </div>
        </div>
    );
};

export default ChannelNotFound;
