import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { PATH } from 'constants/path';
import s from './s.module.less';
import LandingIcon from 'assets/channel/landing.png';
import InternalIcon from 'assets/channel/internal.svg';
import lightingIcon from 'assets/channel/lighting.svg';
import ScaleIcon from 'assets/channel/scale.svg';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';

const Page = () => {
    const navigate = useNavigate();
    const [providerHomeInfo, homeInfoLoading] = useProviderHomeInfo();

    const handleClick = () => {
        if (!providerHomeInfo?.uniprofileSubmitted) {
            navigate(`${PATH.CREATE_UNI_PROFILE}`);
        } else if (providerHomeInfo && providerHomeInfo.klarityUserWithoutKiwiPlan && !providerHomeInfo.klarityStarterSetUpCompleted) {
            navigate(`/${PATH.KLARITY_PLAN_PROCESS}`);
        } else {
            navigate(`/${PATH.CHANNEL_SURVEY}`);
        }
    };

    return (
        <Spin spinning={homeInfoLoading}>
            <div className={s.wrap}>

                <div className={s.header}>
                    <h1>Grow your practice with Kiwi's Channel listings</h1>
                    <div className={s.des}>
                        Enhance your practice's visibility and patient reach with Kiwi Health’s Channel Listings. One click is all it takes to feature your services on over 30+ online medical directories.
                    </div>
                </div>
                <div className={s.content}>
                    <div className={s.left}>
                        <div className={s.list}>
                            <div className={s.item}>
                                <img src={InternalIcon} />
                                <div className={s.info}>
                                    <div className={s.itemTitle}>One-click listing</div>
                                    <div className={s.itemContent}>Create your UniProfile and instantly publish it across top medical directories like WebMD and Google with a single click, amplifying your online presence.</div>
                                </div>
                            </div>
                            <div className={s.item}>
                                <img src={ScaleIcon} />
                                <div className={s.info}>
                                    <div className={s.itemTitle}>Boosted visibility and credibility</div>
                                    <div className={s.itemContent}>Strengthen your reputation and improve SEO as Kiwi’s listings place your practice in front of more patients, building trust with each channel.</div>
                                </div>
                            </div>
                            <div className={s.item}>
                                <img src={lightingIcon} />
                                <div className={s.info}>
                                    <div className={s.itemTitle}>Effortless maintenance</div>
                                    <div className={s.itemContent}>Update your practice details once, and Kiwi syncs the changes everywhere, saving you time while ensuring accuracy across all listings.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.right}>
                        <img src={LandingIcon} />
                    </div>
                </div>
                <div className={s.footer}>
                    <Button
                        type="primary"
                        onClick={handleClick}
                    >Get started
                    </Button>
                </div>
            </div>
        </Spin>
    );
};

export default Page;
