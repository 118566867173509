import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { Spin, Button, Checkbox } from 'antd';
import s from './s.module.less';
import { insertUrlParam, getUrlParam } from 'utils/common';
import CreateAccountIcon from 'assets/channel/createAccount.svg';
import navigateUtils from 'utils/navigateUtils';
import useLiveChannels from 'hooks/useLiveChannels';
import useSettings from 'hooks/useSettings';
import { ChannelItem, ChannelItemBasic, Meta } from 'types/common';
import { surveyCareMost, surveyChannelUsing, surveyComplete } from 'api/provider';
import { EnumFields } from 'types/enumerationData';
import { setSkipChannelCare } from 'utils/sessionStorage';
import providerStatusStore from 'store/ProviderStatus';
import { checkHasSubmit } from 'utils/provider';
import useGetUniprofileEntry from 'hooks/useGetUniprofileEntry';
// import { filterAvailableChannel } from 'utils/channel';

enum EStep {
    SELECT_USING = 'SELECT_USING',
    MOST_CARE = 'MOST_CARE',
    COMPLETE_UNI_PROFILE = 'COMPLETE_UNI_PROFILE',
}

const URL_KEY = 'step';

const Page = () => {
    const [step, setStep] = React.useState<EStep | undefined>(getUrlParam(URL_KEY) as EStep || EStep.SELECT_USING);
    const [submiting, setSubmiting] = React.useState(false);
    const selectUsingMapRef = React.useRef<Record<string, boolean>>({});
    const selectUsingMap = selectUsingMapRef.current;
    const selectMostCareMapRef = React.useRef<Record<string, boolean>>({});
    const selectMostCareMap = selectMostCareMapRef.current;
    const [liveChannels, liveChannelsLoading] = useLiveChannels();
    const [setting, loading] = useSettings();
    const careMostOption = setting[EnumFields.LIST_CARE] || [];
    const [,forceUpdate] = React.useState({});
    const navigate = useNavigate();
    const [getProviderStatus] = providerStatusStore.useStore();
    const providerStatus = getProviderStatus('data');
    const [uniprofileEntryPath] = useGetUniprofileEntry();
    // const liveChannels = useMemo(() => filterAvailableChannel(liveChannelsData), [liveChannelsData]);

    const hasSubmit = checkHasSubmit(providerStatus?.profileStatus);

    const onClickExit = () => {
        setSkipChannelCare(true);
        navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL}`);
    };

    const onClickBack = () => {
        if (step === EStep.MOST_CARE) {
            setStep(EStep.SELECT_USING);
            insertUrlParam(URL_KEY, EStep.SELECT_USING);
        }
        if (step === EStep.COMPLETE_UNI_PROFILE) {
            setStep(EStep.MOST_CARE);
            insertUrlParam(URL_KEY, EStep.MOST_CARE);
        }
    };

    //liveChannels?.filter((item:ChannelItem) => { return !!item.top; });
    let displayChannel:ChannelItem[] = liveChannels || [];
    if (!displayChannel || displayChannel.length === 0) {
        displayChannel = liveChannels || [];
    }

    const onClickNext = async () => {
        if (step === EStep.SELECT_USING) {
            setSubmiting(true);
            const result = await surveyChannelUsing(Object.keys(selectUsingMap).filter((key) => selectUsingMap[key]).map((val) => { return parseInt(val.replace('_', ''), 10); }));
            setSubmiting(false);
            if (!result.error) {
                insertUrlParam(URL_KEY, EStep.MOST_CARE);
                setStep(EStep.MOST_CARE);
            }
        }
        if (step === EStep.MOST_CARE) {
            setSubmiting(true);
            const result = await surveyCareMost(Object.keys(selectMostCareMap).filter((key) => selectMostCareMap[key]));
            setSubmiting(false);
            if (!result.error) {
                const completeResult = await surveyComplete();
                if (!completeResult.error) {
                    if (hasSubmit) {
                        navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL}`);
                    } else {
                        navigateUtils.openCreateUniProfilePage(uniprofileEntryPath, true);
                    }
                }
            }
            // insertUrlParam(URL_KEY, EStep.COMPLETE_UNI_PROFILE);
            // setStep(EStep.COMPLETE_UNI_PROFILE);
        }
        if (step === EStep.COMPLETE_UNI_PROFILE) {
            navigateUtils.openCreateUniProfilePage(uniprofileEntryPath, true);
        }
    };

    return (
        <div className={s.wrap}>
            <Spin spinning={!step || liveChannelsLoading || loading}>
                {
                    step === EStep.SELECT_USING &&
                    <div className={s.content}>
                        <h2>What marketing channels are you currently utilizing to reach new patients?</h2>
                        <div className={s.channelList}>
                            {
                                displayChannel.map((channel:ChannelItemBasic) => {
                                    const isChecked = !!selectUsingMap[`_${channel.id}`];
                                    const imgSrc = channel.rectangleLogo || channel.logo;
                                    return (
                                        <div
                                            key={channel.id}
                                            onClick={() => {
                                                selectUsingMap[`_${channel.id}`] = !selectUsingMap[`_${channel.id}`];
                                                forceUpdate({});
                                            }}
                                            className={isChecked ? `${s.item} ${s.itemCheck}` : s.item}
                                        >
                                            <img src={imgSrc} />
                                            {/* <div className={s.check}>
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={(e) => {
                                                        const { checked } = e.target;
                                                        selectUsingMap[`_${channel.id}`] = !!checked;
                                                        forceUpdate({});
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </div> */}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }
                {
                    step === EStep.MOST_CARE &&
                    <div className={s.content}>
                        <h2>What do you care the most for selecting channels</h2>
                        <div className={s.careSelect}>
                            {
                                careMostOption.map((mostCare:Meta) => {
                                    return (
                                        <div>
                                            <Checkbox onChange={(e) => {
                                                const { checked } = e.target;
                                                selectMostCareMap[mostCare.value!] = !!checked;
                                                forceUpdate({});
                                                e.stopPropagation();
                                            }}
                                            >
                                                {mostCare.label}
                                            </Checkbox>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }
                {
                    step === EStep.COMPLETE_UNI_PROFILE &&
                    <div className={s.content}>
                        <h2>Let’s complete your UniProfile</h2>
                        <div className={s.des}>It takes about 15 mins and what’ you have been filled out in onboarding process are</div>
                        <div className={s.contentInner}>
                            <div className={s.left}>
                                <img src={CreateAccountIcon} />
                            </div>
                            <div className={s.right}>
                                <p className={s.contentTitle}>Simplify your healthcare marketing with UniProfile</p>
                                <p className={s.contentTitle}> - one profile, endless possibilities.</p>
                                <p className={s.contentText}>
                                    With a single, unified profile that can be easily listed across multiple marketing channels,
                                    UniProfile simplifies your online presence and maximizes your reach.
                                    Join the UniProfile community today and take the first step towards growing your practice with ease.
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </Spin>
            <div className={s.footer}>
                <div className={s.footerInner}>
                    <div>
                        {step === EStep.SELECT_USING ? <span /> : <span className={s.back} onClick={onClickBack}>Back</span>}
                    </div>
                    {
                        hasSubmit ?
                            <div>
                                <Button loading={submiting} type="primary" onClick={onClickNext}>
                                    {step === EStep.MOST_CARE ? 'Complete' : 'Next'}
                                </Button>
                            </div> :
                            <div>
                                <Button type="text" onClick={onClickExit} style={{ marginRight: 24 }}>Skip for now</Button>
                                <Button loading={submiting} type="primary" onClick={onClickNext}>
                                    {step === EStep.MOST_CARE ? 'Complete my UniProfile' : 'Next'}
                                </Button>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Page;
