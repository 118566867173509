// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--ZbN54 {
  padding: 24px;
  background: #fff;
}
.s-module__wrap--ZbN54 .s-module__header--DMLRn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: var(--gray-800);
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
}
.s-module__wrap--ZbN54 .s-module__title--yfl09 {
  margin: 10px 0 6px 0;
}
.s-module__wrap--ZbN54 .s-module__colorItem--uouHh {
  display: inline-block;
  width: 56px;
  height: 22px;
  margin-top: 4px;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentSettingPage/components/ServicesPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;AACF;AAHA;EAII,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;EACA,6BAAA;AAEJ;AAbA;EAcI,oBAAA;AAEJ;AAhBA;EAiBI,qBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AAEJ","sourcesContent":[".wrap {\n  padding: 24px;\n  background: #fff;\n  .header{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 20px;\n    color: var(--gray-800);\n    margin-bottom: 16px;\n    border-bottom: 1px solid #eee;\n  }\n  .title {\n    margin: 10px 0 6px 0;\n  }\n  .colorItem{\n    display: inline-block;\n    width: 56px;\n    height: 22px;\n    margin-top: 4px;\n    border-radius: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--ZbN54`,
	"header": `s-module__header--DMLRn`,
	"title": `s-module__title--yfl09`,
	"colorItem": `s-module__colorItem--uouHh`
};
export default ___CSS_LOADER_EXPORT___;
