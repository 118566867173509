// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--d0M9S {
  background-color: var(--main-bg-color);
}
.s-module__wrap--d0M9S .s-module__header--ItGdK {
  padding: 48px 48px 0;
}
.s-module__wrap--d0M9S .s-module__header--ItGdK .s-module__inner--NzcRc {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--light-gray);
  color: var(--gray-900);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
}
.s-module__wrap--d0M9S .s-module__content--JkhE5 {
  padding: 4px 48px 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ChannelMainPage/s.module.less"],"names":[],"mappings":"AAAA;EACI,sCAAA;AACJ;AAFA;EAIQ,oBAAA;AACR;AALA;EAOY,mBAAA;EACA,0CAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACZ;AAfA;EAmBQ,mBAAA;AADR","sourcesContent":[".wrap {\n    background-color: var(--main-bg-color);\n\n    .header {\n        padding: 48px 48px 0;\n        \n        .inner {\n            padding-bottom: 8px;\n            border-bottom: 1px solid var(--light-gray);\n            color: var(--gray-900);\n            font-family: Inter;\n            font-size: 30px;\n            font-style: normal;\n            font-weight: 800;\n            line-height: 36px;\n        }\n    }\n\n    .content {\n        padding: 4px 48px 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--d0M9S`,
	"header": `s-module__header--ItGdK`,
	"inner": `s-module__inner--NzcRc`,
	"content": `s-module__content--JkhE5`
};
export default ___CSS_LOADER_EXPORT___;
