// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__module--X3_K3 {
  background: #fff;
  height: auto;
}
.s-module__module--X3_K3 .s-module__tips--h_0mT {
  color: var(--gray-500);
  font-size: 14px;
  margin-bottom: 24px;
}
.s-module__module--X3_K3 h3 {
  color: var(--gray-800);
  font-size: 20px;
  line-height: 44px;
  font-weight: 500;
  border-bottom: 1px solid var(--bottom-line-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.s-module__module--X3_K3 h3 .s-module__icon--iB0NZ {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 100;
  margin-right: 8px;
  cursor: pointer;
  opacity: 1;
  transition: all linear 200ms;
}
.s-module__module--X3_K3 h3 .s-module__icon--iB0NZ:hover {
  opacity: 0.7;
}
.s-module__module--X3_K3 .s-module__toggle--nMeHH {
  transition: all linear 300ms;
  transform: scaleY(1);
  transform-origin: top;
}
.s-module__module--X3_K3 .s-module__row--DdSUx {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.s-module__module--X3_K3 .s-module__row--DdSUx .s-module__displayItem--piSOj {
  margin-bottom: 12px;
}
.s-module__module--X3_K3 .s-module__row--DdSUx .s-module__displayItem--piSOj .s-module__label--jeSQ_ {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__module--X3_K3 .s-module__rowItem--EpDfX {
  margin-right: 36px;
}
.s-module__module--X3_K3 .s-module__colItem--MdSvj {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentManagePage/components/AddEventModal/s.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;AAHA;EAKI,sBAAA;EACA,eAAA;EACA,mBAAA;AACJ;AARA;EAUI,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,iDAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AApBA;EAqBM,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,UAAA;EACA,4BAAA;AAEN;AA7BA;EA8BM,YAAA;AAEN;AAhCA;EAkCI,4BAAA;EACA,oBAAA;EACA,qBAAA;AACJ;AArCA;EAwCI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AA3CA;EAkDM,mBAAA;AAJN;AA9CA;EA8CQ,cAAA;EACA,eAAA;EACA,iBAAA;AAGR;AAnDA;EAuDI,kBAAA;AADJ;AAtDA;EA2DI,aAAA;EACA,sBAAA;AAFJ","sourcesContent":[".module {\n  background: #fff;\n  height: auto;\n\n  .tips{\n    color: var(--gray-500);\n    font-size: 14px;\n    margin-bottom: 24px;\n  }\n  h3 {\n    color: var(--gray-800);\n    font-size: 20px;\n    line-height: 44px;\n    font-weight: 500;\n    border-bottom: 1px solid var(--bottom-line-color);\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 12px;\n    .icon{\n      color: var(--primary-color);\n      font-size: 24px;\n      font-weight: 100;\n      margin-right: 8px;\n      cursor: pointer;\n      opacity: 1;\n      transition: all linear 200ms;\n    }\n    .icon:hover{\n      opacity: 0.7;\n    }\n  }\n  .toggle{\n    transition: all linear 300ms;\n    transform: scaleY(1);\n    transform-origin: top;\n  }\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 16px;\n    .displayItem{\n      .label {\n        color: #6B7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n      margin-bottom: 12px;\n    }\n  }\n\n  .rowItem {\n    margin-right: 36px;\n  }\n\n  .colItem {\n    display: flex;\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"module": `s-module__module--X3_K3`,
	"tips": `s-module__tips--h_0mT`,
	"icon": `s-module__icon--iB0NZ`,
	"toggle": `s-module__toggle--nMeHH`,
	"row": `s-module__row--DdSUx`,
	"displayItem": `s-module__displayItem--piSOj`,
	"label": `s-module__label--jeSQ_`,
	"rowItem": `s-module__rowItem--EpDfX`,
	"colItem": `s-module__colItem--MdSvj`
};
export default ___CSS_LOADER_EXPORT___;
