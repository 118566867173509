// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__row--5jRVL {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.s-module__row--5jRVL .s-module__displayItem--NqCMk {
  width: 28%;
  margin-right: 24px;
  margin-bottom: 12px;
}
.s-module__row--5jRVL .s-module__displayItem--NqCMk .s-module__tips--N55v1 {
  padding: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--gray-900);
}
.s-module__row--5jRVL .s-module__displayItem--NqCMk .s-module__tips--N55v1 img {
  flex: 0 0 20px;
  height: 20px;
}
.s-module__row--5jRVL .s-module__displayItem--NqCMk .s-module__tips--N55v1 p {
  max-width: 100%;
}
.s-module__row--5jRVL .s-module__displayItem--NqCMk .s-module__tips--N55v1 span {
  color: var(--primary-color);
  cursor: pointer;
}
.s-module__row--5jRVL .s-module__displayItem--NqCMk .s-module__label--LFybG {
  margin-bottom: 4px;
  color: #6b7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__row--5jRVL .s-module__displayItem--NqCMk .s-module__subLabel--hrcpN {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-500);
}
.s-module__row--5jRVL .s-module__displayItem--NqCMk .s-module__value--ip9YW {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-900);
}
.s-module__row--5jRVL .s-module__displayItem--NqCMk .s-module__textarea--P7wtf {
  height: 188px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentSettingPage/components/BillingPage/components/components/Item/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AALA;EAMI,UAAA;EACA,kBAAA;EA6CA,mBAAA;AA1CJ;AAVA;EASM,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAIN;AAlBA;EAiBQ,cAAA;EACA,YAAA;AAIR;AAtBA;EAsBQ,eAAA;AAGR;AAzBA;EA0BQ,2BAAA;EACA,eAAA;AAER;AA7BA;EA+BM,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AACN;AAnCA;EAqCM,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACN;AA1CA;EA4CM,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACN;AAhDA;EAkDM,aAAA;AACN","sourcesContent":[".row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-top: 16px;\n  .displayItem {\n    width: 28%;\n    margin-right: 24px;\n    .tips {\n      padding: 16px;\n      margin-bottom: 16px;\n      font-weight: 500;\n      font-size: 14px;\n      line-height: 24px;\n      color: var(--gray-900);\n\n      img {\n        flex: 0 0 20px;\n        height: 20px;\n      }\n\n      p {\n        max-width: 100%;\n      }\n\n      span {\n        color: var(--primary-color);\n        cursor: pointer;\n      }\n    }\n    .label {\n      margin-bottom: 4px;\n      color: #6b7280;\n      font-size: 14px;\n      line-height: 20px;\n    }\n    .subLabel {\n      margin-bottom: 8px;\n      font-weight: 400;\n      font-size: 14px;\n      line-height: 20px;\n      color: var(--gray-500);\n    }\n    .value {\n      font-weight: 400;\n      font-size: 14px;\n      line-height: 20px;\n      color: var(--gray-900);\n    }\n    .textarea {\n      height: 188px;\n    }\n    margin-bottom: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `s-module__row--5jRVL`,
	"displayItem": `s-module__displayItem--NqCMk`,
	"tips": `s-module__tips--N55v1`,
	"label": `s-module__label--LFybG`,
	"subLabel": `s-module__subLabel--hrcpN`,
	"value": `s-module__value--ip9YW`,
	"textarea": `s-module__textarea--P7wtf`
};
export default ___CSS_LOADER_EXPORT___;
