import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import Contact from 'components/PracticeFrontFromComponents/Contact';
import PracticeAddress from 'components/PracticeFrontFromComponents/PracticeAddress';
import LocalSEO from 'components/PracticeFrontFromComponents/LocalSEO';
import { EditModuleEnum, IProviderStore } from 'types/practiceFront';
import ProviderStore from 'store/PracticeFront/provider';
import EditStatusStore from 'store/PracticeFront/editStatus';
import ShowEditingTipStore from 'store/PracticeFront/showEditingTip';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import PracticeFrontPreviewStore from 'store/PracticeFrontPreview';

interface IProps {
    isFree: boolean;
    onSubmit: (formValue, field: EditModuleEnum, callback?: () => void) => void;
    setTabKey: (key: string) => void;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    scrollToTheEditingModule: () => void;
    refetch: () => void;
}

const ContactPage = ({
    isFree,
    onEditStatusChange,
    setTabKey,
    onSubmit,
    scrollToTheEditingModule,
    refetch,
}: IProps) => {
    const [, setPreviewStore] = PracticeFrontPreviewStore.useStore();
    const [getProvider] = ProviderStore.useStore();
    const [getEditStatus] = EditStatusStore.useStore();
    const [getShowEditingTip, setShowEditingTip] = ShowEditingTipStore.useStore();
    const showEditingTip = getShowEditingTip('data');
    const editStatusMap = getEditStatus('data') as Record<EditModuleEnum, boolean>;
    const providerData = getProvider('data');
    const gloablEditStatus = useMemo(() => !editStatusMap || !!Object.values(editStatusMap).find((e) => e), [editStatusMap]);

    const { contact, seoCity, seoData, containSeoApp } = providerData!;

    const handleShowUpgardePlan = useCallback(() => {
        openSubscriptionModal();
    }, []);

    return (
        <div className={s.wrap}>
            {
                isFree &&
                (
                    <div className={s.paidTip}>
                        <div className={s.starIcon} />
                        <div className={s.TipContent}>
                            <div className={s.tipText}><strong>Enhance your reach by adding a Contact Form</strong> to your Practice Front to gather consistent and relevant information from the visitors and make yourself more reachable and professional.</div>
                            <a className={s.upgradeAction} onClick={handleShowUpgardePlan}>Upgrade plan</a>
                            <span
                                style={{ marginLeft: '24px' }}
                                className={s.upgradeAction}
                                onClick={() => {
                                    setPreviewStore('previewContactForm', true);
                                }}
                            >
                                Try it
                            </span>
                        </div>
                    </div>
                )
            }
            <div className={s.editItem}>
                <Contact
                    scrollToTheEditingModule={scrollToTheEditingModule}
                    isEditing={editStatusMap[EditModuleEnum.CONTACT]}
                    onEditStatusChange={onEditStatusChange}
                    setShowEditingTip={setShowEditingTip}
                    showEditingTip={showEditingTip}
                    gloablEditStatus={gloablEditStatus}
                    initFormValue={contact}
                    onSubmit={onSubmit}
                    setTabKey={setTabKey}
                />
                <PracticeAddress
                    setShowEditingTip={setShowEditingTip}
                    scrollToTheEditingModule={scrollToTheEditingModule}
                    refetch={refetch}
                    initFormValue={contact}
                    gloablEditStatus={gloablEditStatus}
                    isEditing={editStatusMap[EditModuleEnum.PRACTICE_ADDRESS]}
                    onEditStatusChange={onEditStatusChange}
                    setTabKey={setTabKey}
                />
            </div>
            <div className={s.editItem}>
                <LocalSEO
                    onSubmit={onSubmit}
                    uniProfile={providerData}
                    initFormValue={seoData}
                    containSeoApp={containSeoApp}
                    practiceAddress={contact?.addressList || []}
                    isEditing={editStatusMap[EditModuleEnum.LOCAL_SEO]}
                />
            </div>
        </div>
    );
};

export default ContactPage;
