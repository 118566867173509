import React, { useState } from 'react';
import { Modal, Form, Button, message, Calendar } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import commonS from 'styles/common.module.less';
import s from './s.module.less';

import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import TimePicker from 'components/form/TimePicker';
import { TimeItemType, IproviderOverrideCreate } from 'types/appointmentSetting';
import { providerOverrideCreate } from 'api/appointmentSetting';
import { getUserId, getTimeZone } from 'utils/localstore';
import { getDateKey } from 'utils/calendar';
import { OverrideFormFieldEnum, OverrideFormInterface } from './types';

type Props = {
    onCancel?: () => void,
    onFetchData?: () => void
};

const initValues:TimeItemType = {
    fromHour: undefined,
    fromMinute: undefined,
    toHour: undefined,
    toMinute: undefined,
};

export default function AddOverrideModal({ onCancel, onFetchData }: Props) {
    const [, forceUpdate] = useState({});
    const [form] = Form.useForm<OverrideFormInterface>();
    const [submiting, setSubmiting] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [overrideTime, setOverrideTime] = useState<TimeItemType[]>([initValues]);
    const [value, setValue] = useState(() => dayjs());
    const [selectedValue, setSelectedValue] = useState(() => dayjs());
    const uid = getUserId();

    const onSelect = (newValue: Dayjs) => {
        setValue(newValue);
        setSelectedValue(newValue);
    };

    const onPanelChange = (newValue: Dayjs) => {
        setValue(newValue);
    };

    const addBlockTime = () => {
        if (overrideTime.length === 5) return message.error('Max 5 time slot per day');
        overrideTime.push(initValues);
        forceUpdate({});
    };

    const timePickerFunc = (val: TimeItemType, index: number) => {
        overrideTime[index] = val;
        forceUpdate({});
    };

    const deleteDateItem = (index: number) => {
        overrideTime.splice(overrideTime.findIndex((item) => item === overrideTime[index]), 1);
        forceUpdate({});
    };

    const onSave = async () => {
        setSubmiting(true);
        try {
            await form.validateFields();
            const params:IproviderOverrideCreate = {
                overrideDate: getDateKey(selectedValue),
                time: overrideTime,
                providerId: uid,
                timezone: getTimeZone() || '',
            };
            const { data } = await providerOverrideCreate(params);
            if (data.code) {
                message.success(data.message);
                onCancel?.();
                onFetchData?.();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    return (
        <Modal
            title="Add a date and time override"
            closable
            className={commonS.modalFixHeightWrap}
            width="660px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>Save</Button>
                </>
            }
        >
            <div className={s.wrap}>
                <div className={s.title}>Select the date(s) you want to assign specific hours</div>
                <Calendar
                    disabledDate={(date) => {
                        const now = (new Date()).getTime();
                        if (!date) {
                            return false;
                        }
                        return date?.valueOf() < now;
                    }}
                    fullscreen={false}
                    value={value}
                    onSelect={onSelect}
                    onPanelChange={onPanelChange}
                />
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >

                    <div className={s.title}>Available hours:</div>
                    <div className="flex justify-between">
                        <div className="flex flex-col">
                            {
                                overrideTime && overrideTime.map((dateItem, index) => {
                                    return (
                                        <div key={index} className="mt-1 mb-1 flex items-center">
                                            <Form.Item
                                                name={[OverrideFormFieldEnum.TIME]}
                                                validateFirst
                                                rules={[{ required: true, message: '' }]}
                                            >
                                                <TimePicker value={dateItem} onChange={(val) => timePickerFunc(val, index)} />
                                            </Form.Item>
                                            {
                                                overrideTime.length >= 1 && <CloseOutlined className="ml-10 w-6 h-6 text-gray-400" onClick={() => deleteDateItem(index)} />
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <PlusOutlined className="text-indigo-700 w-6 h-6 cursor-pointer mt-1 mb-1" onClick={addBlockTime} />
                    </div>
                </Form>

            </div>
        </Modal>
    );
}
