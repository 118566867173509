import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import s from './s.module.less';
import { Channel } from 'types/common';
import { Popover } from 'antd';

interface IProps {
    item: Channel;
    onUpdateVote: (id: number, voted: boolean) => void
}

const UpcomingChannelCard = ({
    item,
    onUpdateVote,
}: IProps) => {
    const {
        id,
        name,
        logo,
        tagList,
        voted,
        voteCount,
        initUpvoteCount,
    } = item;
    const navigate = useNavigate();
    const voteNumber = `🔥${voteCount || initUpvoteCount || 5}`;
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        if (tagList && tagList.length > 0) {
            setTags(tagList.slice(0, 2));
        }
    }, [tagList]);

    const handleDetailPage = useCallback(() => {
        //navigate(`/dashboard/channel-detail?channelId=${id}`);
    }, [id, navigate]);

    const handleClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        onUpdateVote(id, voted);
    }, [id, onUpdateVote, voted]);

    const moreNumber = (tagList && tagList.length > 2) ? tagList.length - 2 : 0;

    return (
        <div className={s.channel} onClick={handleDetailPage}>
            <div className={s.logo} style={{ backgroundImage: `url(${logo})` }} />
            <div className={s.channelInfo}>
                <div className={s.channelTitle}>
                    <div className={s.name}>{name}</div>
                    <div className={s.voteNumber}>{voteNumber}</div>
                </div>
                {
                    tagList && tagList.length > 0 &&
                    <div className={s.tagList}>
                        {
                            tags?.map((tag, i) => <div key={i} className={s.tag}>{tag}</div>)
                        }
                        { moreNumber > 0 ? <Popover placement="bottomLeft" content={tagList.slice(2, tagList.length).map((e) => <p>{e}</p>)}><a className={s.moreTags}>{tagList.length - 2} more</a></Popover> : '' }
                    </div>
                }
                <div className={s.activeButton} onClick={handleClick}>
                    <div className={`${s.buttonInner} ${voted ? s.success : ''}`}>
                        <div className={s.icon} />
                        <div className={s.buttonText}>Join waitlist</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpcomingChannelCard;
