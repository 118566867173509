/* eslint-disable react/jsx-closing-tag-location */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Button, Modal, Spin, Table, message } from 'antd';
import { openSubscriptionModal, openChannelSubscriptionModal } from 'utils/globalLayerControl';
import ChannelStatus from '../ActiveChannelStatus';
import useGetMyChannels from 'hooks/useGetMyChannels';
import { TSubscriptionItemFromServer, TSubscriptionType } from 'types/subscription';
import dayjs from 'dayjs';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { reactivatePremiumChannel, removePremiumChannel } from 'api/channel';
import { Channel, EKlarityPlanLevel, Meta } from 'types/common';
import { Email } from 'constants/contact';
import RemovalConfirm from './components/RemovalConfirm';
import RemoveSuccessfully from './components/RemoveSuccessfully';
import ReactiveSuccessfully from './components/ReactiveSuccessfully';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
// import { filterAvailableChannelForMyChannel } from 'utils/channel';

interface IProps {
    isFree: boolean;
    currentPlan?: TSubscriptionItemFromServer;
    setTabKey: (key: string) => void
}

const KlarityMyChannel = ({
    isFree,
    currentPlan,
    setTabKey,
}: IProps) => {
    const navigate = useNavigate();
    const [myChannels, loading, error, refetch] = useGetMyChannels();
    // const freeChannelLimit = currentPlan?.freeChannelLimit || 2;
    const paidChannelLimit = currentPlan?.paidChannelLimit;
    // const premiumChannelLimit = currentPlan?.premiumChannelLimit || 0;
    const [setting] = useSettings();
    const listStatusOptions = setting[EnumFields.LIST_CHANNEL_STATUS];
    const paymentStatus = setting[EnumFields.PAYMENT_STATUS];
    const [showRemovalConfirm, setShowRemovalConfirm] = useState(false);
    const [showRemoveSuccessfully, setShowRemoveSuccessfully] = useState(false);
    const [showReactiveSuccessfully, setShowReactiveSuccessfully] = useState(false);
    const [removeChannelId, setRemoveChannelId] = useState<null | number>(null);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [reactiveLoading, setReactiveLoading] = useState(false);
    const [reactiveChannel, setReactiveChannel] = useState<Channel>();
    // 记录被删除的Channel，用来获取billingCycleEndDate
    const [removedChannel, setRemoveChannel] = useState<Channel>();

    // const myChannels = useMemo(() => {
    //     const { freeList, standardList, premiumList, remainChannelList } = myChannelsData;

    //     return {
    //         ...myChannelsData,
    //         freeList: filterAvailableChannelForMyChannel(freeList),
    //         standardList: filterAvailableChannelForMyChannel(standardList),
    //         premiumList: filterAvailableChannelForMyChannel(premiumList),
    //         remainChannelList,
    //     };
    // }, [myChannelsData]);

    useEffect(() => {
        if (reactiveChannel) {
            const reactivePremiumChannel = myChannels?.premiumList?.find((e: Channel) => e.id === reactiveChannel.id);

            if (reactivePremiumChannel) {
                setReactiveChannel(reactivePremiumChannel);
            }
        }
    }, [myChannels, reactiveChannel]);

    const getStatusText = useCallback((listStatus: string) => {
        const statusItem = listStatusOptions?.find((option) => listStatus === option.value);

        return statusItem?.label;
    }, [listStatusOptions]);

    const getRemovedChannel = useCallback((channelId: number) => {
        if (channelId) {
            const removedPremiumChannel = myChannels?.premiumList?.find((c: Channel) => c.id === channelId);

            if (removedPremiumChannel) {
                setRemoveChannel(removedPremiumChannel);
            }
        }
    }, [myChannels]);

    const handleRemovePremiumChannel = useCallback(async (channelId: number | null) => {
        if (channelId) {
            setRemoveLoading(true);
            try {
                const res = await removePremiumChannel(channelId);

                if (res && !res.error) {
                    setShowRemovalConfirm(false);
                    setShowRemoveSuccessfully(true);
                    getRemovedChannel(channelId);
                } else {
                    message.error(res.error);
                }
            } catch (e) {
                console.error(e);
            }
            setRemoveLoading(false);
        }
    }, [getRemovedChannel]);

    const handleReactivePremiumChannel = useCallback(async (channelId: number | null) => {
        if (channelId) {
            setReactiveLoading(true);
            try {
                const res = await reactivatePremiumChannel(channelId);

                if (res && !res.error) {
                    setShowReactiveSuccessfully(true);
                } else {
                    message.error(res.error);
                }
            } catch (e) {
                console.error(e);
            }
            setReactiveLoading(false);
        }
    }, []);

    const handleRemovalConfirmCancel = useCallback(() => {
        setShowRemovalConfirm(false);
    }, []);
    const handleRemovalConfirmConfirm = useCallback(() => {
        handleRemovePremiumChannel(removeChannelId);
    }, [handleRemovePremiumChannel, removeChannelId]);

    const handleRemoveSuccessfullyClose = useCallback(() => {
        setRemoveChannelId(null);
        setRemoveChannel(undefined);
        setShowRemoveSuccessfully(false);
        refetch();
    }, [refetch]);

    const handleReactiveSuccessfullyClose = useCallback(() => {
        setShowReactiveSuccessfully(false);
        refetch();
    }, [refetch]);

    const premiumTableActionRender = useCallback((row:Channel) => {
        const { payStatus, listStatus, removeStatus } = row;

        if (payStatus === 'failed') {
            return <a className={s.actionCell} onClick={() => openChannelSubscriptionModal({ channel: row })}>Update payment</a>;
        }

        if (payStatus === 'paid') {
            // 优先判断removeStatus，如果是pending，说明已经被删除，在处理中
            if (removeStatus === 'Pending') {
                return (
                    <a
                        className={s.actionCell}
                        onClick={() => {
                            setReactiveChannel(row);
                            handleReactivePremiumChannel(row.id);
                        }}
                    >Reactivate</a>
                );
            }

            if (listStatus === 'under-review' || listStatus === 'rejected') {
                return <a className={s.actionCell} href={`mailto:${Email.support}`}>Follow up</a>;
            }

            if (listStatus === 'listed') {
                return <div
                    className={s.actionCell}
                    onClick={() => {
                        setRemoveChannelId(row.id);
                        setShowRemovalConfirm(true);
                    }}
                >
                    Remove
                </div>;
            }
        }
    }, [handleReactivePremiumChannel]);

    const premiumChannelColumn = useMemo(() => {
        return (
            [
                {
                    title: 'CHANNEL NAME',
                    dataIndex: 'name',
                    key: 'name',
                    render: (name: string, row: Channel) => {
                        return (
                            <div className={s.channelNameColumn}>
                                <img src={row.logo} />
                                {name}
                            </div>
                        );
                    },
                },
                {
                    title: 'STATUS',
                    dataIndex: 'listStatus',
                    key: 'listStatus',
                    render: (listStatus: string, row: Channel) => {
                        const { removeStatus } = row;
                        return removeStatus === 'Pending' ? <div className={s.pendingRemoved}>Pending removed</div> : <ChannelStatus showDot status={listStatus} statusText={getStatusText(listStatus)} />;
                    },
                },
                {
                    title: 'NEXT PAYMENT DUE',
                    dataIndex: 'nextPaymentDue',
                    key: 'nextPaymentDue',
                    render: (time: null | string) => {
                        return <div>{time ? `On ${dayjs(time).format('MMM D, YYYY')}` : ''}</div>;
                    },
                },
                {
                    title: 'PAYMENT',
                    dataIndex: 'payStatus',
                    key: 'payStatus',
                    render: (payStatus: string) => {
                        return paymentStatus ? paymentStatus.find((e: Meta) => e.value === payStatus)?.label : '';
                    },
                },
                {
                    title: 'PROFILE PAGE',
                    dataIndex: 'profileUrl',
                    key: 'profileUrl',
                    render: (url: string, row: Channel) => {
                        if (row.listStatus !== 'listed') {
                            return 'Not listed yet';
                        }

                        if (url) {
                            let link = url;
                            if (url?.indexOf('https://') !== 0) {
                                link = `https://${url}`;
                            }
                            return <a target="_blank" className={s.link} href={link} rel="noreferrer">Link</a>;
                        }

                        return null;
                    },
                },
                {
                    title: '',
                    width: '120px',
                    className: s.tableColumn,
                    editable: false,
                    render: (item: Channel) => {
                        return premiumTableActionRender(item);
                    },
                },
            ]
        );
    }, [getStatusText, paymentStatus, premiumTableActionRender]);

    const standardChannelColumn = useMemo(() => {
        return (
            [
                {
                    title: 'CHANNEL NAME',
                    dataIndex: 'name',
                    key: 'name',
                    render: (name: string, row: Channel) => {
                        return (
                            <div className={s.channelNameColumn}>
                                <img src={row.logo} />
                                {name}
                            </div>
                        );
                    },
                },
                {
                    title: 'LISTED SINCE',
                    dataIndex: 'listedTime',
                    key: 'listedTime',
                    render: (time: null | string) => {
                        return <div>{time ? dayjs(time).format('MM/DD/YYYY') : ''}</div>;
                    },
                },
                {
                    title: 'STATUS',
                    dataIndex: 'listStatus',
                    key: 'listStatus',
                    render: (listStatus: string) => {
                        return <ChannelStatus showDot status={listStatus} statusText={getStatusText(listStatus)} />;
                    },
                },
                {
                    title: 'PROFILE PAGE',
                    dataIndex: 'profileUrl',
                    key: 'profileUrl',
                    render: (url: string, row: Channel) => {
                        if (row.listStatus !== 'listed') {
                            return 'Not listed yet';
                        }
                        if (url) {
                            let link = url;
                            if (url?.indexOf('https://') !== 0) {
                                link = `https://${url}`;
                            }
                            return <a target="_blank" className={s.link} href={link} rel="noreferrer">Link</a>;
                        }

                        return null;
                    },
                },
            ]
        );
    }, [getStatusText]);
    const handleShowUpgardePlan = useCallback(() => {
        openSubscriptionModal();
    }, []);

    const gotoChannelDetail = useCallback((channel?: Channel) => {
        if (channel) {
            navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL_DETAIL}?channelId=${channel.id}`);
        }
    }, [navigate]);

    const standardChannelHeaderRender = useMemo(() => {
        const listedLength = myChannels && myChannels.standardList ? myChannels.standardList.length : 0;
        const denominator = paidChannelLimit ? `/${paidChannelLimit}` : '';

        return <div className={s.header}>{`Channels included in my plan (${listedLength}${denominator})`}</div>;
    }, [myChannels, paidChannelLimit]);

    const remainStandardChannelsTipText = useMemo(() => {
        const listedLength = myChannels && myChannels.allList ? myChannels.allList.length : 0;
        const remaindStandardLength = myChannels && myChannels.remainChannelList ? myChannels.remainChannelList.filter((e) => e.klarityPlanLevel > EKlarityPlanLevel.PREMIUM).length : 0;
        const remaindPremiumLength = myChannels && myChannels.remainChannelList ? myChannels.remainChannelList.filter((e) => e.klarityPlanLevel === EKlarityPlanLevel.PREMIUM).length : 0;
        return (
            <>
                <p>You have listed on {listedLength} channels, {remaindStandardLength} standard channels and {remaindPremiumLength} premium channel remain in your Starter Plan. </p>
                <p>Upgrade to unlock unlimited Standard channels and more premium channels.</p>
            </>
        );
    }, []);
    return (
        <div className={s.wrap}>
            <Modal
                width="50%"
                footer={null}
                open={showRemovalConfirm}
                onCancel={() => setShowRemovalConfirm(false)}
            >
                <RemovalConfirm
                    loading={removeLoading}
                    onCancel={handleRemovalConfirmCancel}
                    onConfirm={handleRemovalConfirmConfirm}
                />
            </Modal>
            <Modal
                width="45%"
                footer={null}
                open={showRemoveSuccessfully}
                onCancel={handleRemoveSuccessfullyClose}
            >
                <RemoveSuccessfully
                    removedChannel={removedChannel}
                    onClose={handleRemoveSuccessfullyClose}
                />
            </Modal>
            <Modal
                width="45%"
                footer={null}
                open={showReactiveSuccessfully}
                onCancel={handleReactiveSuccessfullyClose}
            >
                <ReactiveSuccessfully
                    nextBillingDate={reactiveChannel ? reactiveChannel.nextPaymentDue : ''}
                    onClose={handleReactiveSuccessfullyClose}
                />
            </Modal>
            <Spin spinning={!!loading || reactiveLoading}>
                <div className={s.typeWrap}>
                    <div className={s.header}>Add-on premium channels</div>
                    <Table
                        columns={premiumChannelColumn}
                        className={s.channelTable}
                        dataSource={myChannels?.premiumList || []}
                        pagination={
                            { hideOnSinglePage: true }
                        }
                        locale={{ emptyText: isFree ? (
                            <div className={s.emptyWrap}>
                                <div className={s.emptyImage} />
                                <div className={s.emptyText}>Premium channels are only available to Essential and Growth Plan Members</div>
                                <div className={s.upgradeText} onClick={handleShowUpgardePlan}>Upgrade my plan</div>
                            </div>
                        ) :
                            <div className={s.emptyWrap}>
                                <div className={s.emptyImage} />
                                <div className={s.emptyText}>You currently have no Premium channels. Discover the power they offer in the Channel store and elevate your presence.</div>
                                <div className={s.upgradeText} onClick={() => setTabKey('1')}>Browse Channel store</div>
                            </div>,
                        }}
                    />
                </div>
                <div className={s.typeWrap}>
                    <div className={s.header}>{standardChannelHeaderRender}</div>
                    <div className={s.desc}>
                        {remainStandardChannelsTipText}
                    </div>
                    <Table
                        columns={standardChannelColumn}
                        className={s.channelTable}
                        dataSource={myChannels?.standardList || []}
                        pagination={
                            { hideOnSinglePage: true }
                        }
                        locale={{ emptyText: isFree ? (
                            <div className={s.emptyWrap}>
                                <div className={s.emptyImage} />
                                <div className={s.emptyText}>Standard channels are only available to Essential and Growth Plan Members</div>
                                <div className={s.upgradeText} onClick={handleShowUpgardePlan}>Upgrade my plan</div>
                            </div>
                        ) :
                            <div className={s.emptyWrap}>
                                <div className={s.emptyImage} />
                                <div className={s.emptyText}>You currently have no Standard channels. Discover the power they offer in the Channel store and elevate your presence.</div>
                                <div className={s.upgradeText} onClick={() => setTabKey('1')}>Browse Channel store</div>
                            </div>,
                        }}
                    />
                </div>
            </Spin>
        </div>
    );
};

export default KlarityMyChannel;
